import { useReducer, useCallback, useEffect } from "react";
import { ScrollView, View, Dimensions } from "react-native";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import Picker from "../../components/Picker";
import Colors from "../../constants/Colors";
import Btn from "../../components/Btn";
import Config from "../../constants/Config";
import axios from "axios";
import Header from "../../components/Header";
import File from "../../components/File";
import { CommonActions } from "@react-navigation/native";

const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM = "SET_FORM";
const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.loading,
          },
        },
      };
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          type:
            action.data.type == "plugin"
              ? { item: "plugin", id: "plugin" }
              : action.data.type == "theme"
              ? { item: "theme", id: "theme" }
              : action.data.type == "theme-child"
              ? { item: "theme-child", id: "theme-child" }
              : false,
        },
      };
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        values: {
          ...state.values,
          upload_progress: action.value,
        },
      };
    default:
      return state;
  }
};

const AddMarketItemScreen = (props) => {
  const item_id = props.route.params.item_id;
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        get_form: false,
        send_form: false,
      },
      folder_name: "",
      file: "",
      type: "",
      version: "",
      upload_progress: null,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_form = async () => {
    const url = Config.aioUrl + "/asc/market/setitem";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    if (!formState.values.folder_name || !formState.values.type || !formState.values.version || (!item_id && !formState.values.file)) {
      alert("فیلدهای الزامی را پر کنید.");
      return;
    }

    let formdata = new FormData();
    if (item_id) formdata.append("item_id", item_id);
    formdata.append("folder_name", formState.values.folder_name);
    if (formState.values.file) formdata.append("file", formState.values.file.file, formState.values.file.name);
    formdata.append("type", formState.values.type.id);
    formdata.append("version", formState.values.version);

    dispatchFormState({ type: SET_LOADING, name: "send_form", loading: true });
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_UPLOAD_PROGRESS,
          value: progress.loaded / progress.total,
        });
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, name: "send_form", loading: false });
        alert(response.data.msg);
        props.navigation.navigate("MarketList");
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "send_form", loading: false });
      });
  };

  const get_form = async () => {
    const url = Config.aioUrl + "/asc/market/getitem?id=" + item_id;

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    dispatchFormState({ type: SET_LOADING, name: "get_form", loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
        });
        dispatchFormState({ type: SET_LOADING, name: "get_form", loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "get_form", loading: false });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      if (item_id) get_form();
    });

    return focused;
  }, [props.navigation]);

  return (
    <View style={{ paddingHorizontal: 25, height: "100%", overflow: "hidden", backgroundColor: Colors.bg }}>
      <Header title="آیتم مارکت" noToggle />
      {!formState.values.loading.get_form && (
        <ScrollView
          style={{
            width: "100%",
            height: "100%",
            paddingHorizontal: DESKTOP ? "30%" : 0,
          }}
          showsVerticalScrollIndicator={false}
        >
          <Input
            label="نام فولدر"
            id="folder_name"
            onChange={inputChangeHandler}
            insert={item_id ? (formState.values.form ? formState.values.form.folder_name : "") : false}
          />
          <Picker
            label="نوع"
            id="type"
            onChange={inputChangeHandler}
            options={[
              { item: "plugin", id: "plugin" },
              { item: "theme", id: "theme" },
              { item: "theme-child", id: "theme-child" },
            ]}
            insert={
              item_id
                ? formState.values.form
                  ? formState.values.form.type == "plugin"
                    ? { item: "plugin", id: "plugin" }
                    : formState.values.form.type == "theme"
                    ? { item: "theme", id: "theme" }
                    : formState.values.form.type == "theme-child"
                    ? { item: "theme-child", id: "theme-child" }
                    : false
                  : false
                : false
            }
          />
          <Input
            label="ورژن"
            id="version"
            onChange={inputChangeHandler}
            insert={item_id ? (formState.values.form ? formState.values.form.version : "") : false}
          />
          <File
            id="file"
            title="فایل قالب یا افزونه"
            extension="zip"
            content_type="application/zip"
            required={true}
            value={formState.values.file}
            onChange={inputChangeHandler}
          />

          <Btn
            title={
              formState.values.upload_progress && formState.values.upload_progress != 0 && formState.values.upload_progress != 100
                ? "%" + formState.values.upload_progress
                : item_id
                ? "ثبت ویرایش"
                : "افزودن آیتم"
            }
            onPress={async () => {
              send_form();
            }}
            wrapperStyle={{ marginVertical: 50 }}
            loading={formState.values.loading.send_form}
          />
        </ScrollView>
      )}
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default AddMarketItemScreen;
