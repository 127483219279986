import { useReducer, useState } from "react";
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Image, ScrollView } from "react-native";
import Colors from "../../constants/Colors";
import { separate } from "../../components/Functions";
import { useEffect } from "react";
import Config from "../../constants/Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Header from "../../components/Header";
import Btn from "../../components/Btn";

const SET_LOADING = "SET_LOADING";
const SET_TRANSACTIONS = "SET_TRANSACTIONS";
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
      };
    default:
      return state;
  }
};

const Transaction = (props) => {
  const token = useSelector((store) => store.user.token);
  const { item } = props.item;
  const { reject_transaction, confirm_transaction } = props;
  const [img, setImg] = useState(false);
  const [loading, setLoading] = useState({ confirm: false, reject: false });
  const get_transaction_file = async () => {
    const url = Config.aioUrl + "/transaction/getfile?id=" + item.file_id;
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        if (response.data.success) {
          setImg(response.data.img_base64);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    get_transaction_file();
  }, []);
  return (
    <View
      style={{
        width: "100%",
        backgroundColor: "#f2f2f2",
        borderRadius: 15,
        paddingHorizontal: 20,
        paddingVertical: 20,
        marginBottom: 10,
        flexDirection: "row-reverse",
      }}
    >
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "flex-end" }}>
          <Text style={{ fontFamily: "IranSans-Light", fontSize: 12, color: Colors.text, marginBottom: 10 }}>صورتحساب</Text>
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>#{item.order_id}</Text>
        </View>
        <View style={{ alignItems: "flex-end", marginVertical: 15 }}>
          <Text style={{ fontFamily: "IranSans-Light", fontSize: 12, color: Colors.text, marginBottom: 10 }}>نام مشتری</Text>
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>{item.customer_name}</Text>
        </View>
        <View style={{ alignItems: "flex-end" }}>
          <Text style={{ fontFamily: "IranSans-Light", fontSize: 12, color: Colors.text, marginBottom: 10 }}>مبلغ صورتحساب</Text>
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>{separate(item.amount.toString())} تومان</Text>
        </View>
        <View style={{ marginTop: 15 }}>
          <Btn
            title="تایید"
            onPress={async () => {
              setLoading({ confirm: true, reject: false });
              await confirm_transaction(item.id);
              setLoading({ confirm: false, reject: false });
            }}
            color={Colors.green}
            wrapperStyle={{ flex: 1, marginHorizontal: 2, marginBottom: 15, paddingVertical: 10 }}
            loading={loading.confirm}
          />
          <Btn
            title="رد"
            onPress={async () => {
              setLoading({ confirm: false, reject: true });
              await reject_transaction(item.id);
              setLoading({ confirm: false, reject: false });
            }}
            color={Colors.primary}
            wrapperStyle={{ flex: 1, marginHorizontal: 2, paddingVertical: 10 }}
            loading={loading.reject}
          />
        </View>
      </View>
      <View style={{ flex: 2 }}>
        <ScrollView style={{ height: 400, width: "100%", flex: 1 }}>
          <View style={{ flex: 1, height: "100%", width: "100%" }}>
            {img && <Image style={{ width: "auto", height: 400 }} source={{ uri: img }} />}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const PendingTransactionsScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    showAlert: false,
    loading: false,
    transactions: [],
  });

  const get_transactions = async (section) => {
    const url = Config.aioUrl + "/transaction/getpending";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_TRANSACTIONS, data: response.data.data });
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const reject_transaction = async (transaction_id) => {
    const url = Config.aioUrl + "/transaction/reject";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("transaction_id", transaction_id);

    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        get_transactions();
        alert(response.data.msg);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const confirm_transaction = async (transaction_id) => {
    const url = Config.aioUrl + "/transaction/confirm";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("transaction_id", transaction_id);

    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        get_transactions();
        alert(response.data.msg);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_transactions();
    });

    return focused;
  }, [props.navigation]);

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="صورتحساب های در انتظار تایید" noBack />
      {formState.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      {!formState.loading && (
        <FlatList
          style={{ paddingBottom: 50 }}
          showsVerticalScrollIndicator={false}
          data={formState.transactions}
          keyExtractor={(item) => item.order_id}
          renderItem={(item, index) => (
            <Transaction
              item={item}
              navigation={props.navigation}
              reject_transaction={reject_transaction}
              confirm_transaction={confirm_transaction}
            />
          )}
        />
      )}
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default PendingTransactionsScreen;
