import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  Platform,
  ScrollView,
} from "react-native"
import Colors from "../constants/Colors"
import moment from "moment-jalaali"
import { Link } from "@react-navigation/native"
import { Ionicons } from "@expo/vector-icons"
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import Config from "../constants/Config"
import { useSelector } from "react-redux"

const AnalyseUserCard = (props) => {
  const { item, reload } = props

  const token = useSelector((store) => store.user.token)

  const [dropDown, setDropDown] = useState(false)
  const [projectMonths, setProjectMonths] = useState([])

  const dropDownToggle = () => setDropDown((prev) => !prev)

  const submit = (value) => {
    console.log(value)

    axios
      .post(
        Config.aioUrl + "/project/set-kpi",
        { id: item.id, kpi: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        alert(res.data.msg)
        reload()
      })

    dropDownToggle()
  }

  useEffect(() => {
    // const projectTime = item.time

    // const defrentFrom1401Esfand = moment
    //   .unix(projectTime)
    //   .diff(moment("1401-12-01", "jYYYY-jMM-jDD"), "months")

    // const months = []

    // for (let index = 0; index <= defrentFrom1401Esfand; index++) {
    //   const monthOBJ = {
    //     name: moment
    //       .unix(projectTime)
    //       .subtract(index, "month")
    //       .format("jMMMM jYYYY"),
    //     unix: moment.unix(projectTime).subtract(index, "month").unix(),
    //   }

    //   const isExist = months.find((item) => item.name == monthOBJ.name)

    //   if (!isExist) {
    //     months.push(monthOBJ)
    //   }
    // }

    const threeMonthLaterFromNow = moment(moment.now()).add(3, "months").unix()

    const defrentFrom1401Esfand = moment
      .unix(threeMonthLaterFromNow)
      .diff(moment("1401-12-01", "jYYYY-jMM-jDD"), "months")

    const months = []

    for (let index = 0; index <= defrentFrom1401Esfand; index++) {
      const monthOBJ = {
        name: moment
          .unix(threeMonthLaterFromNow)
          .subtract(index, "month")
          .format("jMMMM jYYYY"),
        unix: moment
          .unix(threeMonthLaterFromNow)
          .subtract(index, "month")
          .unix(),
      }

      const isExist = months.find((item) => item.name == monthOBJ.name)

      if (!isExist) {
        months.push(monthOBJ)
      }
    }

    setProjectMonths(months)
  }, [])

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: item.color || "#F2F2F2",
        borderRadius: 15,
        marginVertical: 5,
        paddingVertical: 7,
        direction: "ltr",
        display: "flex",

        zIndex: 1000 - props.item.index,
      }}
    >
      <View style={{ width: "100%", flexDirection: "row-reverse" }}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            style={{
              transform: [{ rotate: "-90deg" }],
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: "#fff",
            }}
          >
            #{item.id}
          </Text>
        </View>

        <View
          style={{ flex: 6, justifyContent: "center", alignItems: "flex-end" }}
        >
          <Text
            style={{
              color: "#fff",
              fontFamily: "Iransans-Medium",
              fontSize: item.customer_name?.length > 20 ? 12 : 16,
              marginVertical: 5,
            }}
          >
            {item.customer_name}
          </Text>
          <Text
            style={{
              color: "#fff",
              fontFamily: "Iransans-Light",
              fontSize: item.package?.length > 22 ? 10 : 14,
              marginVertical: 5,
            }}
          >
            {item.package}
          </Text>
          {item.site && (
            <Text
              style={{
                color: "#fff",
                fontFamily: "Iransans-Light",
                fontSize: item.package?.length > 22 ? 10 : 14,
                marginVertical: 5,
              }}
            >
              {item.site}
            </Text>
          )}
        </View>

        <View
          style={{
            flex: 2,
            paddingLeft: 15,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <View style={Styles.LeftSectionsContainer}>
            <Text style={Styles.date}>{item.price.toLocaleString()} تومان</Text>
          </View>

          <View style={Styles.LeftSectionsContainer}>
            <Text style={Styles.date}>
              تاریخ تحویل:{" "}
              {item.end_date != null
                ? moment.unix(item.end_date).format("jYYYY/jM/jD")
                : "بدون تایم"}
            </Text>
          </View>

          <View
            style={{
              marginTop: 10,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: Colors.text,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                width: 100,
                padding: 5,
                position: "relative",
              }}
              onPress={dropDownToggle}
            >
              <Text style={Styles.date}>
                {item.kpi_date ? "حساب شده" : "حساب نشده"}
              </Text>

              {dropDown && (
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  style={{
                    width: 100,
                    height: 200,
                    backgroundColor: Colors.text,
                    position: "absolute",
                    top: 15,
                    borderRadius: 5,
                  }}
                  contentContainerStyle={{
                    paddingHorizontal: 5,
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {projectMonths.map((item) => (
                    <TouchableOpacity
                      onPress={() => submit(item.unix)}
                      style={{ paddingVertical: 15 }}
                    >
                      <Text style={Styles.date}>{item.name}</Text>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              )}
            </TouchableOpacity>

            {item.danger ? (
              <Ionicons name="warning" size={22} color="white" />
            ) : (
              <View
                style={{
                  backgroundColor: Colors.text,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 30,
                  padding: 5,
                }}
              >
                <Text style={Styles.date}>{item.duration}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

const Styles = StyleSheet.create({
  dateTitle: {
    color: "#fff",
    fontFamily: "IranSans-Light",
    fontSize: 7,
  },
  LeftSectionsContainer: {
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    marginBottom: 5,
  },
  LeftSectionsContainer_staffs: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 5,
  },
  date: {
    color: "#fff",
    fontFamily: "IranSans-Light",
    fontSize: 10,
  },
})

export default AnalyseUserCard
