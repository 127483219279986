import { useReducer, useCallback, useEffect } from "react"
import {
  ActivityIndicator,
  ScrollView,
  View,
  Text,
  Image,
  Dimensions,
  Platform,
} from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"
import File from "../../../components/File"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Range from "../../../components/Range"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_GET_LOADING = "SET_GET_LOADING"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_GET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          get_loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,

          // ! NEED TO CHANGE
          logo_1_score: action.data.logoform_logo_1_score || 5,
          logo_2_score: action.data.logoform_logo_2_score || 5,
          logo_3_score: action.data.logoform_logo_3_score || 5,
          logo_4_score: action.data.logoform_logo_4_score || 5,
          logo_5_score: action.data.logoform_logo_5_score || 5,
          logo_6_score: action.data.logoform_logo_6_score || 5,
          logo_7_score: action.data.logoform_logo_7_score || 5,
          logo_8_score: action.data.logoform_logo_8_score || 5,
          logo_9_score: action.data.logoform_logo_9_score || 5,
          logo_10_score: action.data.logoform_logo_10_score || 5,
          logo_11_score: action.data.logoform_logo_11_score || 5,
          logo_12_score: action.data.logoform_logo_12_score || 5,
          logo_13_score: action.data.logoform_logo_13_score || 5,
          logo_14_score: action.data.logoform_logo_14_score || 5,
          logo_15_score: action.data.logoform_logo_15_score || 5,
          // !

          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      }

    default:
      return state
  }
}

const logos = [
  {
    id: "logo_1_score",
    img_id: "01",
    img: require(`/assets/img/LOGO_FORM/01/01.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_2_score",
    img_id: "02",
    img: require(`/assets/img/LOGO_FORM/01/02.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_3_score",
    img_id: "03",
    img: require(`/assets/img/LOGO_FORM/01/03.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_4_score",
    img_id: "04",
    img: require(`/assets/img/LOGO_FORM/01/04.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_5_score",
    img_id: "05",
    img: require(`/assets/img/LOGO_FORM/01/05.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_6_score",
    img_id: "06",
    img: require(`/assets/img/LOGO_FORM/01/06.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_7_score",
    img_id: "07",
    img: require(`/assets/img/LOGO_FORM/01/07.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_8_score",
    img_id: "08",
    img: require(`/assets/img/LOGO_FORM/01/08.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_9_score",
    img_id: "09",
    img: require(`/assets/img/LOGO_FORM/01/09.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_10_score",
    img_id: "10",
    img: require(`/assets/img/LOGO_FORM/01/10.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_11_score",
    img_id: "11",
    img: require(`/assets/img/LOGO_FORM/01/11.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_12_score",
    img_id: "12",
    img: require(`/assets/img/LOGO_FORM/01/12.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_13_score",
    img_id: "13",
    img: require(`/assets/img/LOGO_FORM/01/13.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_14_score",
    img_id: "14",
    img: require(`/assets/img/LOGO_FORM/01/14.jpg`),
    img_folder_id: "01",
  },
  {
    id: "logo_15_score",
    img_id: "15",
    img: require(`/assets/img/LOGO_FORM/01/15.jpg`),
    img_folder_id: "01",
  },
]

const RatingLogosTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const role = useSelector((store) => store.user.user_data.role)
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const setActiveIndex = props.route.params.setActiveIndex
  const setSteps = props.route.params.setSteps

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      // ! NEED TO CHANGE
      logo_1_score: 5,
      logo_2_score: 5,
      logo_3_score: 5,
      logo_4_score: 5,
      logo_5_score: 5,
      logo_6_score: 5,
      logo_7_score: 5,
      logo_8_score: 5,
      logo_9_score: 5,
      logo_10_score: 5,
      logo_11_score: 5,
      logo_12_score: 5,
      logo_13_score: 5,
      logo_14_score: 5,
      logo_15_score: 5,
      // !

      loading: false,
      get_loading: false,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/logoform/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    // ! NEED TO CHANGE
    formdata.append("project_id", project_id)
    formdata.append("logo_1_score", formState.values.logo_1_score)
    formdata.append("logo_2_score", formState.values.logo_2_score)
    formdata.append("logo_3_score", formState.values.logo_3_score)
    formdata.append("logo_4_score", formState.values.logo_4_score)
    formdata.append("logo_5_score", formState.values.logo_5_score)
    formdata.append("logo_6_score", formState.values.logo_6_score)
    formdata.append("logo_7_score", formState.values.logo_7_score)
    formdata.append("logo_8_score", formState.values.logo_8_score)
    formdata.append("logo_9_score", formState.values.logo_9_score)
    formdata.append("logo_10_score", formState.values.logo_10_score)
    formdata.append("logo_11_score", formState.values.logo_11_score)
    formdata.append("logo_12_score", formState.values.logo_12_score)
    formdata.append("logo_13_score", formState.values.logo_13_score)
    formdata.append("logo_14_score", formState.values.logo_14_score)
    formdata.append("logo_15_score", formState.values.logo_15_score)
    // !

    dispatchFormState({ type: SET_LOADING, loading: true })

    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data.success
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const checkIsComplete = (response) => {
    let is_form_section_completed = false

    if (
      response.data.data.logoform_logo_1_score &&
      response.data.data.logoform_logo_2_score &&
      response.data.data.logoform_logo_3_score &&
      response.data.data.logoform_logo_4_score &&
      response.data.data.logoform_logo_5_score &&
      response.data.data.logoform_logo_6_score &&
      response.data.data.logoform_logo_7_score &&
      response.data.data.logoform_logo_8_score &&
      response.data.data.logoform_logo_9_score &&
      response.data.data.logoform_logo_10_score &&
      response.data.data.logoform_logo_11_score &&
      response.data.data.logoform_logo_12_score &&
      response.data.data.logoform_logo_13_score &&
      response.data.data.logoform_logo_14_score &&
      response.data.data.logoform_logo_15_score
    ) {
      is_form_section_completed = true
    }

    return is_form_section_completed
  }

  const get_form = async () => {
    dispatchFormState({ type: SET_GET_LOADING, loading: true })

    const url =
      Config.aioUrl + "/project/forms/logoform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: checkIsComplete(response),
        })
      })
      .catch(function (error) {})
      .finally(() =>
        dispatchFormState({ type: SET_GET_LOADING, loading: false })
      )
  }

  useEffect(() => {
    get_form()
  }, [])

  const isNotAccessToEditInputs =
    (formState.values.is_staff_in_project &&
      capabilities.includes("project_logoform_update_own_project")) ||
    is_customer ||
    capabilities.includes("project_logoform_update_own_department")
      ? false
      : true

  if (formState.values.get_loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginTop: 20,
          }}
        >
          در حال بارگذاری اطلاعات...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 15,
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote
          title="اطلاعات این صفحه تکمیل شده است."
          icon="information-circle-outline"
          backgroundColor={Colors.green}
          textColor="#fff"
        />
      )}

      <InputNote
        wrapperStyle={{ marginBottom: 30 }}
        title="امتیاز به لوگو"
        content={`برای درک بهتر سلیقه شما، لطفا به ${logos.length} لوگو زیر امتیاز دهید.`}
      />

      {logos.map((logoItem, index) => (
        <View
          key={logoItem.id}
          style={{
            width: "100%",
            height: DESKTOP ? 150 : "auto",
            borderRadius: 20,
            backgroundColor: Colors.cardBg,
            flexDirection: DESKTOP ? "row-reverse" : "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 10,
            padding: DESKTOP ? 27 : 10,
            paddingTop: DESKTOP ? 27 : 20,
            marginVertical: 10,
            opacity: isNotAccessToEditInputs ? "80%" : "100%",
          }}
        >
          {Platform.OS === "web" ? (
            <img
              style={{
                width: DESKTOP ? 130 : 200,
                height: DESKTOP ? 130 : 200,
                borderRadius: 10,
              }}
              src={logoItem.img}
            />
          ) : (
            <Image
              style={{
                width: DESKTOP ? 130 : 200,
                height: DESKTOP ? 130 : 200,
                borderRadius: 10,
              }}
              source={logoItem.img}
            />
          )}

          <View
            style={{
              justifyContent: "space-evenly",
              alignItems: DESKTOP ? "flex-end" : "center",
              flexGrow: 3,
              padding: 15,
              gap: 15,
              width: !DESKTOP && "100%",
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: DESKTOP ? 18 : 14,
                color: "#424242",
                marginRight: 5,
                fontWeight: "bold",
              }}
            >
              امتیاز شما به این لوگو {formState.values[logoItem.id]} از 10
            </Text>

            <Range
              leftText="0"
              rightText="10"
              minimumValue={0}
              maximumValue={10}
              disabled={isNotAccessToEditInputs}
              id={logoItem.id}
              onChange={inputChangeHandler}
              noBorder
              leftColor={Colors.primary}
              rightColor={Colors.primary}
              insert={formState.values[logoItem.id]}
              fixedValue={true}
            />
          </View>
        </View>
      ))}

      {!isNotAccessToEditInputs && (
        <Btn
          title={
            formState.values.form_send_progress > 0 &&
            formState.values.form_send_progress < 1
              ? "در حال ارسال اطلاعات...(" +
                (formState.values.form_send_progress * 100).toFixed(0) +
                "%)"
              : "ثبت اطلاعات فرم"
          }
          onPress={async () => {
            let result = await send_form()

            if (result) {
              await get_form()

              setSteps("لوگو مورد علاقه")
            }
          }}
          wrapperStyle={{ marginVertical: 50 }}
        />
      )}
    </ScrollView>
  )
}

export default RatingLogosTab
