import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import Btn from "../../components/Btn"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { Ionicons } from "@expo/vector-icons"
import InputNote from "../../components/InputNote"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSE = "SET_COURSE"
const TOGGLE_SHOW_ORDER_CHANGE = "TOGGLE_SHOW_ORDER_CHANGE"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSE:
      let result = []
      if (action.data.sections.length != 0) {
        let sortedData = action.data.sections.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1
          }
          return 0
        })

        const hierarchySortFunc = (a, b) => {
          return a.priority < b.priority
        }

        const hierarhySort = (hashArr, key, result) => {
          if (hashArr[key] == undefined) return
          let arr = hashArr[key].sort(hierarchySortFunc)
          for (let i = 0; i < arr.length; i++) {
            result.push(arr[i])
            hierarhySort(hashArr, arr[i].id, result)
          }
          return result
        }

        let arr = sortedData
        let hashArr = {}
        for (let i = 0; i < arr.length; i++) {
          if (hashArr[arr[i].parent_id] == undefined)
            hashArr[arr[i].parent_id] = []
          hashArr[arr[i].parent_id].push(arr[i])
        }
        result = hierarhySort(hashArr, 0, [])
      }
      return {
        ...state,
        values: {
          ...state.values,
          sections: result,
        },
      }
    case TOGGLE_SHOW_ORDER_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          order_change_show: state.values.order_change_show ? false : true,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const CourseScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const course_id = props.route.params.course_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      sections: [],
      order_change_show: false,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_course = async () => {
    const url = Config.aioUrl + "/lms/course/get?id=" + course_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_COURSE, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const change_order = async () => {
    const url = Config.aioUrl + "/lms/course/changeOrder"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let target_data = []
    for (let i = 0; i < formState.values.sections.length; i++) {
      let item = {
        id: formState.values.sections[i].id,
        priority: formState.values.sections[i].priority,
      }
      target_data.push(item)
    }

    let formdata = new FormData()
    formdata.append("course_id", course_id)
    formdata.append("target_data", JSON.stringify(target_data))

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_course()
    })

    return focused
  }, [props.navigation])

  const change_order_handler = (id, type) => {
    let sections = formState.values.sections

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id == id) {
        if (sections[i].parent_id != 0) {
          if (type == "up") {
            if (sections[i - 1].parent_id != 0) {
              sections[i - 1]["priority"] = sections[i].priority
              sections[i]["priority"] = sections[i].priority - 1
            }
          } else {
            if (sections[i + 1].parent_id != 0) {
              sections[i + 1]["priority"] = sections[i].priority
              sections[i]["priority"] = sections[i].priority + 1
            }
          }
        } else {
          if (type == "up") {
            if (sections[i].priority != 1) {
              for (let j = 0; j < sections.length; j++) {
                if (
                  sections[j].priority == sections[i].priority - 1 &&
                  sections[j].parent_id == 0
                )
                  sections[j]["priority"] = sections[i].priority
              }
              sections[i]["priority"] = sections[i].priority - 1
            }
          } else {
            let last_chapter_priority = 0
            for (let j = 0; j < sections.length; j++) {
              if (sections[j].parent_id == 0) last_chapter_priority++
            }
            if (sections[i].priority != last_chapter_priority) {
              for (let k = 0; k < sections.length; k++) {
                if (
                  sections[k].priority == sections[i].priority + 1 &&
                  sections[k].parent_id == 0
                )
                  sections[k]["priority"] = sections[i].priority
              }
              sections[i]["priority"] = sections[i].priority + 1
            }
          }
        }
      }
    }

    dispatchFormState({ type: SET_COURSE, data: { sections: sections } })
  }

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="دوره" noNotif noActiveSessions noToggle />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {formState.values.loading ? (
          <LoadingPlaceHolder />
        ) : (
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "row-reverse" }}>
              <View style={{ flex: 1, paddingBottom: 20, marginLeft: 3 }}>
                <Btn
                  title="افزودن بخش جدید"
                  color={Colors.blue}
                  titleStyle={{ fontSize: 12 }}
                  onPress={() => {
                    props.navigation.navigate("Section", {
                      course_id: course_id,
                    })
                  }}
                />
              </View>
              <View style={{ flex: 1, marginRight: 3 }}>
                {!formState.values.order_change_show && (
                  <Btn
                    title="تغییر ترتیب"
                    color={Colors.error}
                    titleStyle={{ fontSize: 12 }}
                    onPress={() => {
                      dispatchFormState({ type: TOGGLE_SHOW_ORDER_CHANGE })
                    }}
                  />
                )}

                {formState.values.order_change_show && (
                  <View style={{ flex: 1, flexDirection: "row-reverse" }}>
                    <Btn
                      title="ثبت"
                      color={Colors.green}
                      titleStyle={{ fontSize: 12 }}
                      onPress={async () => {
                        await change_order()
                        dispatchFormState({ type: TOGGLE_SHOW_ORDER_CHANGE })
                      }}
                      wrapperStyle={{ flex: 1, marginLeft: 3 }}
                    />
                    <Btn
                      title="انصراف"
                      color={Colors.primary}
                      titleStyle={{ fontSize: 12 }}
                      onPress={() => {
                        dispatchFormState({ type: TOGGLE_SHOW_ORDER_CHANGE })
                      }}
                      wrapperStyle={{ flex: 1, marginRight: 3 }}
                    />
                  </View>
                )}
              </View>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ paddingBottom: 50 }}
            >
              {formState.values.sections.map((item) => (
                <View style={{ flexDirection: "row-reverse" }} key={item.id}>
                  <View style={{ flex: 4 }}>
                    <TouchableOpacity
                      disabled={!item.has_content}
                      style={{
                        width: "100%",
                        backgroundColor: !item.has_content
                          ? "transparent"
                          : "#F2F2F2",
                        borderRadius: 15,
                        paddingHorizontal: 20,
                        paddingTop: 20,
                        paddingBottom: !item.has_content ? 5 : 20,
                        marginBottom: 10,
                        flexDirection: "row-reverse",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      onPress={() => {
                        props.navigation.navigate("Section", {
                          section_id: item.id,
                          course_id: course_id,
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: !item.has_content
                            ? "IranSans-Medium"
                            : "IranSans-Regular",
                          fontSize: !item.has_content ? 16 : 14,
                          color: Colors.text,
                          marginRight: 5,
                        }}
                      >
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {formState.values.order_change_show && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          change_order_handler(item.id, "up")
                        }}
                      >
                        <Ionicons
                          name="arrow-up-outline"
                          color={Colors.blue}
                          size={24}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onPress={() => {
                          change_order_handler(item.id, "down")
                        }}
                      >
                        <Ionicons
                          name="arrow-down-outline"
                          color={Colors.primary}
                          size={24}
                        />
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CourseScreen
