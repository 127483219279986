import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import TicketListScreen, {
  screenOptions as TicketListScreenOptions,
} from "../screens/Support/TicketListScreen"
import TicketScreen, {
  screenOptions as TicketScreenOptions,
} from "../screens/Support/TicketScreen"
import CoursesListScreen, {
  screenOptions as CoursesListScreenOptions,
} from "../screens/Support/CoursesListScreen"
import CourseScreen, {
  screenOptions as CourseScreenOptions,
} from "../screens/Support/CourseScreen"
import SectionScreen, {
  screenOptions as SectionScreenOptions,
} from "../screens/Support/SectionScreen"
import SetCourseScreen, {
  screenOptions as SetCourseScreenOptions,
} from "../screens/Support/SetCourseScreen"
import SetCourseAccessScreen, {
  screenOptions as SetCourseAccessScreenOptions,
} from "../screens/Support/SetCourseAccessScreen"

import MyLessonScreen, {
  screenOptions as MyLessonScreennOptions,
} from "../screens/LMS/LessonScreen"
import MyCoursesListScreen, {
  screenOptions as MyCoursesListScreenOptions,
} from "../screens/LMS/CoursesListScreen"
import MyCourseScreen, {
  screenOptions as MyCourseScreenOptions,
} from "../screens/LMS/CourseScreen"
import SetStaffCourseAccessScreen, {
  screenOptions as SetStaffCourseAccessScreenOptions,
} from "../screens/Support/SetStaffCourseAccessScreen"

const SupportStackNavigator = createNativeStackNavigator()
const SupportNavigator = () => {
  return (
    <SupportStackNavigator.Navigator>
      <SupportStackNavigator.Screen
        name="CoursesList"
        component={CoursesListScreen}
        options={{ ...CoursesListScreenOptions, title: "لیست دوره ها" }}
      />

      <SupportStackNavigator.Screen
        name="SetCourse"
        component={SetCourseScreen}
        initialParams={{ course_id: false }}
        options={{ ...SetCourseScreenOptions, title: "دوره" }}
      />

      <SupportStackNavigator.Screen
        name="SetCourseAccess"
        component={SetCourseAccessScreen}
        options={{ ...SetCourseAccessScreenOptions, title: "دسترسی دوره ها" }}
      />

      <SupportStackNavigator.Screen
        name="EditCourse"
        component={CourseScreen}
        options={{ ...CourseScreenOptions, title: "دوره" }}
      />

      <SupportStackNavigator.Screen
        name="Section"
        component={SectionScreen}
        initialParams={{ section_id: false }}
        options={{ ...SectionScreenOptions, title: "درس" }}
      />

      <SupportStackNavigator.Screen
        name="TicketList"
        component={TicketListScreen}
        options={{ ...TicketListScreenOptions, title: "لیست تیکت ها" }}
      />
      
      <SupportStackNavigator.Screen
        name="Ticket"
        component={TicketScreen}
        options={{ ...TicketScreenOptions, title: "تیکت" }}
      />

      {/* //! LMS //! */}

      <SupportStackNavigator.Screen
        name="MyCoursesList"
        component={MyCoursesListScreen}
        options={{ ...MyCoursesListScreenOptions, title: "دوره های من" }}
      />

      <SupportStackNavigator.Screen
        name="SetStaffCourseAccess"
        component={SetStaffCourseAccessScreen}
        options={{
          ...SetStaffCourseAccessScreenOptions,
          title: "دسترسی دوره پرسنل",
        }}
      />

      <SupportStackNavigator.Screen
        name="Course"
        component={MyCourseScreen}
        options={{ ...MyCourseScreenOptions, title: "دوره" }}
      />

      <SupportStackNavigator.Screen
        name="MyCourseLesson"
        component={MyLessonScreen}
        options={{ ...MyLessonScreennOptions, title: "درس" }}
      />

      {/* //! LMS //! */}
    </SupportStackNavigator.Navigator>
  )
}

export default SupportNavigator
