import { useReducer, useCallback, useEffect } from "react";
import { View, Text, ScrollView, ActivityIndicator, TouchableOpacity } from "react-native";
import Colors from "../../../constants/Colors";
import Btn from "../../../components/Btn";
import Input from "../../../components/Input";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../constants/Config";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";
import { logOut } from "../../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_CUSTOMER = "SET_CUSTOMER";
const TOGGLE_EDIT = "TOGGLE_EDIT";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_CUSTOMER:
      return {
        ...state,
        values: {
          ...state.values,
          customer: action.data,
        },
      };
    case TOGGLE_EDIT:
      let newEdit = state.values.edit ? false : true;
      return {
        ...state,
        values: {
          ...state.values,
          edit: newEdit,
        },
      };
    default:
      return state;
  }
};

const InfoTab = (props) => {
  const customer_id = props.route.params.id;
  const token = useSelector((store) => store.user.token);
  const capabilities = useSelector((store) => store.user.capabilities);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      showAlert: false,
      loading: false,
      edit: false,
      customer_firstname: false,
      customer_lastname: false,
      customer_mobile: false,
    },
    validities: {},
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity });
    },
    [dispatchFormState]
  );

  const get_customer = async () => {
    const url = Config.aioUrl + "/customer/get?id=" + customer_id;
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_CUSTOMER, data: response.data.data });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        console.log(error);
      });
  };

  const edit_customer = async () => {
    const url = Config.aioUrl + "/customer/edit";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    let formdata = new FormData();
    formdata.append("id", customer_id);
    formdata.append("first_name", formState.values.customer_firstname);
    formdata.append("last_name", formState.values.customer_lastname);
    formdata.append("mobile", formState.values.customer_mobile);

    if (!formState.values.customer_firstname || !formState.values.customer_lastname || !formState.values.customer_mobile) {
      alert("اطلاعات نمی تواند خالی باشد.");
    } else {
      dispatchFormState({ type: SET_LOADING, loading: true });
      axios({
        method: "post",
        url: url,
        data: formdata,
        headers: reqHedear,
      })
        .then(function (response) {
          if (response.data.code == 401) {
            alert("نیاز است مجددا به سیستم ورود کنید.");
            logOut();
          }
          alert(response.data.msg);
          dispatchFormState({ type: SET_LOADING, loading: false });
          dispatchFormState({ type: TOGGLE_EDIT });
          get_customer();
        })
        .catch(function (error) {
          dispatchFormState({ type: SET_LOADING, loading: false });
          console.log(error);
        });
    }
  };

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value);
  };

  useEffect(() => {
    get_customer();
  }, []);

  return (
    <View style={{ height: "100%", overflow: "hidden", backgroundColor: Colors.bg }}>
      {!formState.values.customer && (
        <View style={{ width: "100%", height: "50%", justifyContent: "center", alignItems: "center" }}>
          <ActivityIndicator size="large" color={Colors.primary} />
          <Text style={{ fontFamily: "IranSans-Medium", fontSize: 16, color: Colors.text, marginTop: 20 }}>در حال بارگذاری اطلاعات...</Text>
        </View>
      )}

      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50, paddingTop: 0 }}>
        {formState.values.customer && !formState.values.edit && (
          <View style={{ zIndex: 2 }}>
            <View
              style={{
                width: "100%",
                marginTop: 15,
                flexDirection: "row-reverse",
                zIndex: 2,
              }}
            >
              <View style={{ flex: 1, marginLeft: 5 }}>
                <Btn
                  title="واتسپ"
                  color={Colors.green}
                  onPress={() => {
                    Linking.openURL("whatsapp://send?phone=+" + formState.values.customer.mobile);
                  }}
                />
              </View>
              <View style={{ flex: 1, marginRight: 5 }}></View>
            </View>
            <View
              style={{
                backgroundColor: "#F2F2F2",
                width: "100%",
                paddingHorizontal: 15,
                paddingVertical: 15,
                borderRadius: 15,
                marginTop: 15,
              }}
            >
              <View style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                <View>
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: 14, color: Colors.text }}>
                    {formState.values.customer.first_name + " " + formState.values.customer.last_name}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    copyToClipboard(formState.values.customer.mobile);
                  }}
                >
                  <Text style={{ fontFamily: "IranSans-Regular", fontSize: 14, color: Colors.text }}>{formState.values.customer.mobile}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        )}

        {formState.values.edit && (
          <View style={{ marginTop: 20 }}>
            <Input label="نام مشتری" id="customer_firstname" onChange={inputChangeHandler} insert={formState.values.customer.first_name} validity />
            <Input
              label="نام خانوادگی مشتری"
              id="customer_lastname"
              onChange={inputChangeHandler}
              insert={formState.values.customer.last_name}
              validity
            />
            <Input
              label="شماره موبایل مشتری"
              id="customer_mobile"
              onChange={inputChangeHandler}
              insert={formState.values.customer.mobile}
              validity
              onlyNumber
              convertDigits
              mobile
            />
          </View>
        )}

        {capabilities.includes("customer_edit_all") && (
          <Btn
            title={formState.values.edit ? "ثبت تغییرات" : "ویرایش"}
            color={formState.values.edit ? Colors.primary : Colors.blue}
            onPress={() => {
              if (formState.values.edit) {
                edit_customer();
              } else {
                dispatchFormState({ type: TOGGLE_EDIT });
              }
            }}
            loading={formState.values.loading}
            wrapperStyle={{ marginTop: 30, zIndex: 1 }}
          />
        )}
        {formState.values.edit && (
          <Btn
            title={"انصراف از ویرایش"}
            color={Colors.secondary}
            onPress={() => {
              dispatchFormState({ type: TOGGLE_EDIT });
            }}
            wrapperStyle={{ marginTop: 30 }}
          />
        )}
      </ScrollView>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
};

export default InfoTab;
