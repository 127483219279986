import { useEffect, useReducer, useState } from "react"
import {
  View,
  Text,
  StyleSheet,
  Switch,
  FlatList,
  TouchableOpacity,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { useSelector } from "react-redux"
import Btn from "../../components/Btn"
import axios from "axios"
import Config from "../../constants/Config"
import { logOut } from "../../components/Functions"
import * as Location from "expo-location"
import moment from "moment-jalaali"
import AsyncStorage from "@react-native-async-storage/async-storage"

const SET_LOADING = "SET_LOADING"
const SET_ENTRANCE = "SET_ENTRANCE"
const SET_RECORDS = "SET_RECORDS"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_ENTRANCE:
      return {
        ...state,
        values: {
          ...state.values,
          entrance: state.values.entrance == 1 ? 0 : 1,
        },
      }
    case SET_RECORDS:
      return {
        ...state,
        values: {
          ...state.values,
          records: action.data,
        },
      }
    default:
      return state
  }
}

const RecordDay = (props) => {
  const { itemData } = props
  const [day_open, set_day_open] = useState(false)

  return (
    <View>
      <TouchableOpacity
        style={{
          width: "100%",
          backgroundColor:
            itemData.item.type == "penalty"
              ? Colors.primary
              : itemData.item.type == "late"
              ? Colors.error
              : itemData.item.type == "early"
              ? Colors.green
              : "#F2F2F2",
          borderRadius: 15,
          paddingVertical: 20,
          paddingHorizontal: 20,
          marginBottom: 10,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onPress={() => set_day_open((prev) => !prev)}
      >
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color:
                itemData.item.type != "normal"
                  ? "#fff"
                  : itemData.item.day_title == "آدینه"
                  ? Colors.primary
                  : Colors.text,
              textAlign: "right",
            }}
          >
            {itemData.item.date}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 10,
              color:
                itemData.item.type != "normal"
                  ? "#fff"
                  : itemData.item.day_title == "آدینه"
                  ? Colors.primary
                  : Colors.text,
            }}
          >
            {itemData.item.day_title == "آدینه"
              ? "جمعه"
              : itemData.item.day_title}
          </Text>
        </View>
      </TouchableOpacity>
      {itemData.item.data.length != 0 && day_open && (
        <TouchableOpacity
          style={{
            width: "100%",
            borderColor: "#F2F2F2",
            borderWidth: 1,
            borderRadius: 15,
            paddingVertical: 20,
            paddingHorizontal: 20,
            marginBottom: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => set_day_open((prev) => !prev)}
        >
          {itemData.item.data.map((item_data, index) => (
            <View
              key={item_data.id}
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginVertical: 5,
              }}
            >
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  {moment.unix(item_data.time).format("HH:mm")}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  {item_data.entrance == "1" ? "ورود" : "خروج"}
                </Text>
              </View>
            </View>
          ))}
        </TouchableOpacity>
      )}
    </View>
  )
}

const SetRecordScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      entrance: 1,
      latitude: null,
      longitude: null,
      records: [],
    },
  })

  const get_user_location = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync()
    if (status !== "granted") {
      alert("اجازه داده نشد")
      return false
    }

    let location = await Location.getCurrentPositionAsync({
      accuracy: "Highest",
      distanceInterval: 0,
    })
    let last_location_time = await AsyncStorage.getItem("last_location_time")
    if (parseInt(last_location_time) == parseInt(location.timestamp)) {
      alert("لطفا یک بار اپ را کامل ببندید و مجدد به این صفحه برگردید.")
      return false
    } else {
      last_location_time = location.timestamp
      await AsyncStorage.setItem(
        "last_location_time",
        last_location_time.toString()
      )
      return location
    }
  }

  const process_records = async (records) => {
    let processed_records = []
    const numberOfDays = [1, 2, 3, 4, 5, 6].includes(moment().jMonth() + 1)
      ? 31
      : moment().jMonth() + 1 == 12
      ? 29
      : 30
    for (let i = 1; i <= numberOfDays; i++) {
      if (i > moment().jDate()) break
      const day_beginning = moment()
        .startOf("jmonth")
        .add(i - 1, "day")
        .unix()
      const day_end = moment().startOf("jmonth").add(i, "day").unix() - 1
      let day_data = []
      let day_type = "normal"
      for (let j = 0; j < records.length; j++) {
        if (
          parseInt(records[j].time) >= day_beginning &&
          parseInt(records[j].time) <= day_end
        ) {
          if (
            day_type != "penalty" &&
            day_type != "late" &&
            records[j].type == "early"
          )
            day_type = "early"
          if (day_type != "penalty" && records[j].type == "late")
            day_type = "late"
          if (records[j].type == "penalty") day_type = "penalty"
          day_data.push(records[j])
        }
      }
      processed_records.push({
        day: i,
        day_title: moment.unix(day_beginning).format("dddd"),
        date: moment().jYear() + "/" + (moment().jMonth() + 1) + "/" + i,
        type: day_type,
        data: day_data,
      })
    }

    return processed_records.sort((a, b) => b.day - a.day)
  }

  const get_records = async () => {
    const url = Config.aioUrl + "/eam/staff/getrecords"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    let response_records = await axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.code == 200) {
          console.log("RESPONSE: ", response.data)
          return response
        } else {
          return false
        }
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })

    if (!response_records) {
      alert("در دریافت رکوردها خطایی رخ داد")
      return
    }

    let processed_records = await process_records(response_records.data.data)

    // console.clear()
    // console.log("BEFORE: ", response_records)
    // console.log("AFTER: ", processed_records)
    // return false

    dispatchFormState({ type: SET_LOADING, loading: false })
    dispatchFormState({ type: SET_RECORDS, data: processed_records })
  }

  const set_record = async () => {
    dispatchFormState({ type: SET_LOADING, loading: true })
    const location = await get_user_location()
    if (!location) {
      alert("در دریافت موقعیت مکانی مشکلی رخ داده است.")
      dispatchFormState({ type: SET_LOADING, loading: false })
      return
    }

    const latitude = location.coords.latitude
    const longitude = location.coords.longitude
    const url = Config.aioUrl + "/eam/staff/setrecord"
    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("entrance", formState.values.entrance)
    formdata.append("latitude", latitude)
    formdata.append("longitude", longitude)
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)

        get_records()

        // props.navigation.dispatch(
        //   CommonActions.reset({
        //     index: 1,
        //     routes: [
        //       {
        //         name: "SetRecord",
        //       },
        //     ],
        //   })
        // )
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_records()
    })

    return focused
  }, [props.navigation])

  return (
    <View style={styles.wrapper}>
      <Header title="ثبت تردد" noBack />
      <View
        style={{
          width: "100%",
          flexDirection: "row-reverse",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 30,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          ورود
        </Text>
        <Switch
          trackColor={{ false: Colors.secondary, true: Colors.secondary }}
          thumbColor={"rgb(0, 150, 136)"}
          onValueChange={() => {
            dispatchFormState({ type: SET_ENTRANCE })
          }}
          value={formState.values.entrance}
          style={{ marginHorizontal: 10 }}
        />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          خروج
        </Text>
      </View>
      <Btn
        title={formState.values.entrance == 1 ? "ثبت ورود شما" : "ثبت خروج شما"}
        onPress={() => {
          set_record()
        }}
        color={Colors.secondary}
        loading={formState.values.loading}
      />
      <View style={{ marginTop: 60, flex: 1 }}>
        <FlatList
          data={formState.values.records}
          keyExtractor={(item) => item.day}
          showsVerticalScrollIndicator={false}
          renderItem={(itemData) => <RecordDay itemData={itemData} />}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default SetRecordScreen
