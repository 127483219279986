import { useCallback, useReducer, useEffect } from "react";
import { View, ScrollView, Text, StyleSheet, ActivityIndicator, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Btn from "../../components/Btn";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Config from "../../constants/Config";
import Picker from "../../components/Picker";

const INPUT_CHANGE = "INPUT_CHANGE";
const ALERT_SHOW = "ALERT_SHOW";
const SET_LOADING = "SET_LOADING";
const SET_ACCESS = "SET_ACCESS";
const SET_COURSES = "SET_COURSES";
const CHANGE_ACCESS = "CHANGE_ACCESS";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      };
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertMessage: action.msg,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_ACCESS:
      return {
        ...state,
        values: {
          ...state.values,
          access: action.data,
        },
      };
    case SET_COURSES:
      let changedData = action.data;
      changedData = changedData.map(({ title: item, ...rest }) => ({
        item,
        ...rest,
      }));
      return {
        ...state,
        values: {
          ...state.values,
          courses: changedData,
        },
      };
    case CHANGE_ACCESS:
      let newAccess = state.values.access;
      for (let i = 0; i < newAccess.length; i++) {
        if (newAccess[i].id == action.id) {
          newAccess[i].has_access = action.active;
        }
      }

      return {
        ...state,
        values: {
          ...state.values,
          access: newAccess,
        },
      };

    default:
      return state;
  }
};

const SetStaffCourseAccessScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      access: [],
      courses: [],
      selectedCourse: null,
    },
    validities: {},
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity });
    },
    [dispatchFormState]
  );

  const get_course_access = async () => {
    const url = Config.aioUrl + "/lms/course/employee/accessList?course_id=" + formState.values.selectedCourse.id;
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_ACCESS, data: response.data.data });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  const get_courses = async () => {
    // const url = Config.aioUrl + "/lms/course/employee/accessList";
    const url = Config.aioUrl + "/lms/course/list";

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_COURSES, data: response.data.data });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  const edit_access = async () => {
    const url = Config.aioUrl + "/lms/course/employee/accessEdit";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    let reqData = JSON.stringify({
      course_id: formState.values.selectedCourse.id,
      access: formState.values.access,
    });
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  useEffect(() => {
    if (formState.values.selectedCourse) {
      get_course_access();
    }
  }, [formState.values.selectedCourse]);

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_courses();
    });

    return focused;
  }, [props.navigation]);

  const change_access_status = (id, active) => {
    dispatchFormState({ type: CHANGE_ACCESS, id: id, active: active });
  };

  
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="دسترسی دوره پرسنل" noBack />
      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50 }}>
        <View style={{ flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 1,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>انتخاب دوره</Text>
            </View>
            {formState.values.courses.length != 0 && (
              <Picker label="دوره" id="selectedCourse" onChange={inputChangeHandler} options={formState.values.courses} />
            )}
            {formState.values.selectedCourse && (
              <Btn
                title="ثبت تغییرات"
                onPress={() => {
                  edit_access();
                }}
                loading={formState.values.loading}
                wrapperStyle={{ marginBottom: 30 }}
              />
            )}
          </View>
          <View
            style={{
              flex: 3,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>دسترسی های دوره</Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row-reverse" }}>
              <View style={{ flex: 1, paddingHorizontal: 25, paddingBottom: 20 }}>
                <Text style={{ fontFamily: "IranSans-Medium", marginBottom: 20, textAlign: "center" }}>دارای دسترسی</Text>
                {formState.values.access
                  .filter((item) => {
                    if (item.has_access) return true;
                  })
                  .map((item, index) => (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        change_access_status(item.id, false);
                      }}
                    >
                      <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10, fontSize: 12 }}>
                        {item.name + " (" + item.mobile + ")"}
                      </Text>
                    </TouchableOpacity>
                  ))}
              </View>
              <View style={{ flex: 1, paddingHorizontal: 25 }}>
                <Text style={{ fontFamily: "IranSans-Medium", marginBottom: 20, textAlign: "center" }}>بدون دسترسی</Text>
                {formState.values.access
                  .filter((item) => {
                    if (!item.has_access) return true;
                  })
                  .map((item, index) => (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        change_access_status(item.id, true);
                      }}
                    >
                      <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10, fontSize: 12 }}>
                        {item.name + " (" + item.mobile + ")"}
                      </Text>
                    </TouchableOpacity>
                  ))}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
});

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default SetStaffCourseAccessScreen;
