import { useCallback, useReducer, useEffect } from "react";
import { View, ScrollView, Text, StyleSheet, ActivityIndicator } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Input from "../../components/Input";
import Btn from "../../components/Btn";
import { useSelector } from "react-redux";
import AwesomeAlert from "react-native-awesome-alerts";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Config from "../../constants/Config";
import Picker from "../../components/Picker";
import { separate } from "../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const ALERT_SHOW = "ALERT_SHOW";
const SET_LOADING = "SET_LOADING";
const SET_SERVICES = "SET_SERVICES";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      };
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertMessage: action.msg,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_SERVICES:
      let sepratedServices = {
        site: [],
        extraSite: [],
        graphic: [],
        extraGraphic: [],
      };

      for (let i = 0; i < action.data.length; i++) {
        if (action.data[i].category == "site" && action.data[i].type == "primary") {
          sepratedServices.site.push(action.data[i]);
        }
        if (action.data[i].category == "site" && action.data[i].type == "extra") {
          sepratedServices.extraSite.push(action.data[i]);
        }
        if (action.data[i].category == "graphic" && action.data[i].type == "primary") {
          sepratedServices.graphic.push(action.data[i]);
        }
        if (action.data[i].category == "graphic" && action.data[i].type == "extra") {
          sepratedServices.extraGraphic.push(action.data[i]);
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          services: sepratedServices,
        },
      };
    default:
      return state;
  }
};

const ServicesScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      title: "",
      price: "",
      category: "",
      type: "",
      loading: false,
      services: {},
    },
    validities: {
      title: true,
      price: true,
      category: true,
      type: true,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity });
    },
    [dispatchFormState]
  );

  const formValidity = (form) => {
    const areTrue = Object.values(form.validities).every((value) => value != false);
    return areTrue;
  };

  const add_service = async (data) => {
    const url = Config.aioUrl + "/service/add";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    let reqData = JSON.stringify({
      title: data.title,
      price: data.price,
      category: data.category,
      type: data.type,
    });
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: response.data.msg });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  const get_service = async () => {
    const url = Config.aioUrl + "/service/get";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_SERVICES, data: response.data.data });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  const delete_service = async (data) => {
    const url = Config.aioUrl + "/service/delete";
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    var reqData = JSON.stringify({
      id: data.id,
    });
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: response.data.msg });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: ALERT_SHOW, show: true, msg: error.response.data.message });
      });
  };

  useEffect(() => {
    get_service();
  }, []);
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="خدمات" reset="Services" noBack />
      <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: 50 }}>
        <View style={{ flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 1,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>افزودن خدمت</Text>
            </View>
            <Input label="عنوان" id="title" onChange={inputChangeHandler} validity />
            <Input label="تعرفه" id="price" onChange={inputChangeHandler} validity convertDigits />
            <Picker
              label="دسته"
              id="category"
              onChange={inputChangeHandler}
              options={[
                { item: "سایت", id: "site" },
                { item: "گرافیک", id: "graphic" },
              ]}
            />
            <Picker
              label="نوع"
              id="type"
              onChange={inputChangeHandler}
              options={[
                { item: "اصلی", id: "primary" },
                { item: "جانبی", id: "extra" },
              ]}
            />
            <Btn
              title="ثبت"
              onPress={() => {
                add_service({
                  title: formState.values.title,
                  price: formState.values.price,
                  category: formState.values.category.id,
                  type: formState.values.type.id,
                });
              }}
              loading={formState.values.loading}
              wrapperStyle={{ marginBottom: 30 }}
            />
          </View>
          <View
            style={{
              flex: 3,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>خدمات</Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row-reverse" }}>
              <View style={{ flex: 1, paddingHorizontal: 25, paddingBottom: 20 }}>
                <Text style={{ fontFamily: "IranSans-Medium", marginBottom: 20, textAlign: "center" }}>سایت</Text>
                {!formState.values.loading && formState.values.services.site !== undefined && (
                  <View>
                    {formState.values.services.site.map((item, index) => (
                      <View key={index} style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{item.title}</Text>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{separate(item.price)}</Text>
                      </View>
                    ))}
                  </View>
                )}
                <View style={{ width: "100%", height: 1, backgroundColor: "#e6e6e6" }}></View>
                {!formState.values.loading && formState.values.services.site !== undefined && (
                  <View style={{ marginTop: 10 }}>
                    {formState.values.services.extraSite.map((item, index) => (
                      <View key={index} style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{item.title}</Text>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{separate(item.price)}</Text>
                      </View>
                    ))}
                  </View>
                )}
              </View>
              <View style={{ flex: 1, paddingHorizontal: 25 }}>
                <Text style={{ fontFamily: "IranSans-Medium", marginBottom: 20, textAlign: "center" }}>گرافیک</Text>
                {!formState.values.loading && formState.values.services.site !== undefined && (
                  <View>
                    {formState.values.services.graphic.map((item, index) => (
                      <View key={index} style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{item.title}</Text>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{separate(item.price)}</Text>
                      </View>
                    ))}
                  </View>
                )}
                <View style={{ width: "100%", height: 1, backgroundColor: "#e6e6e6" }}></View>
                {!formState.values.loading && formState.values.services.site !== undefined && (
                  <View style={{ marginTop: 10 }}>
                    {formState.values.services.extraGraphic.map((item, index) => (
                      <View key={index} style={{ flexDirection: "row-reverse", justifyContent: "space-between" }}>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{item.title}</Text>
                        <Text style={{ fontFamily: "IranSans-Light", marginBottom: 10 }}>{separate(item.price)}</Text>
                      </View>
                    ))}
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <AwesomeAlert
        show={formState.values.showAlert}
        showProgress={false}
        title="اعلان"
        message={formState.values.alertMessage ? formState.values.alertMessage : "ابتدا خطاها را بررسی کنید!"}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="متوجه شدم"
        confirmButtonColor={Colors.green}
        onConfirmPressed={() => {
          dispatchFormState({ type: ALERT_SHOW, show: false });
        }}
        titleStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        messageStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        confirmButtonTextStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
      />
    </View>
  );
};

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
});

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default ServicesScreen;
