import { useReducer, useEffect } from "react";
import { View, Text, FlatList, ActivityIndicator, Dimensions, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../components/Functions";
import Config from "../../constants/Config";
import moment from "moment-jalaali";
const windowWidth = Dimensions.get("window").width;

const SET_LOADING = "SET_LOADING";
const SET_REMINDERS = "SET_REMINDERS";
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION";
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_REMINDERS:
      return {
        ...state,
        values: {
          ...state.values,
          reminders: action.data,
        },
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        values: {
          ...state.values,
          active_section: action.value,
        },
      };

    default:
      return state;
  }
};

const Reminder = (props) => {
  const { item, navigation } = props;
  return (
    <TouchableOpacity
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        paddingVertical: 20,
        paddingLeft: 20,
        paddingRight: 0,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onPress={() => {
        navigation.navigate("Lead", { id: item.lead_data.id });
      }}
    >
      <View style={{ flex: 5 }}>
        <View
          style={{
            width: 80,
            height: 25,
            backgroundColor: item.reminder_data.done == "1" ? Colors.green : Colors.primary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>
            {moment.unix(item.reminder_data.time).format("jYYYY/jM/jD HH:mm")}
          </Text>
        </View>
      </View>
      <View style={{ flex: 5 }}>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>{item.lead_data.name}</Text>
        <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7, textAlign: "right" }}>
          {item.lead_data.phone}
        </Text>
        <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7 }}>{item.reminder_data.description}</Text>
      </View>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
        <Text style={{ transform: [{ rotate: "-90deg" }], fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}>
          #{item.reminder_data.id}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const RemindersScreen = (props) => {
  const DESKTOP = windowWidth > 800 ? true : false;
  const token = useSelector((store) => store.user.token);
  const capabilities = useSelector((store) => store.user.capabilities);

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      reminders: [],
      active_section: "me",
    },
  });

  const get_reminders = async (me) => {
    const url = Config.aioUrl + "/crm/reminders/get";

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        
        dispatchFormState({ type: SET_LOADING, loading: false });
        dispatchFormState({ type: SET_REMINDERS, data: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", async () => {
      get_reminders();
    });

    return focused;
  }, [props.navigation]);

  
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="یاداوری ها" noBack />
      {formState.values.loading && <ActivityIndicator size="large" color={Colors.primary} style={{ marginTop: 30 }} />}
      {!formState.values.loading && (
        <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "30%" : 0 }}>
          <View style={{ flexDirection: "row-reverse", alignItems: "center", height: 40 }}>
            <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>بخش: </Text>
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                width: 60,
                borderRadius: 10,
                backgroundColor: formState.values.active_section == "me" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({ type: SET_ACTIVE_SECTION, value: "me" });
              }}
            >
              <Text style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: formState.values.active_section == "me" ? "#fff" : Colors.text }}>
                من
              </Text>
            </TouchableOpacity>
            {capabilities.includes("crm_get_all_reminders") && (
              <TouchableOpacity
                style={{
                  paddingVertical: 5,
                  width: 60,
                  borderRadius: 10,
                  backgroundColor: formState.values.active_section == "all" ? Colors.blue : "#F2F2F2",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                }}
                onPress={() => {
                  dispatchFormState({ type: SET_ACTIVE_SECTION, value: "all" });
                }}
              >
                <Text
                  style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: formState.values.active_section == "all" ? "#fff" : Colors.text }}
                >
                  همه
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {formState.values.reminders.length != 0 && (
            <FlatList
              showsVerticalScrollIndicator={false}
              style={{ flex: 1, height: "100%" }}
              data={formState.values.active_section == "me" ? formState.values.reminders.me : formState.values.reminders.all}
              keyExtractor={(item) => item.reminder_data.id}
              renderItem={(itemData) => <Reminder item={itemData.item} navigation={props.navigation} />}
            />
          )}
        </View>
      )}
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default RemindersScreen;
