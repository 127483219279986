import { useReducer, useCallback, useEffect } from "react"
import { View, ScrollView } from "react-native"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import File from "../../../components/File"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_FILES = "SET_FILES"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case ALERT_SHOW:
      return {
        ...state,
        showAlert: action.show,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_FILES:
      return {
        ...state,
        files: action.data,
      }
    default:
      return state
  }
}

const FilesTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const id = props.route.params.id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    showAlert: false,
    loading: false,
    files: [],
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_files = async () => {
    const url = Config.aioUrl + "/project/file/list?id=" + id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_FILES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }
  
  useEffect(() => {
    get_files()
  }, [])

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingTop: 20,
        paddingHorizontal: 10,
      }}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingBottom: 50, paddingTop: 0 }}
      >
        {formState.files.map((item, index) => (
          <File
            standalone
            key={item.id}
            id={item.id}
            title={item.title}
            extension={item.extension}
            max_size={item.max_size}
            max_width={item.max_width}
            max_height={item.max_height}
            min_width={item.min_width}
            min_height={item.min_height}
            content_type={item.content_type}
            required={item.required == 1 ? true : false}
            token={token}
            insert={item.bucket_key ? item : false}
            value={formState[item.id]}
            uploadUrl={Config.aioUrl + "/project/file/upload"}
            downloadUrl={Config.aioUrl + "/project/file/get"}
            downloadParams={[
              {
                key: "id",
                value: item.id,
              },
            ]}
            // editOptions={{
            //   compress: 0.8,
            //   resizeHeight: item.max_height,
            //   resizeWidth: item.max_width,
            // }}
            doAfterUpload={get_files}
          />
        ))}
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default FilesTab
