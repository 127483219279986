import { useReducer, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { useSelector } from "react-redux";
import Input from "../../../components/Input";
import InputNote from "../../../components/InputNote";
import Colors from "../../../constants/Colors";
import Btn from "../../../components/Btn";
import Config from "../../../constants/Config";
import axios from "axios";
import { logOut } from "../../../components/Functions";

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM = "SET_FORM";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      };
    default:
      return state;
  }
};

const BankTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities);
  const project_id = props.route.params.project_id;
  const role = useSelector((store) => store.user.user_data.role);
  const is_customer = props.route.params.is_customer;
  const token = useSelector((store) => store.user.token);
  const setActiveIndex = props.route.params.setActiveIndex;
  const setSteps = props.route.params.setSteps;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      bank_name: "",
      bank_card_number: "",
      bank_shaba_number: "",
      form: null,
      is_form_section_completed: false,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/update";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("project_id", project_id);
    formdata.append("bank_name", formState.values.bank_name);
    formdata.append("bank_card_number", formState.values.bank_card_number);
    formdata.append("bank_shaba_number", formState.values.bank_shaba_number);

    dispatchFormState({ type: SET_LOADING, loading: true });
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, loading: false });
        return response.data.success;
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const get_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/get?project_id=" + project_id;

    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        let is_form_section_completed = false;
        if (response.data.data.siteform_bank_name && response.data.data.siteform_bank_card_number && response.data.data.siteform_bank_shaba_number) {
          is_form_section_completed = true;
        }
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: is_form_section_completed,
        });
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    get_form();
  }, []);

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote title="اطلاعات بانکی تکمیل شده است." icon="information-circle-outline" backgroundColor={Colors.green} textColor="#fff" />
      )}
      {is_customer && (
        <InputNote
          title="توضیح"
          content="اطلاعاتی که در این بخش وارد می کنید برای دریافت درگاه بانکی اقای پرداخت مورد استفاده قرار می گیرد. در نظر داشته باشید که درگاه آنلاین به همین حساب بانکی به نام شما متصل خواهد شد و واریزی ها به صورت اتوماتیک توسط سیستم بانکی به این حساب انجام می پذیرد بنابراین نهایت دقت در ورود اطلاعات را داشته باشید."
          icon="information-circle-outline"
        />
      )}
      {(is_customer || formState.values.is_staff_in_project || capabilities.includes("role_site_full_access")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="بانک"
          id="bank_name"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_bank_name : ""}
        />
      )}
      {(is_customer || formState.values.is_staff_in_project || capabilities.includes("role_site_full_access")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شماره کارت"
          id="bank_card_number"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_bank_card_number : ""}
          convertDigits
        />
      )}
      {(is_customer || formState.values.is_staff_in_project || capabilities.includes("role_site_full_access")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          label="شماره شبا"
          id="bank_shaba_number"
          onChange={inputChangeHandler}
          insert={formState.values.form ? formState.values.form.siteform_bank_shaba_number : ""}
          convertDigits
        />
      )}
      <Btn
        title="ثبت اطلاعات بانکی"
        onPress={async () => {
          if (
            (formState.values.is_staff_in_project && capabilities.includes("project_siteform_update_own_project")) ||
            capabilities.includes("role_site_full_access") ||
            role == "customer"
          ) {
            let result = await send_form();
            if (result) {
              await get_form();
              setSteps((prev) => {
                let prevState = prev;
                for (let i = 0; i < prevState.length; i++) {
                  if (prevState[i].name == "اطلاعات کسب و کار") {
                    prevState[i].status = "active";
                  } else {
                    prevState[i].status = "inactive";
                  }
                }
                return prevState;
              });
              props.navigation.navigate("اطلاعات کسب و کار");
              setActiveIndex(3);
            }
          } else {
            props.navigation.navigate("اطلاعات کسب و کار");
            setActiveIndex(3);
          }
        }}
        wrapperStyle={{ marginVertical: 50 }}
        loading={formState.values.loading}
      />
    </ScrollView>
  );
};

export default BankTab;
