import { useCallback, useReducer, useEffect } from "react"
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"
import Picker from "../../components/Picker"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_CAPABILITIES = "SET_CAPABILITIES"
const SET_STAFFS = "SET_STAFFS"
const CHANGE_CAPABILITY = "CHANGE_CAPABILITY"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertMessage: action.msg,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_CAPABILITIES:
      return {
        ...state,
        values: {
          ...state.values,
          capabilities: action.data,
        },
      }
    case SET_STAFFS:
      return {
        ...state,
        values: {
          ...state.values,
          staffs: action.data,
        },
      }
    case CHANGE_CAPABILITY:
      let newCapabilities = state.values.capabilities
      for (let i = 0; i < newCapabilities.length; i++) {
        if (newCapabilities[i].id == action.id) {
          newCapabilities[i].has_capability = action.active
        }
      }

      return {
        ...state,
        values: {
          ...state.values,
          capabilities: newCapabilities,
        },
      }

    default:
      return state
  }
}

const CapSection = (props) => {
  const {
    capabilities,
    change_access_status,
    access_to,
    sectionTitle,
    condition,
  } = props

  const filtered_capabilities = capabilities.filter((item) => {
    if (eval(condition)) return true
  })

  if (filtered_capabilities.length != 0) {
    return (
      <View style={{ marginBottom: 20 }}>
        <View
          style={{
            borderBottomWidth: 0.5,
            borderBottomColor: "rgba(47, 128, 237,0.5)",
            marginBottom: 10,
            paddingBottom: 5,
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: "rgba(47, 128, 237,0.5)",
            }}
          >
            {sectionTitle}
          </Text>
        </View>
        {filtered_capabilities.map((item, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              change_access_status(item.id, access_to)
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Light",
                marginBottom: 10,
                fontSize: 12,
              }}
            >
              {item.title + " (" + item.slug + ")"}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    )
  } else {
    return <View></View>
  }
}

const CapabilityScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      capabilities: [],
      staffs: [],
      selectedStaff: null,
    },
    validities: {},
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const get_capabilities = async () => {
    const url =
      Config.aioUrl +
      "/capabilities/get?staff_id=" +
      formState.values.selectedStaff.id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_CAPABILITIES, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const get_staffs = async () => {
    const url = Config.aioUrl + "/staffs/get"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_STAFFS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const edit_capabilities = async () => {
    const url = Config.aioUrl + "/capabilities/edit"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let reqData = JSON.stringify({
      staff_id: formState.values.selectedStaff.id,
      capabilities: formState.values.capabilities,
    })
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  useEffect(() => {
    get_staffs()
  }, [])

  useEffect(() => {
    if (formState.values.selectedStaff) {
      get_capabilities()
    }
  }, [formState.values.selectedStaff])

  const change_access_status = (id, active) => {
    dispatchFormState({ type: CHANGE_CAPABILITY, id: id, active: active })
  }

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="دسترسی ها" noBack />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingBottom: 50 }}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 1,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>
                انتخاب پرسنل
              </Text>
            </View>
            {formState.values.staffs.length != 0 && (
              <Picker
                label="پرسنل"
                id="selectedStaff"
                onChange={inputChangeHandler}
                options={formState.values.staffs}
              />
            )}
            {formState.values.selectedStaff && (
              <Btn
                title="ثبت تغییرات"
                onPress={() => {
                  edit_capabilities()
                }}
                loading={formState.values.loading}
                wrapperStyle={{ marginBottom: 30 }}
              />
            )}
          </View>
          <View
            style={{
              flex: 3,
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 0 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              marginVertical: 3,
              marginHorizontal: 5,
              borderRadius: 7,
              paddingHorizontal: 10,
            }}
          >
            <View style={Styles.cardHeader}>
              <Text style={{ fontFamily: "IranSans-Bold", fontSize: 16 }}>
                دسترسی های پرسنل
              </Text>
            </View>
            <View style={{ flex: 1, flexDirection: "row-reverse" }}>
              <View
                style={{ flex: 1, paddingHorizontal: 25, paddingBottom: 20 }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    marginBottom: 20,
                    textAlign: "center",
                  }}
                >
                  دسترسی های فعال
                </Text>
                <CapSection
                  sectionTitle="پروژه - دریافت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && ((item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "get") || (item.slug.split("_")[0] == "projects" && item.slug.split("_")[1] == "get"))'
                />
                <CapSection
                  sectionTitle="پروژه - یادداشت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "note")'
                />
                <CapSection
                  sectionTitle="پروژه - فایل"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "file")'
                />
                <CapSection
                  sectionTitle="پروژه - صورتحساب"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "invoices")'
                />
                <CapSection
                  sectionTitle="پروژه - ویرایش و فعالسازی"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && ((item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "activate") || (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "edit") || (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "stage"))'
                />
                <CapSection
                  sectionTitle="پروژه - فرم اطلاعات سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "siteform")'
                />
                <CapSection
                  sectionTitle="پروژه - فرم اطلاعات لوگو"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "logoform")'
                />
                <CapSection
                  sectionTitle="صورتحساب"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && item.slug.split("_")[0] == "invoice"'
                />
                <CapSection
                  sectionTitle="امور مشتریان"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "customer" || item.slug.split("_")[0] == "crm")'
                />

                <CapSection
                  sectionTitle="تراکنش"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "transaction" || item.slug.split("_")[0] == "transactions")'
                />
                <CapSection
                  sectionTitle="مرکز سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && item.slug.split("_")[0] == "asc"'
                />
                <CapSection
                  sectionTitle="وب سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && item.slug.split("_")[0] == "website"'
                />
                <CapSection
                  sectionTitle="مسئولیت ها - دپارتمان سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "role" && item.slug.split("_")[1] == "site")'
                />
                <CapSection
                  sectionTitle="پشتیبانی"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] == "support" || item.slug.split("_")[0] == "lms")'
                />
                <CapSection
                  sectionTitle="تردد"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && item.slug.split("_")[0] == "eam"'
                />
                <CapSection
                  sectionTitle="سایر"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={false}
                  condition='item.has_capability && (item.slug.split("_")[0] != "project" && item.slug.split("_")[0] != "projects" && item.slug.split("_")[0] != "invoice" && item.slug.split("_")[0] != "transaction" && item.slug.split("_")[0] != "transactions" && item.slug.split("_")[0] != "asc" && item.slug.split("_")[0] != "role" && item.slug.split("_")[0] != "website" && item.slug.split("_")[0] != "customer" && item.slug.split("_")[0] != "support" && item.slug.split("_")[0] != "lms" && item.slug.split("_")[0] != "eam" && item.slug.split("_")[0] != "crm")'
                />
              </View>
              <View style={{ flex: 1, paddingHorizontal: 25 }}>
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    marginBottom: 20,
                    textAlign: "center",
                  }}
                >
                  دسترسی های غیرفعال
                </Text>
                <CapSection
                  sectionTitle="پروژه - دریافت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && ((item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "get") || (item.slug.split("_")[0] == "projects" && item.slug.split("_")[1] == "get"))'
                />
                <CapSection
                  sectionTitle="پروژه - یادداشت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "note")'
                />
                <CapSection
                  sectionTitle="پروژه - فایل"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "file")'
                />
                <CapSection
                  sectionTitle="پروژه - صورتحساب"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "invoices")'
                />
                <CapSection
                  sectionTitle="پروژه - ویرایش و فعالسازی"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && ((item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "activate") || (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "edit") || (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "stage"))'
                />
                <CapSection
                  sectionTitle="پروژه - فرم اطلاعات سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "siteform")'
                />
                <CapSection
                  sectionTitle="پروژه - فرم اطلاعات لوگو"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "project" && item.slug.split("_")[1] == "logoform")'
                />
                <CapSection
                  sectionTitle="صورتحساب"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && item.slug.split("_")[0] == "invoice"'
                />
                <CapSection
                  sectionTitle="امور مشتریان"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "customer" || item.slug.split("_")[0] == "crm")'
                />

                <CapSection
                  sectionTitle="تراکنش"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "transaction" || item.slug.split("_")[0] == "transactions")'
                />
                <CapSection
                  sectionTitle="مرکز سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && item.slug.split("_")[0] == "asc"'
                />
                <CapSection
                  sectionTitle="وب سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && item.slug.split("_")[0] == "website"'
                />
                <CapSection
                  sectionTitle="مسئولیت ها - دپارتمان سایت"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "role" && item.slug.split("_")[1] == "site")'
                />
                <CapSection
                  sectionTitle="پشتیبانی"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] == "support" || item.slug.split("_")[0] == "lms")'
                />
                <CapSection
                  sectionTitle="تردد"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && item.slug.split("_")[0] == "eam"'
                />
                <CapSection
                  sectionTitle="سایر"
                  capabilities={formState.values.capabilities}
                  change_access_status={change_access_status}
                  access_to={true}
                  condition='!item.has_capability && (item.slug.split("_")[0] != "project" && item.slug.split("_")[0] != "projects" && item.slug.split("_")[0] != "invoice" && item.slug.split("_")[0] != "transaction" && item.slug.split("_")[0] != "transactions" && item.slug.split("_")[0] != "asc" && item.slug.split("_")[0] != "role" && item.slug.split("_")[0] != "website" && item.slug.split("_")[0] != "customer" && item.slug.split("_")[0] != "support" && item.slug.split("_")[0] != "lms" && item.slug.split("_")[0] != "eam" && item.slug.split("_")[0] != "crm")'
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CapabilityScreen
