import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import LeadsScreen, {
  screenOptions as LeadsScreenOptions,
} from "../screens/CRM/LeadsScreen"
import LeadScreen, {
  screenOptions as LeadScreenOptions,
} from "../screens/CRM/LeadScreen"
import AddLeadScreen, {
  screenOptions as AddLeadScreenOptions,
} from "../screens/CRM/AddLeadScreen"
import RemindersScreen, {
  screenOptions as RemindersScreenOptions,
} from "../screens/CRM/RemindersScreen"
import CustomersListScreen, {
  screenOptions as CustomersListScreenOptions,
} from "../screens/CRM/CustomersListScreen"
import CustomerScreen, {
  screenOptions as CustomerScreenOptions,
} from "../screens/CRM/CustomerScreen"
import CustomerAddScreen, {
  screenOptions as CustomerAddScreenOptions,
} from "../screens/CRM/CustomerAddScreen"

const CRMStackNavigator = createNativeStackNavigator()
const CRMNavigator = () => {
  return (
    <CRMStackNavigator.Navigator>
      <CRMStackNavigator.Screen
        name="Leads"
        component={LeadsScreen}
        options={{ ...LeadsScreenOptions, title: "لیدها" }}
      />

      <CRMStackNavigator.Screen
        name="AddLead"
        component={AddLeadScreen}
        options={{ ...AddLeadScreenOptions, title: "افزودن لید" }}
      />

      <CRMStackNavigator.Screen
        name="Lead"
        component={LeadScreen}
        options={{ ...LeadScreenOptions, title: "لید" }}
      />
      <CRMStackNavigator.Screen
        name="Reminders"
        component={RemindersScreen}
        options={{ ...RemindersScreenOptions, title: "یاداوری ها" }}
      />
      <CRMStackNavigator.Screen
        name="CustomersList"
        component={CustomersListScreen}
        options={{ ...CustomersListScreenOptions, title: "لیست مشتریان" }}
      />
      <CRMStackNavigator.Screen
        name="CustomerAdd"
        component={CustomerAddScreen}
        options={{ ...CustomerAddScreenOptions, title: "افزودن مشتری" }}
      />
      <CRMStackNavigator.Screen
        name="Customer"
        component={CustomerScreen}
        options={{ ...CustomerScreenOptions, title: "اطلاعات مشتری" }}
      />
    </CRMStackNavigator.Navigator>
  )
}

export default CRMNavigator
