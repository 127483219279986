import { useReducer, useCallback } from "react"
import { View, StyleSheet, Image, Dimensions } from "react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import Input from "../../components/Input"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
const windowWidth = Dimensions.get("window").width
import { is_key_valid } from "../../components/Functions"
import { Buffer } from "@craftzdog/react-native-buffer"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
const SetKeyScreen = (props) => {
  const DESKTOP = windowWidth > 800 ? true : false
  const [formState, dispatchFormState] = useReducer(formReducer, {
    loading: false,
    key: "",
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const flip = (data) =>
    Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]))

  const saveCred = async () => {
    try {
      const key_encrypt = JSON.parse(
        Buffer.from(formState.key, "base64").toString()
      )

      const key_decrypt = flip(key_encrypt)
      await AsyncStorage.setItem("key_encrypt", JSON.stringify(key_encrypt))
      await AsyncStorage.setItem("key_decrypt", JSON.stringify(key_decrypt))
      props.navigation.replace("StaffNavigator")
    } catch (e) {
      // saving error
    }
  }

  return (
    <View style={styles.wrapper}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Image
          style={{ width: 100, height: 100 }}
          source={require("../../assets/img/logoR-500.png")}
        />
      </View>
      <View
        style={{
          marginVertical: 80,
          flexDirection: "row",
          width: DESKTOP ? "40%" : "100%",
        }}
      >
        <View style={{ marginLeft: 10, flex: 1 }}>
          <Input
            autoFocus
            onSubmitEditing={() => {
              if (confirm(is_key_valid(formState.key)) == true) {
                saveCred()
              }
            }}
            multiline
            label="کلید رمزنگاری"
            id="key"
            onChange={inputChangeHandler}
            textAlign="center"
          />
        </View>
      </View>
      <Btn
        title="ثبت کلید"
        onPress={() => {
          if (confirm(is_key_valid(formState.key)) == true) {
            saveCred()
          }
        }}
        loading={formState.loading}
        wrapperStyle={{ width: DESKTOP ? "40%" : "100%" }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 15,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default SetKeyScreen
