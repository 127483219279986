export const isDevelopeMode =
  location.hostname.includes("localhost") || location.hostname.includes("violetonline")

export default {
  aioUrl: isDevelopeMode
    ? // ? "https://dev-api.aminh.pro/aio/v2"
      "https://dev.sogoods.ca/aio/v2"
    : "https://api.aminh.pro/aio/v2",
  appVersion: 182,
}
