import { useReducer, useCallback, useEffect } from "react"
import { ActivityIndicator, ScrollView, View, Text } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import Btn from "../../../components/Btn"
import Colors from "../../../constants/Colors"
import File from "../../../components/File"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_GET_LOADING = "SET_GET_LOADING"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_GET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          get_loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
          is_form_section_completed: action.is_form_section_completed,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      }

    default:
      return state
  }
}

const BasicInformationTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const role = useSelector((store) => store.user.user_data.role)
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const setActiveIndex = props.route.params.setActiveIndex
  const setSteps = props.route.params.setSteps

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      // ! NEED TO CHANGE
      first_name: "",
      last_name: "",
      city: "",
      what_offer: "",
      location: "",
      persian_name: "",
      english_name: "",
      experience: "",
      future_plan: "",
      competitors: "",
      target_audience: "",
      instagram: "",
      site: "",
      mobile: "",
      landline_number: "",
      // !

      loading: false,
      get_loading: false,
      form: null,
      is_staff_in_project: null,
      form_send_progress: 0,
      is_form_section_completed: false,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/logoform/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    console.clear()
    console.log(formState.values)

    // ! NEED TO CHANGE
    formdata.append("project_id", project_id)
    formdata.append("first_name", formState.values.first_name)
    formdata.append("last_name", formState.values.last_name)
    formdata.append("city", formState.values.city)
    formdata.append("what_offer", formState.values.what_offer)
    formdata.append("location", formState.values.location)
    formdata.append("persian_name", formState.values.persian_name)
    formdata.append("english_name", formState.values.english_name)
    formdata.append("experience", formState.values.experience)
    formdata.append("future_plan", formState.values.future_plan)
    formdata.append("competitors", formState.values.competitors)
    formdata.append("target_audience", formState.values.target_audience)
    formdata.append("instagram", formState.values.instagram)
    formdata.append("site", formState.values.site)
    formdata.append("mobile", formState.values.mobile)
    formdata.append("landline_number", formState.values.landline_number)
    // !

    dispatchFormState({ type: SET_LOADING, loading: true })

    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data.success
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const checkIsComplete = (response) => {
    let is_form_section_completed = false

    if (
      response.data.data.logoform_first_name &&
      response.data.data.logoform_last_name &&
      response.data.data.logoform_city &&
      response.data.data.logoform_what_offer &&
      response.data.data.logoform_location &&
      response.data.data.logoform_persian_name &&
      response.data.data.logoform_english_name &&
      response.data.data.logoform_experience &&
      response.data.data.logoform_future_plan &&
      response.data.data.logoform_competitors &&
      response.data.data.logoform_target_audience &&
      response.data.data.logoform_mobile &&
      response.data.data.logoform_landline_number
    ) {
      is_form_section_completed = true
    }

    return is_form_section_completed
  }

  const get_form = async () => {
    dispatchFormState({ type: SET_GET_LOADING, loading: true })

    const url =
      Config.aioUrl + "/project/forms/logoform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
          is_form_section_completed: checkIsComplete(response),
        })
      })
      .catch(function (error) {})
      .finally(() =>
        dispatchFormState({ type: SET_GET_LOADING, loading: false })
      )
  }

  useEffect(() => {
    get_form()
  }, [])

  const isNotAccessToEditInputs =
    (formState.values.is_staff_in_project &&
      capabilities.includes("project_logoform_update_own_project")) ||
    is_customer ||
    capabilities.includes("project_logoform_update_own_department")
      ? false
      : true

  if (formState.values.get_loading) {
    return (
      <View
        style={{
          width: "100%",
          height: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 16,
            color: Colors.text,
            marginTop: 20,
          }}
        >
          در حال بارگذاری اطلاعات...
        </Text>
      </View>
    )
  }

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: 15,
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {formState.values.is_form_section_completed && (
        <InputNote
          title="اطلاعات این صفحه تکمیل شده است."
          icon="information-circle-outline"
          backgroundColor={Colors.green}
          textColor="#fff"
        />
      )}

      <Input
        disabled={isNotAccessToEditInputs}
        label="نام"
        id="first_name"
        onChange={inputChangeHandler}
        required
        maxLength={15}
        minLength={2}
        insert={
          formState.values.form ? formState.values.form.logoform_first_name : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="نام خانوادگی"
        id="last_name"
        onChange={inputChangeHandler}
        required
        maxLength={15}
        minLength={2}
        insert={
          formState.values.form ? formState.values.form.logoform_last_name : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="شهر شما"
        id="city"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form ? formState.values.form.logoform_city : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="نام برند به فارسی"
        id="persian_name"
        onChange={inputChangeHandler}
        required
        minLength={2}
        insert={
          formState.values.form
            ? formState.values.form.logoform_persian_name
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="نام برند به انگلیسی"
        id="english_name"
        onChange={inputChangeHandler}
        required
        minLength={2}
        insert={
          formState.values.form
            ? formState.values.form.logoform_english_name
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="چه محصولات یا خدماتی ارائه می دهید؟"
        id="what_offer"
        onChange={inputChangeHandler}
        required
        multiline
        insert={
          formState.values.form ? formState.values.form.logoform_what_offer : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="محدوده جغرافیایی فعالیت برند شما"
        id="location"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form ? formState.values.form.logoform_location : ""
        }
        placeholder="برای مثال در سطح شهر، کشور و بین المللی"
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="چند سال است در این زمینه کار می کنید؟"
        id="experience"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form ? formState.values.form.logoform_experience : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="چشم اندازتان برای آینده برند چیست؟"
        id="future_plan"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_future_plan
            : ""
        }
        placeholder="فکر میکنید برند شما در 10 سال آینده در چه نقطه ای قرار میگیرد"
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="نام رقبای شما و وجه تمایز شما با آن ها"
        id="competitors"
        onChange={inputChangeHandler}
        required
        multiline
        insert={
          formState.values.form
            ? formState.values.form.logoform_competitors
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="اصلی ترین مخاطب هدف (مشتری) شما کیست؟"
        id="target_audience"
        onChange={inputChangeHandler}
        required
        insert={
          formState.values.form
            ? formState.values.form.logoform_target_audience
            : ""
        }
        placeholder="از نظر سن، جنسیت و..."
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="شماره تلفن ثابت"
        id="landline_number"
        required
        onChange={inputChangeHandler}
        insert={
          formState.values.form
            ? formState.values.form.logoform_landline_number
            : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="شماره موبایل"
        id="mobile"
        required
        onChange={inputChangeHandler}
        insert={
          formState.values.form ? formState.values.form.logoform_mobile : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="آدرس اینستاگرام شما"
        id="instagram"
        onChange={inputChangeHandler}
        insert={
          formState.values.form ? formState.values.form.logoform_instagram : ""
        }
      />

      <Input
        disabled={isNotAccessToEditInputs}
        label="آدرس سایت شما"
        id="site"
        onChange={inputChangeHandler}
        insert={
          formState.values.form ? formState.values.form.logoform_site : ""
        }
      />

      {!isNotAccessToEditInputs && (
        <Btn
          title={
            formState.values.form_send_progress > 0 &&
            formState.values.form_send_progress < 1
              ? "در حال ارسال اطلاعات...(" +
                (formState.values.form_send_progress * 100).toFixed(0) +
                "%)"
              : "ثبت اطلاعات فرم"
          }
          onPress={async () => {
            let result = await send_form()

            if (result) {
              await get_form()

              setSteps("اطلاعات برند")
            }
          }}
          wrapperStyle={{ marginVertical: 50 }}
        />
      )}
    </ScrollView>
  )
}

export default BasicInformationTab
