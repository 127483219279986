import { useReducer, useEffect } from "react"
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native"
import Colors from "../../../constants/Colors"
import Config from "../../../constants/Config"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Header from "../../../components/Header"
import moment from "moment-jalaali"
import { ScrollView } from "react-native-gesture-handler"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const SET_LOADING = "SET_LOADING"
const SET_POSTS = "SET_POSTS"
const SET_CATEGORIES = "SET_CATEGORIES"
const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY"
const SET_ACTIVE_PUBLISH_TYPE = "SET_ACTIVE_PUBLISH_TYPE"
const SET_ACTIVE_POST_TYPE = "SET_ACTIVE_POST_TYPE"
const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE"
const SET_ACTIVE_AUTHEOR = "SET_ACTIVE_AUTHEOR"
const SET_AUTHEORS = "SET_AUTHEORS"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_CATEGORIES:
      return {
        ...state,
        values: {
          ...state.values,
          categories: action.data,
        },
      }
    case SET_POSTS:
      let posts = action.reset ? [] : state.values.posts
      posts = posts.concat(action.data)
      return {
        ...state,
        values: {
          ...state.values,
          posts: posts,
        },
      }
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        values: {
          ...state.values,
          active_category: action.value,
        },
      }
    case SET_ACTIVE_PUBLISH_TYPE:
      return {
        ...state,
        values: {
          ...state.values,
          active_publish_type: action.value,
        },
      }
    case SET_ACTIVE_POST_TYPE:
      return {
        ...state,
        values: {
          ...state.values,
          active_post_type: action.value,
        },
      }
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        values: {
          ...state.values,
          active_page: action.value,
        },
      }
    case SET_ACTIVE_AUTHEOR:
      return {
        ...state,
        values: {
          ...state.values,
          active_author: action.value,
        },
      }
    case SET_AUTHEORS:
      return {
        ...state,
        values: {
          ...state.values,
          authors: [{ id: "all", title: "همه" }, ...action.value],
        },
      }

    default:
      return state
  }
}

const PostCard = (props) => {
  const { item, navigation } = props
  return (
    <TouchableOpacity
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        paddingVertical: 20,
        paddingLeft: 20,
        paddingRight: 0,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onPress={() => {
        navigation.navigate("AddPost", { post_id: item.id })
      }}
    >
      <View style={{ flex: 5 }}>
        <TouchableOpacity
          style={{
            width: 80,
            height: 25,
            backgroundColor: Colors.blue,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 3,
          }}
          onPress={() => {
            window.open("https://aminh.pro/" + item.slug, "_blank")
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-regular",
              fontSize: 10,
              color: "#fff",
            }}
          >
            مشاهده مطلب
          </Text>
        </TouchableOpacity>
        {item.status == "pending" && (
          <View
            style={{
              width: 80,
              height: 25,
              backgroundColor: Colors.error,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-regular",
                fontSize: 10,
                color: "#fff",
              }}
            >
              پیش نویس
            </Text>
          </View>
        )}
        {item.category_title && (
          <View
            style={{
              width: 80,
              height: 25,
              backgroundColor: Colors.primary,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
              marginTop: item.status == "pending" ? 3 : 0,
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-regular",
                fontSize: 10,
                color: "#fff",
              }}
            >
              {item.category_title}
            </Text>
          </View>
        )}

        <View
          style={{
            width: 80,
            height: 25,
            backgroundColor: Colors.green,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-regular",
              fontSize: 10,
              color: "#fff",
            }}
          >
            {item.author}
          </Text>
        </View>
        <View
          style={{
            width: 80,
            height: 25,
            backgroundColor: Colors.secondary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-regular",
              fontSize: 10,
              color: "#fff",
            }}
          >
            {moment.unix(item.created_at).format("jYYYY/jM/jD")}
          </Text>
        </View>
      </View>
      <View style={{ flex: 5, alignItems: "flex-end" }}>
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 12,
            color: Colors.text,
          }}
        >
          {item.title}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-regular",
            fontSize: 10,
            color: Colors.text,
            marginTop: 7,
          }}
        >
          {item.slug}
        </Text>
      </View>
      {DESKTOP && item.thumbnail_url && (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Image
            source={{ uri: item.thumbnail_url }}
            style={{ width: 80, height: 80, borderRadius: 7 }}
          />
        </View>
      )}
      <View
        style={{
          flex: DESKTOP ? 0.4 : 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            transform: [{ rotate: "-90deg" }],
            fontFamily: "IranSans-Medium",
            fontSize: 14,
            color: Colors.text,
          }}
        >
          # {item.id}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const LoadingPlaceHolder = () => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingBottom: 0,
            paddingTop: 15,
            paddingHorizontal: 20,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 5, marginRight: 30, opacity: 0 }}>
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 2 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>

      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>

        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 0,
            marginBottom: 20,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 2, marginRight: 30 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View style={{ flex: 5 }}>
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <PlaceholderLine style={{ transform: [{ rotate: "-90deg" }] }} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const PostsListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      posts: [],
      categories: [],
      authors: [{ id: "all", title: "همه" }],
      active_author: "all",
      active_category: null,
      active_publish_type: null,
      active_post_type: "normal",
      active_page: 1,
    },
  })

  const get_categories = async () => {
    const url = Config.aioUrl + "/website/blog/category/list"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_CATEGORIES, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_posts = async (options) => {
    let active_page = formState.values.active_page
    active_page = active_page.toString()
    if (active_page.includes("stop") && options.page != 1) return
    let url = Config.aioUrl + "/website/blog/posts/get?type=latest"
    if (options.number != undefined) url += "&number=" + options.number
    if (options.page != undefined) url += "&page=" + options.page
    if (options.category != undefined) url += "&category=" + options.category
    if (options.posts_type != undefined)
      url += "&posts_type=" + options.posts_type
    if (options.publish_type != undefined)
      url += "&publish_status=" + options.publish_type

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    console.log(url)
    if (options.page == 1)
      dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.data.length == 0)
          dispatchFormState({ type: SET_ACTIVE_PAGE, value: "stop" })
        dispatchFormState({
          type: SET_POSTS,
          data: response.data.data,
          reset: options.page == 1 ? true : false,
        })
        if (options.page == 1)
          dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        if (options.page == 1)
          dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_categories()
      get_posts({ page: 1, number: 20, posts_type: "normal" })
    })

    return focused
  }, [props.navigation])

  useEffect(() => {
    // ? Set selected author ====>

    dispatchFormState({
      type: SET_ACTIVE_AUTHEOR,
      value: "all",
    })

    let authorsList = []
    let authorsObjList = []

    formState.values.posts.map((item) => {
      if (!authorsList.includes(item.author)) {
        authorsList.push(item.author)
      }
    })

    authorsList.map((item) => authorsObjList.push({ id: item, title: item }))

    dispatchFormState({
      type: SET_AUTHEORS,
      value: authorsObjList,
    })
  }, [formState.values.posts])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="لیست مطالب بلاگ" noBack />

      {formState.values.loading && (
        <View>
          <LoadingPlaceHolder />
        </View>
      )}

      {!formState.values.loading && (
        <View style={{ flex: 1, height: "100%", width: "100%" }}>
          <View style={{ flex: 1 }}>
            <View
              style={{ height: 40, alignItems: "flex-end", marginBottom: 20 }}
            >
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{ flexDirection: "row-reverse", width: "100%" }}
              >
                <View
                  style={{
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    نوع:{" "}
                  </Text>
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_post_type == "normal"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 })
                      dispatchFormState({
                        type: SET_ACTIVE_CATEGORY,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_PUBLISH_TYPE,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_POST_TYPE,
                        value: "normal",
                      })
                      get_posts({ page: 1, number: 20, posts_type: "normal" })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.values.active_post_type == "normal"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      پست
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_post_type == "page"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 })
                      dispatchFormState({
                        type: SET_ACTIVE_CATEGORY,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_PUBLISH_TYPE,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_POST_TYPE,
                        value: "page",
                      })
                      get_posts({ page: 1, number: 20, posts_type: "page" })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.values.active_post_type == "page"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      برگه
                    </Text>
                  </TouchableOpacity>

                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                      marginRight: 20,
                    }}
                  >
                    وضعیت انتشار:{" "}
                  </Text>
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_publish_type == "published"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 })
                      dispatchFormState({
                        type: SET_ACTIVE_CATEGORY,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_PUBLISH_TYPE,
                        value: "published",
                      })
                      get_posts({
                        page: 1,
                        number: 20,
                        publish_type: "published",
                        category: formState.values.active_category,
                        posts_type: formState.values.active_post_type,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.values.active_publish_type == "published"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      منتشر شده
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.values.active_publish_type == "pending"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 })
                      dispatchFormState({
                        type: SET_ACTIVE_CATEGORY,
                        value: null,
                      })
                      dispatchFormState({
                        type: SET_ACTIVE_PUBLISH_TYPE,
                        value: "pending",
                      })
                      get_posts({
                        page: 1,
                        number: 20,
                        publish_type: "pending",
                        category: formState.values.active_category,
                        posts_type: formState.values.active_post_type,
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.values.active_publish_type == "pending"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      پیش نویس
                    </Text>
                  </TouchableOpacity>

                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                      marginRight: 20,
                    }}
                  >
                    دسته بندی:{" "}
                  </Text>
                  {formState.values.categories.map((item, index) => (
                    <TouchableOpacity
                      key={item.id}
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_category == item.id
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_CATEGORY,
                          value: item.id,
                        })
                        dispatchFormState({
                          type: SET_ACTIVE_POST_TYPE,
                          value: "normal",
                        })
                        get_posts({
                          page: 1,
                          number: 20,
                          category: item.id,
                          publish_type: formState.values.active_publish_type,
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_category == item.id
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  ))}

                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                      marginRight: 20,
                    }}
                  >
                    نویسنده:{" "}
                  </Text>
                  {formState.values.authors.map((item, index) => (
                    <TouchableOpacity
                      key={item.id}
                      style={{
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 10,
                        backgroundColor:
                          formState.values.active_author == item.id
                            ? Colors.blue
                            : "#F2F2F2",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 5,
                      }}
                      onPress={() => {
                        dispatchFormState({
                          type: SET_ACTIVE_AUTHEOR,
                          value: item.id,
                        })
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color:
                            formState.values.active_author == item.id
                              ? "#fff"
                              : Colors.text,
                        }}
                      >
                        {item.title}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>
              </ScrollView>
            </View>

            {formState.values.posts.length != 0 && (
              <FlatList
                contentContainerStyle={{ paddingHorizontal: 20 }}
                data={formState.values.posts}
                keyExtractor={(item) => item.id}
                style={{ marginBottom: 20 }}
                onEndReached={() => {
                  get_posts({
                    page: formState.values.active_page + 1,
                    number: 20,
                    category: formState.values.active_category,
                    posts_type: formState.values.active_post_type,
                    publish_type: formState.values.active_publish_type,
                  })
                  dispatchFormState({
                    type: SET_ACTIVE_PAGE,
                    value: formState.values.active_page + 1,
                  })
                }}
                onEndReachedThreshold={0.3}
                renderItem={(itemData) => {
                  let condition =
                    itemData.item.author == formState.values.active_author ||
                    formState.values.active_author == "all"

                  if (condition) {
                    return (
                      <PostCard
                        item={itemData.item}
                        navigation={props.navigation}
                      />
                    )
                  } else {
                    return <></>
                  }
                }}
              />
            )}
          </View>
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default PostsListScreen
