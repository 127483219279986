import { useReducer, useState } from "react";
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Image, Dimensions } from "react-native";
import Colors from "../../../constants/Colors";
import Config from "../../../constants/Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../../components/Functions";
import Header from "../../../components/Header";
import moment from "moment-jalaali";
import { ScrollView } from "react-native-gesture-handler";
import { Ionicons } from "@expo/vector-icons";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const SET_LOADING = "SET_LOADING";
const SET_PORTFOLIOS = "SET_PORTFOLIOS";
const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_PORTFOLIOS:
      let portfolios = action.reset ? [] : state.values.portfolios;
      portfolios = portfolios.concat(action.data);
      return {
        ...state,
        values: {
          ...state.values,
          portfolios: portfolios,
        },
      };
    case SET_ACTIVE_CATEGORY:
      return {
        ...state,
        values: {
          ...state.values,
          active_category: action.value,
        },
      };
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        values: {
          ...state.values,
          active_page: action.value,
        },
      };

    default:
      return state;
  }
};

const PostCard = (props) => {
  const { item, navigation, get_posts, active_category, token, capabilities } = props;
  const [loading_remove, set_loading_remove] = useState(false);
  const remove_post = async () => {
    const url = Config.aioUrl + "/website/portfolio/post/remove";
    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    let formdata = new FormData();
    formdata.append("portfolio_id", item.id);

    set_loading_remove(true);
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        set_loading_remove(false);
        if (response.data.success) {
          get_posts({ page: 1, number: 20, category: active_category });
        }
      })
      .catch(function (error) {
        console.log(error);
        set_loading_remove(false);
      });
  };

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        paddingVertical: 20,
        paddingLeft: 20,
        paddingRight: 0,
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onPress={() => {
        navigation.navigate("AddPortfolio", { portfolio_id: item.id });
      }}
    >
      <View style={{ flex: 4 }}>
        {item.status == "pending" && (
          <View
            style={{
              width: 80,
              height: 25,
              backgroundColor: Colors.error,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>پیش نویس</Text>
          </View>
        )}
        {capabilities.includes("website_remove_portfolio_post_all") && (
          <TouchableOpacity
            style={{
              width: 80,
              height: 25,
              backgroundColor: Colors.primary,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
              marginTop: item.status == "pending" ? 3 : 0,
            }}
            onPress={() => {
              if (confirm("آیا از حذف نمونه کار اطمینان دارید؟ در نظر داشته باشید تمام فایل های مرتبط با این نمونه کار به طور کامل پاک خواهند شد.")) {
                remove_post();
              }
            }}
          >
            {!loading_remove && <Ionicons name="trash-outline" color="#fff" size={16} />}
            {loading_remove && <ActivityIndicator size="small" color="#fff" />}
          </TouchableOpacity>
        )}

        <View
          style={{
            width: 80,
            height: 25,
            backgroundColor: Colors.secondary,
            borderRadius: 7,
            justifyContent: "center",
            alignItems: "center",
            marginTop: 3,
          }}
        >
          <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: "#fff" }}>{moment.unix(item.created_at).format("jYYYY/jM/jD")}</Text>
        </View>
      </View>
      <View style={{ flex: 4, alignItems: "flex-end", paddingRight: 10 }}>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>{item.title}</Text>
        <Text style={{ fontFamily: "IranSans-regular", fontSize: 10, color: Colors.text, marginTop: 7 }}>{item.slug}</Text>
      </View>
      {DESKTOP && item.thumbnail_url && (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <Image source={{ uri: item.thumbnail_url }} style={{ width: 120, height: 80, borderRadius: 7 }} />
        </View>
      )}
      <View style={{ flex: DESKTOP ? 0.4 : 1, justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
        <Text style={{ transform: [{ rotate: "-90deg" }], fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text }}># {item.id}</Text>
      </View>
    </TouchableOpacity>
  );
};

const PortfolioListEnScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const capabilities = useSelector((store) => store.user.capabilities);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      portfolios: [],
      active_category: null,
      active_page: 1,
    },
  });

  const get_posts = async (options) => {
    let active_page = formState.values.active_page;
    active_page = active_page.toString();
    if (active_page.includes("stop") && options.page != 1) return;
    let url = Config.aioUrl + "/website/portfolio/posts/get?type=latest&lang=en&category_slug=" + options.category;
    if (options.number != undefined) url += "&number=" + options.number;
    if (options.page != undefined) url += "&page=" + options.page;

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    if (options.page == 1) dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      //headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        if (response.data.data.length == 0) dispatchFormState({ type: SET_ACTIVE_PAGE, value: "stop" });
        dispatchFormState({
          type: SET_PORTFOLIOS,
          data: response.data.data,
          reset: options.page == 1 ? true : false,
        });
        if (options.page == 1) dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        if (options.page == 1) dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="لیست نمونه کارها" noBack />
      <View style={{ flex: 1, height: "100%", width: "100%" }}>
        <View style={{ flex: 1 }}>
          <View style={{ height: 40, alignItems: "flex-end" }}>
            <ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ flexDirection: "row-reverse", width: "100%" }}>
              <View style={{ flexDirection: "row-reverse", alignItems: "center", height: 40 }}>
                <Text style={{ fontFamily: "IranSans-Medium", fontSize: 12, color: Colors.text }}>دسته بندی: </Text>
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRadius: 10,
                    backgroundColor: formState.values.active_category == "logo" ? Colors.blue : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({ type: SET_ACTIVE_CATEGORY, value: "logo" });
                    dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 });
                    get_posts({ page: 1, number: 20, category: "logo" });
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: formState.values.active_category == "logo" ? "#fff" : Colors.text,
                    }}
                  >
                    لوگو
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    borderRadius: 10,
                    backgroundColor: formState.values.active_category == "site" ? Colors.blue : "#F2F2F2",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 5,
                  }}
                  onPress={() => {
                    dispatchFormState({ type: SET_ACTIVE_CATEGORY, value: "site" });
                    dispatchFormState({ type: SET_ACTIVE_PAGE, value: 1 });
                    get_posts({ page: 1, number: 20, category: "site" });
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: formState.values.active_category == "site" ? "#fff" : Colors.text,
                    }}
                  >
                    سایت
                  </Text>
                </TouchableOpacity>
              </View>
            </ScrollView>
          </View>
          {formState.values.loading && (
            <View>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          )}
          {!formState.values.loading && formState.values.portfolios.length != 0 && (
            <FlatList
              data={formState.values.portfolios}
              keyExtractor={(item) => item.id}
              onEndReached={() => {
                get_posts({ page: formState.values.active_page + 1, number: 20, category: formState.values.active_category });
                dispatchFormState({ type: SET_ACTIVE_PAGE, value: formState.values.active_page + 1 });
              }}
              onEndReachedThreshold={0.3}
              renderItem={(itemData) => (
                <PostCard
                  item={itemData.item}
                  navigation={props.navigation}
                  get_posts={get_posts}
                  active_category={formState.values.active_category}
                  token={token}
                  capabilities={capabilities}
                />
              )}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default PortfolioListEnScreen;
