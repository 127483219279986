import { useState, useEffect } from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";
import Colors from "../constants/Colors";
import { DatePicker } from "react-advance-jalaali-datepicker";

const Datepicker = (props) => {
  const { id, onChange } = props;
  const [state, setState] = useState({
    value: props.defaultValue ? props.defaultValue : "",
  });
  useEffect(() => {
    if (state.value) {
      onChange(id, state.value, true);
    } else {
      onChange(id, state.value, false);
    }
  }, [state]);
  const change = (unix, formatted) => {
    //console.log(unix); // returns timestamp of the selected value, for example.
    // console.log(formatted); // returns the selected value in the format you've entered, forexample, "تاریخ: 1396/02/24 ساعت: 18:30".
    setState((prev) => ({ ...prev, value: unix }));
  };

  const DatePickerInput = (props) => {
    return (
      <TextInput
        {...props}
        style={{
          width: "100%",
          outlineStyle: "none",
          paddingHorizontal: 10,
          direction: "rtl",
          fontFamily: "IranSans-Regular",
          fontSize: 16,
          color: Colors.text,
          textAlign: "center",
        }}
      />
    );
  };
  const labelWrapperStyle = () => {
    if (!props.column) {
      return {
        position: "absolute",
        top: -10,
        right: 20,
        backgroundColor: Colors.bg,
        paddingHorizontal: 7,
      };
    } else {
      return {
        position: "absolute",
        top: -10,
        //right: 20,
        backgroundColor: Colors.bg,
        paddingHorizontal: 7,
        width: "80%",
        textAlign: "center",
        marginRight: "10%",
        marginLeft: "10%",
      };
    }
  };

  return (
    <View
      style={{
        width: props.column == 2 ? "48%" : props.column == 3 ? "30.6666%" : "100%",
        height: 50,
        borderWidth: 0.8,
        borderRadius: 7,
        borderColor: state.focus ? Colors.primary : Colors.secondText,
        marginVertical: 15,
        zIndex: 1,
        ...props.wrapperStyle,
      }}
    >
      {props.label && (
        <View style={labelWrapperStyle()}>
          <Text
            style={{
              fontFamily: "IranSans-Light",
              fontSize: 13,
              color: state.focus ? Colors.primary : Colors.secondText,
            }}
          >
            {props.label}
          </Text>
        </View>
      )}

      <View style={Styles.textInputWrapper}>
        <DatePicker
          inputComponent={DatePickerInput}
          placeholder={props.blank ? props.blank : "انتخاب تاریخ"}
          format="jYYYY/jMM/jDD"
          onChange={change}
          id="datePicker"
          preSelected={props.preSelected ? props.preSelected : ""}
          cancelOnBackgroundClick={true}
        />
      </View>
    </View>
  );
};

const Styles = StyleSheet.create({
  textInputWrapper: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
});

export default Datepicker;
