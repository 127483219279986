import { useReducer, useCallback, useEffect, useRef } from "react"
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Platform,
  useWindowDimensions,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { Video, ResizeMode } from "expo-av"
import InputNote from "../../components/InputNote"
import RenderHTML, { defaultSystemFonts } from "react-native-render-html"
import RenderHTMLSource from "../../components/RenderHTMLSource"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_SECTION = "SET_SECTION"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SECTION:
      return {
        ...state,
        values: {
          ...state.values,
          section: action.data,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 5 }}>
            <PlaceholderLine height={35} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const LessonScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const role = useSelector((store) => store.user.user_data.role)
  const section_id = props.route.params.section_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      section: null,
    },
  })

  const source_compiler = (source) => {
    const new_source = source
      // Red
      .replaceAll(
        "[START_SHORT_CODE_RED]",
        "<div class='red' id='alert'><span class='icon'>✖</span><span class='content'>"
      )
      .replaceAll("[END_SHORT_CODE_RED]", "</span></div>")
      // Yellow
      .replaceAll(
        "[START_SHORT_CODE_YELLOW]",
        "<div class='yellow' id='alert'><span class='icon'>⚠</span><span class='content'>"
      )
      .replaceAll("[END_SHORT_CODE_YELLOW]", "</span></div>")
      // Green
      .replaceAll(
        "[START_SHORT_CODE_GREEN]",
        "<div class='green' id='alert'><span class='icon'>✔</span><span class='content'>"
      )
      .replaceAll("[END_SHORT_CODE_GREEN]", "</span></div>")
      // Blue
      .replaceAll(
        "[START_SHORT_CODE_BLUE]",
        "<div class='blue' id='alert'><span class='icon'>i</span><span class='content'>"
      )
      .replaceAll("[END_SHORT_CODE_BLUE]", "</span></div>")

    return new_source
  }

  const source = `
      <div id="PostContent" style="font-family: 'IranSans-Light' !important; direction: rtl;">
      <style>
      
      #PostContent * {
        font-family: "IranSans-Light" !important;
        line-height: 1.7;
      }
      #PostContent img,
      #PostContent li,
      #alert {
        margin-bottom: 15px;
      }
      #PostContent h2,
      #PostContent h3 {
        margin-bottom: 10px;
        margin-top: 60px;
      }
      #PostContent h2,
      #PostContent strong {
        font-family: "IranSans-Light";
      }
      #PostContent p {
        margin-bottom: 20px;
        word-spacing: 3px;
      }
      #PostContent h2 {
        font-size: 25px;
      }
      #PostContent h3 {
        font-size: 22px;
      }
      #PostContent ol,
      #PostContent ul {
        padding-right: 50px;
      }
      #PostContent img {
        border-radius: 0.75rem;
      }
      #PostContent a {
        color: #dd1c1d;
        font-weight: 700;
      }
      
      /* Alert Design ===>> */
      
      #alert {
        font-weight: 700;
        padding: 0 20px;
        color: #333;
        border-radius: 7px;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 15px;
        margin-top: 15px;
        background-color: #f1f1f1;
      }
      #alert * {
        background-color: transparent !important;
      }
      #alert p {
        margin: 0 !important;
      }
      #alert .content {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
      }
      #alert br {
        display: none !important;
      }
      #alert strong {
        font-family: "IranSans-Medium" !important;
        margin: 0 !important;
        font-size: 20px !important;
      }
      #alert .icon {
        font-size: 30px;
        margin-top: 5px;
      }
      #alert.red {
        border-right: 10px solid #eb5757;
      }
      #alert.yellow {
        border-right: 10px solid #f2c94d;
      }
      #alert.green {
        border-right: 10px solid #00cc99;
      }
      #alert.blue {
        border-right: 10px solid #5357f6;
      }
      #alert.blue .content {
        user-select: all;
      }
      #alert.red .icon {
        color: #eb5757;
      }
      #alert.yellow .icon {
        color: #f2c94d;
      }
      #alert.green .icon {
        color: #00cc99;
      }
      #alert.blue .icon {
        color: #5357f6;
      }
      
      @media only screen and (max-width: 600px) {
        #alert {
          padding: 10px 17px;
        }
        #alert .icon {
          display: none;
        }
        #alert strong {
          font-size: 16px !important;
          line-height: 1;
        }
        #alert .content {
          gap: 5px;
        }
      }            

      </style>
        ${formState.values.section?.text_content || ""}
      </div>
    `

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_section = async () => {
    let url = null

    if (role === "customer") {
      url = Config.aioUrl + "/customer/lms/section/get?id=" + section_id
    } else {
      url = Config.aioUrl + "/employee/lms/section/get?id=" + section_id

      // url = "https://dev-api.aminh.pro/aminh/get-sections"
    }

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_SECTION, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }
  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_section()
    })

    return focused
  }, [props.navigation])
  console.log(section_id)
  const video = useRef(null)

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: Colors.bg,
        paddingHorizontal: 25,
      }}
    >
      <Header
        title={formState.values.section?.title || ""}
        noNotif
        noActiveSessions
        noToggle
      />

      <View
        style={{
          paddingHorizontal: DESKTOP ? "15%" : 0,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <View style={{ flex: 1, maxHeight: "80vh" }}>
          {formState.values.loading && <LoadingPlaceHolder />}

          {formState.values.section && !formState.values.loading && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ paddingBottom: 50 }}
            >
              {formState.values.section.content_category.toUpperCase() ===
                "VIDEO" && (
                <View>
                  <View style={{ width: "100%" }}>
                    {Platform.OS === "web" ? (
                      <video
                        width="auto"
                        height="auto"
                        style={{
                          width: "100%",
                          borderRadius: 15,
                        }}
                        controls
                      >
                        <source
                          src={formState.values.section.video_url}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <InputNote content="برای نمایش ویدیو صفحه را در وب باز کنید." />
                    )}
                  </View>
                </View>
              )}

              {formState.values.section.content_category.toUpperCase() ===
                "TEXT" && (
                <>
                  {Platform.OS === "web" ? (
                    <div
                      style={{ fontFamily: "IranSans-Medium" }}
                      dangerouslySetInnerHTML={{
                        __html: source_compiler(source),
                      }}
                    ></div>
                  ) : (
                    <RenderHTMLSource source={source_compiler(source)} />
                  )}
                </>
              )}
            </ScrollView>
          )}
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default LessonScreen
