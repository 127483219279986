import React, { useRef } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import Colors from "../constants/Colors"

const Step = ({
  stepCount = 1,
  title = "بدون عنوان",
  color = "#e1e1e1",
  index,
  diff,
  diffDep,
}) => {
  const isTopLabel = (index + 1) % 2 == 0

  const labelStyles = isTopLabel
    ? {
        backgroundColor: color,
        borderRadius: 100,
        justifyContent: "center",
        alignItems: "center",
        width: 135,
        padding: 8,
        position: "absolute",
        bottom: 45,
        zIndex: 1,
      }
    : {
        backgroundColor: color,
        borderRadius: 100,
        justifyContent: "center",
        alignItems: "center",
        width: 135,
        padding: 8,
        position: "absolute",
        top: 45,
        zIndex: 1,
      }

  const infoStyles = isTopLabel
    ? {
        justifyContent: "center",
        alignItems: "center",
        width: 135,
        gap: 5,
        padding: 8,
        position: "absolute",
        bottom: 90,
      }
    : {
        justifyContent: "center",
        alignItems: "center",
        width: 135,
        gap: 5,
        padding: 8,
        position: "absolute",
        top: 90,
      }

  return (
    <View
      style={{
        position: "absolute",
        bottom: -10,
        right: `${stepCount}%`,
        display: !(stepCount == 0 || stepCount == 100) ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TouchableOpacity style={labelStyles}>
        <Text
          style={{ color: "#fff", fontFamily: "IranSans-Light", fontSize: 14 }}
        >
          {title}
        </Text>
      </TouchableOpacity>

      <View
        style={{
          backgroundColor: "#fff",
          borderWidth: 5,
          borderColor: Colors.cardBg,
          borderStyle: "solid",
          borderRadius: 15150,
          height: 30,
          width: 30,
        }}
      ></View>

      <View style={infoStyles}>
        <Text
          style={{
            color: Colors.text,
            fontFamily: "IranSans-Light",
            fontSize: 17,
          }}
        >
          {Math.floor(diff)} روز
        </Text>

        <Text
          style={{
            color: Colors.text,
            fontFamily: "IranSans-Light",
            fontSize: 12,
          }}
        >
          {Math.floor(diffDep)} روز
        </Text>
      </View>
    </View>
  )
}

export default function SpeedStep({ stages, total }) {
  const stagesTitle = Object.keys(stages)

  return (
    <View
      style={{
        backgroundColor: Colors.cardBg,
        borderRadius: 15,
        width: "83%",
        marginTop: 130,
        marginBottom: 150,
      }}
    >
      <View
        style={{
          backgroundColor: Colors.cardBg,
          borderRadius: 15150,
          height: 10,
          width: "100%",
          position: "relative",
        }}
      >
        <View
          style={{
            backgroundColor: "#fff",
            borderWidth: 5,
            borderColor: Colors.cardBg,
            borderStyle: "solid",
            borderRadius: 15150,
            height: 30,
            width: 30,
            position: "absolute",
            bottom: -10,
            left: 0,
          }}
        ></View>

        {stagesTitle.map((target, index) => {
          const itemData = { ...stages[target] }
          itemData.name = target

          // Give Sum of prevs spces on percent
          const prevStagesDaysPercent = (targetId = 0) => {
            let prevTotal = 0

            stagesTitle.forEach((target, index) => {
              const itemData = { ...stages[target] }
              const itemDays = itemData.diff
              const totalDays = total?.totalDiffs

              const space = (itemDays / totalDays) * 100

              if (itemData.id < targetId) {
                prevTotal += space
              }
            })

            return prevTotal
          }

          // Count of this stage days
          const itemDays = itemData.diff
          // Total days start to ens
          const totalDays = total?.totalDiffs

          // This stage space from prev stage percent
          const space = (itemDays / totalDays) * 100

          // Start to this stage percent + this stage percent space
          const startToNowSpace = space + prevStagesDaysPercent(itemData.id)

          console.log(total)

          return (
            <Step
              index={index}
              stepCount={startToNowSpace}
              title={itemData.name}
              color={itemData.color}
              diff={itemData.diff}
              diffDep={itemData.diffDep}
            />
          )
        })}

        <View
          style={{
            backgroundColor: "#fff",
            borderWidth: 5,
            borderColor: Colors.cardBg,
            borderStyle: "solid",
            borderRadius: 15150,
            height: 30,
            width: 30,
            position: "absolute",
            bottom: -10,
            right: 0,
          }}
        ></View>
      </View>
    </View>
  )
}
