import { useEffect, useReducer, useState } from "react"
import { View, Text, TouchableOpacity, FlatList, Image } from "react-native"
import Colors from "../constants/Colors"
import axios from "axios"
import Config from "../constants/Config"
import { useSelector } from "react-redux"
import { Ionicons } from "@expo/vector-icons"
import moment from "moment-jalaali"
import { useNavigation } from "@react-navigation/native"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"

const SET_LOADING = "SET_LOADING"
const SET_NOTIFS = "SET_NOTIFS"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_NOTIFS:
      return {
        ...state,
        values: {
          ...state.values,
          notifs: action.data,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = () => {
  return (
    <View style={{ padding: 15, direction: "rtl" }}>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
        <View
          style={{
            backgroundColor: "#EFEFEF",
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 15,
            height: 60,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              width: 45,
              height: 45,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
          <View
            style={{
              width: "60%",
              height: 20,
              borderRadius: 500,
              backgroundColor: "#F9F9F9",
            }}
          ></View>
        </View>
      </Placeholder>
    </View>
  )
}

const Notifications = (props) => {
  const navigation = useNavigation()

  const token = useSelector((store) => store.user.token)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      notifs: [],
    },
  })

  const get_notifs = async () => {
    let url = Config.aioUrl + "/notifications/get"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        console.log(response)
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_NOTIFS, data: response.data.data })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    get_notifs()
  }, [])

  return (
    <View
      style={{
        position: "absolute",
        top: 50,
        left: 0,
        width: 300,
        height: 300,
        backgroundColor: "#fff",
        borderRadius: 15,
        shadowColor: "rgba(0,0,0,0.3)",
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 3,
      }}
    >
      {!formState.values.loading && formState.values.notifs.length != 0 && (
        <FlatList
          data={formState.values.notifs}
          keyExtractor={(item) => item.id}
          renderItem={(itemData, index) => (
            <TouchableOpacity
              style={{
                width: "100%",
                flexDirection: "row-reverse",
                paddingHorizontal: 20,
                paddingVertical: 12,
                borderTopColor: "#F2F2F2",
                borderTopWidth: index == 0 ? 0 : 1,
              }}
              onPress={() => {
                if (itemData.item.data.project_id !== undefined) {
                  navigation.navigate("Project", {
                    id: itemData.item.data.project_id,
                  })
                }
              }}
            >
              <View
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 35,
                  height: 35,
                  maxWidth: 35,
                  borderRadius: 17.5,
                  backgroundColor:
                    itemData.item.title == "تایید تراکنش"
                      ? "#4CAF50"
                      : itemData.item.title == "پاسخ جدید در تیکت"
                      ? "#EC407A"
                      : itemData.item.title == "فرم تماس"
                      ? "#F44336"
                      : itemData.item.title == "فعالسازی پروژه"
                      ? "#673AB7"
                      : "#2196F3",
                  flex: 1,
                }}
              >
                <Ionicons
                  name={
                    itemData.item.title == "تایید تراکنش"
                      ? "ios-cash-outline"
                      : itemData.item.title == "پاسخ جدید در تیکت"
                      ? "chatbox-ellipses-outline"
                      : itemData.item.title == "فرم تماس"
                      ? "call-outline"
                      : itemData.item.title == "فعالسازی پروژه"
                      ? "ios-shuffle-outline"
                      : "at-outline"
                  }
                  size={24}
                  color="#fff"
                />
              </View>
              <View
                style={{ height: "100%", justifyContent: "center", flex: 6 }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Medium",
                      fontSize: 12,
                      color: Colors.text,
                      marginRight: 5,
                    }}
                  >
                    {itemData.item.title}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 10,
                      color: "#BDBDBD",
                    }}
                  >
                    {moment.unix(itemData.item.time).fromNow()}
                  </Text>
                </View>
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 10,
                    color: Colors.text,
                    marginRight: 5,
                    marginTop: 3,
                  }}
                >
                  {itemData.item.content}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        />
      )}

      {!formState.values.loading && formState.values.notifs.length == 0 && (
        <View
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: Colors.text,
              fontFamily: "Iransans-Medium",
              fontSize: 16,
            }}
          >
            موردی یافت نشد!
          </Text>
        </View>
      )}

      {formState.values.loading && <LoadingPlaceHolder />}
    </View>
  )
}

export default Notifications
