import React, { useReducer, useCallback, useEffect } from "react"
import { View, Image, FlatList, Text, TouchableOpacity } from "react-native"
import Input from "../../../components/Input"
import Btn from "../../../components/Btn"
import { useSelector } from "react-redux"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Config from "../../../constants/Config"
import moment from "moment-jalaali"
import { Ionicons } from "@expo/vector-icons"
import Colors from "../../../constants/Colors"
import Picker from "../../../components/Picker"

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_NOTES = "SET_NOTES"
const TOGGLE_ADD = "TOGGLE_ADD"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case ALERT_SHOW:
      return {
        ...state,
        showAlert: action.show,
        alertmsg: action.msg ? action.msg : "",
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_NOTES:
      return {
        ...state,
        notes: action.data,
        can_be_selected_staffs: action.can_be_selected_staffs,
      }
    case TOGGLE_ADD:
      return {
        ...state,
        is_add_show: state.is_add_show ? false : true,
      }
    default:
      return state
  }
}

const Note = (props) => {
  const { item } = props.item
  const { remove_note } = props

  const isSystem = !item.sender_id

  if (item.type != "stage") {
    return (
      <View
        style={{
          width: "100%",
          backgroundColor: isSystem ? Colors.secondary : "#F2F2F2",
          paddingVertical: 20,
          paddingHorizontal: 15,
          marginBottom: 10,
          borderRadius: 7,
        }}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 1.25,
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Image
              source={
                isSystem
                  ? require("../../../assets/img/logoR-500.png")
                  : { uri: item.sender_avatar }
              }
              style={{ width: 32, height: 32, borderRadius: 16 }}
            />
            <Text
              style={{
                color: isSystem ? "#fff" : Colors.text,
                marginRight: 5,
                fontFamily: "IranSans-Light",
                fontSize: 14,
              }}
            >
              {isSystem ? "سیستم" : item.sender_name}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text
              style={{
                color: isSystem ? "#fff" : Colors.text,
                fontFamily: "IranSans-Light",
                fontSize: 14,
              }}
            >
              {moment.unix(item.time).format("jYYYY/jM/jD hh:mm")}
            </Text>
            {parseInt(item.time) + 90 > moment().unix() && (
              <TouchableOpacity
                style={{ marginLeft: 20 }}
                onPress={() => {
                  remove_note(item.id)
                }}
              >
                <Ionicons
                  name={"trash-outline"}
                  size={20}
                  color={Colors.primary}
                />
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View>
          <Text
            style={{
              color: isSystem ? "#fff" : Colors.text,
              fontFamily: "IranSans-Light",
              fontSize: 12,
              marginTop: 15,
              lineHeight: 22,
            }}
          >
            {item.content}
          </Text>
        </View>
        {item.note_ref && (
          <View style={{ flexDirection: "row-reverse", marginTop: 3 }}>
            {item.note_ref.map((ref_item) => (
              <View
                key={ref_item.id}
                style={{
                  backgroundColor: isSystem ? "#fff" : Colors.secondary,
                  padding: 5,
                  borderRadius: 7,
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 3,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 10,
                    color: isSystem ? Colors.text : "#fff",
                  }}
                >
                  {ref_item.name}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
    )
  } else {
    let final_content = item.content.substring(
      item.content.indexOf("«"),
      item.content.lastIndexOf("»") + 1
    )

    return (
      <View
        style={{
          width: "100%",
          backgroundColor: isSystem ? Colors.secondary : Colors.blue,
          paddingVertical: 10,
          paddingHorizontal: 15,
          marginBottom: 10,
          borderRadius: 7,
        }}
      >
        <View style={{ flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 1.25,
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Image
              source={
                isSystem
                  ? require("../../../assets/img/logoR-500.png")
                  : { uri: item.sender_avatar }
              }
              style={{ width: 24, height: 24, borderRadius: 12 }}
            />
            <Text
              style={{
                color: "#fff",
                marginRight: 5,
                fontFamily: "IranSans-Light",
                fontSize: 12,
              }}
            >
              {isSystem ? "سیستم" : item.sender_name}
            </Text>
          </View>
          <View
            style={{
              flex: 1.7,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontFamily: "IranSans-Light",
                fontSize: 12,
              }}
            >
              {final_content}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontFamily: "IranSans-Light",
                fontSize: 12,
              }}
            >
              {moment.unix(item.time).format("jYYYY/jM/jD hh:mm")}
            </Text>
          </View>
        </View>
      </View>
    )
  }
}

const NotesTab = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    note_content: "",
    showAlert: false,
    alertmsg: "",
    loading: false,
    notes: [],
    note_ref: [],
    can_be_selected_staffs: [],
    is_add_show: false,
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_notes = async () => {
    const url = Config.aioUrl + "/project/note/get?id=" + props.route.params.id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_NOTES,
          data: response.data.data,
          can_be_selected_staffs: response.data.can_be_selected_staffs,
        })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const add_note = async () => {
    const url = Config.aioUrl + "/project/note/add"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let reqData = JSON.stringify({
      id: props.route.params.id,
      content: formState.note_content,
      note_ref: formState.note_ref,
    })
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
        dispatchFormState({ type: TOGGLE_ADD })
        get_notes()
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const remove_note = async (id) => {
    const url = Config.aioUrl + "/project/note/delete?id=" + id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
        get_notes()
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_notes()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingTop: formState.is_add_show ? 0 : 10,
        paddingHorizontal: 10,
      }}
    >
      {formState.is_add_show && (
        <View>
          <Input
            label="یادداشت"
            id="note_content"
            value={formState.note_content}
            onChange={inputChangeHandler}
            wrapperStyle={{ marginTop: 25 }}
            multiline
            maxLength={800}
            validity
          />
          {!formState.loading && (
            <Picker
              label="ارجاع یادداشت"
              id="note_ref"
              onChange={inputChangeHandler}
              options={formState.can_be_selected_staffs}
              isMulti
            />
          )}
        </View>
      )}

      <View style={{ flexDirection: "row" }}>
        {formState.is_add_show && (
          <View style={{ flex: 1, marginRight: 10 }}>
            <Btn
              title="بستن"
              onPress={() => {
                dispatchFormState({ type: TOGGLE_ADD })
              }}
              loading={formState.loading}
              color={Colors.error}
            />
          </View>
        )}
        <View style={{ flex: 3 }}>
          <Btn
            title={formState.is_add_show ? "ثبت یادداشت" : "افزودن"}
            onPress={() => {
              if (formState.is_add_show) {
                add_note()
              } else {
                dispatchFormState({ type: TOGGLE_ADD })
              }
            }}
            loading={formState.loading}
          />
        </View>
      </View>

      <FlatList
        showsVerticalScrollIndicator={false}
        style={{ width: "100%", marginTop: 20 }}
        data={formState.notes}
        keyExtractor={(item) => item.id}
        renderItem={(item) => <Note item={item} remove_note={remove_note} />}
      />
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}

export default NotesTab
