export default {
  primary: "#DD1C1D",
  secondary: "#333333",
  text: "#222222",
  secondText: "#979494",
  blue: "#2F80ED",
  green: "#27AE60",
  purple: "#9C27B0",
  bg: "#ffffff",
  cardBg: "#F2F2F2",
  error: "#FF7043",
}

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52)

export const randomRGB = () =>
  `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`
