import { useEffect, useState } from "react"
import { View, Text, Image, ScrollView } from "react-native"
import Colors from "../../constants/Colors"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { setCred } from "../../store/user"
import { useDispatch } from "react-redux"
import axios from "axios"
import Config from "../../constants/Config"
import InputNote from "../../components/InputNote"
import * as Device from "expo-device"
import * as Linking from "expo-linking"
import { logOut } from "../../components/Functions"

const SplashScreen = (props) => {
  const [showaths, setShowaths] = useState(false)
  const dispatch = useDispatch()

  const get_latest_version = async () => {
    const url = Config.aioUrl + "/version"
    axios({
      method: "get",
      url: url,
    })
      .then(function (response) {
        if (response.data.version > Config.appVersion)
          window.location.reload(true)
      })
      .catch(function (error) {})
  }

  const updateCapabilities = async (token, capabilities) => {
    if (!token) return false

    const url = Config.aioUrl + "/capabilities/check"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    }).then(async (res) => {
      if (res.data.code == 401 || res.data.code == 403) {
        window.location.reload(true)
        logOut()
      }

      if (!res.data.success) return false

      if (res.data.capabilities.join() !== capabilities) {
        let newCapabilities = res?.data?.capabilities
        let newUserData = res?.data?.user_data

        if (newCapabilities) {
          await AsyncStorage.setItem("capabilities", newCapabilities)
        }

        if (newUserData) {
          await AsyncStorage.setItem("user_data", JSON.stringify(newUserData))
        }

        if (newCapabilities || newUserData) {
          window.location.reload()
        }
      }
    })
  }

  const do_in_order = async () => {
    await get_latest_version()

    const getData = async () => {
      try {
        const token = await AsyncStorage.getItem("token")

        const capabilities = await AsyncStorage.getItem("capabilities")

        let user_data = await AsyncStorage.getItem("user_data")
        user_data = JSON.parse(user_data)

        const key_encrypt = await AsyncStorage.getItem("key_encrypt")
        const key_decrypt = await AsyncStorage.getItem("key_decrypt")

        if (token != null) {
          dispatch(
            setCred({
              token: token,
              capabilities: capabilities,
              user_data: user_data,
              key_encrypt: key_encrypt ? JSON.parse(key_encrypt) : "",
              key_decrypt: key_decrypt ? JSON.parse(key_decrypt) : "",
            })
          )

          if (user_data.role == "staff") {
            updateCapabilities(token, capabilities)

            if (
              (user_data.department == "site" ||
                user_data.department == "lead") &&
              key_encrypt == null
            ) {
              props.navigation.navigate("Auth", { screen: "SetKey" })
            } else {
              props.navigation.navigate("StaffNavigator")
            }
          }

          if (user_data.role == "customer") {
            props.navigation.navigate("CustomerNavigator")
          }
        } else {
          props.navigation.navigate("LoginOne")
        }
      } catch (e) {
        // saving error
      }
    }
    getData()
  }

  useEffect(() => {
    if (
      Device.osName == "iOS" ||
      Device.osName == "ios" ||
      Device.osName == "IOS"
    ) {
      if (window.navigator.standalone) {
        do_in_order()
      } else {
        if (navigator.userAgent.toLowerCase().indexOf("safari") != -1) {
          if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
            // Chrome
            setShowaths(true)
          } else {
            // Safari
            setShowaths(true)
          }
        }
      }
    } else {
      do_in_order()
    }
  }, [])
  if (showaths) {
    return (
      <View
        style={{
          paddingHorizontal: 30,
          height: "100%",
          overflow: "hidden",
          backgroundColor: Colors.bg,
          paddingTop: 80,
        }}
      >
        <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
          <InputNote
            content={
              "کاربر عزیز آیفون " +
              "سیستم عامل گوشی شما ios " +
              Device.osVersion +
              " می باشد. برای استفاده از اپلیکیشن باید مراحل زیر را در مرورگر safari طی کنید."
            }
            backgroundColor={Colors.error}
            textColor="#fff"
          />
          <InputNote
            title="۱- روی دکمه زیر در مرورگر safari کلیک کنید"
            backgroundColor={Colors.blue}
            textColor="#fff"
            wrapperStyle={{
              marginBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <View style={{ width: "100%", height: 130, marginBottom: 10 }}>
            <Image
              style={{
                width: "100%",
                height: 130,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderWidth: 1,
                borderColor: Colors.blue,
              }}
              source={require("../../assets/img/aths1.jpg")}
            />
          </View>
          <InputNote
            title="۲- گزینه Add to Home Screen را بزنید"
            content=""
            backgroundColor={Colors.blue}
            textColor="#fff"
            wrapperStyle={{
              marginBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <View style={{ width: "100%", height: 130, marginBottom: 10 }}>
            <Image
              style={{
                width: "100%",
                height: 130,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderWidth: 1,
                borderColor: Colors.blue,
              }}
              source={require("../../assets/img/aths2.jpg")}
            />
          </View>
          <InputNote
            title="۳- گزینه Add را بزنید"
            content=""
            backgroundColor={Colors.blue}
            textColor="#fff"
            wrapperStyle={{
              marginBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <View style={{ width: "100%", height: 130, marginBottom: 10 }}>
            <Image
              style={{
                width: "100%",
                height: 130,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderWidth: 1,
                borderColor: Colors.blue,
              }}
              source={require("../../assets/img/aths3.jpg")}
            />
          </View>
          {/* <InputNote
            title="۴- وارد اپ از صفحه گوشی خود شوید"
            content=""
            backgroundColor={Colors.blue}
            textColor="#fff"
            wrapperStyle={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          />
          <View style={{ width: "100%", height: 130, marginBottom: 10 }}>
            <Image
              style={{
                width: "100%",
                height: 130,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                borderWidth: 1,
                borderColor: Colors.blue,
              }}
              source={require("../../assets/img/aths4.jpg")}
            />
          </View> */}
        </ScrollView>
      </View>
    )
  } else {
    return (
      <View
        style={{
          paddingHorizontal: 30,
          height: "100%",
          overflow: "hidden",
          backgroundColor: Colors.bg,
        }}
      >
        <View
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            flex: 1,
            marginBottom: 30,
          }}
        >
          <Image
            style={{ width: 150, height: 150 }}
            source={require("../../assets/img/logoR-500.png")}
          />
        </View>
        <View
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            flex: 1,
          }}
        >
          <Text style={{ fontSize: 26, fontWeight: "bold" }}>
            AminH Company
          </Text>
          <Text style={{ fontSize: 18, fontWeight: "200", marginTop: 10 }}>
            We Love What We Do
          </Text>
        </View>
      </View>
    )
  }
}

export const screenOptions = {
  headerShown: false,
}

export default SplashScreen
