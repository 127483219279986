import { View, StyleSheet, Dimensions } from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
const Tab = createMaterialTopTabNavigator()
import InfoTab from "./CustomerTabs/InfoTab"
// import ProjectsTab from "./CustomerTabs/ProjectsTab"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const CustomerScreen = (props) => {
  let tabs = [
    // {
    //   name: "پروژه ها",
    //   component: ProjectsTab,
    // },
    {
      name: "مشخصات",
      component: InfoTab,
    },
  ]

  return (
    <View style={styles.wrapper}>
      <Header title={"اطلاعات مشتری " + "#" + props.route.params.id} noToggle />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 0,
          flex: 1,
          height: "100%",
        }}
      >
        <Tab.Navigator
          initialRouteName="مشخصات"
          screenOptions={{
            tabBarActiveTintColor: Colors.primary,
            tabBarInactiveTintColor: Colors.secondText,
            tabBarIndicatorStyle: { backgroundColor: Colors.primary },
            tabBarLabelStyle: { fontFamily: "IranSans-Medium" },
          }}
          sceneContainerStyle={{ backgroundColor: "#fff", flex: 1 }}
        >
          {tabs.map((tab) => (
            <Tab.Screen
              key={tab.name}
              name={tab.name}
              component={tab.component}
              initialParams={{ id: props.route.params.id }}
            />
          ))}
        </Tab.Navigator>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default CustomerScreen
