import { useReducer, useEffect, useCallback } from "react";
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Dimensions, Modal, TouchableWithoutFeedback } from "react-native";
import Colors from "../../../constants/Colors";
import Config from "../../../constants/Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../../components/Functions";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Picker from "../../../components/Picker";
import Btn from "../../../components/Btn";
import { CommonActions } from "@react-navigation/native";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_CATEGORIES = "SET_CATEGORIES";
const TOGGLE_EDIT = "TOGGLE_EDIT";
const SET_ACTIVE_CAT = "SET_ACTIVE_CAT";
const SET_MODAL = "SET_MODAL";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_CATEGORIES:
      let categories = action.data;
      for (let i = 0; i < categories.length; i++) {
        categories[i].item = categories[i]["title"];
      }
      return {
        ...state,
        values: {
          ...state.values,
          categories: categories,
        },
      };
    case TOGGLE_EDIT:
      return {
        ...state,
        values: {
          ...state.values,
          onEdit: state.values.onEdit ? false : true,
        },
      };
    case SET_ACTIVE_CAT:
      return {
        ...state,
        values: {
          ...state.values,
          active_category: action.data,
        },
      };
    case SET_MODAL:
      return {
        ...state,
        values: {
          ...state.values,
          modalVisible: action.value,
        },
      };
    default:
      return state;
  }
};

const CategoriesScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      categories: [],
      onEdit: false,
      active_category: null,
      new_active_category: null,
      modalVisible: false,
    },
  });

  const get_categories = async () => {
    const url = Config.aioUrl + "/website/blog/category/list";
    let reqHedear = {
      "Content-Type": "application/json",
    };

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        
        dispatchFormState({ type: SET_CATEGORIES, data: response.data.data });
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const set_category = async () => {
    if (!formState.values.title || !formState.values.slug) {
      alert("وارد کردن فیلدها اجباریست.");
      return;
    }

    const url = Config.aioUrl + "/website/blog/category/set";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("title", formState.values.title);
    formdata.append("slug", formState.values.slug);
    if (formState.values.icon) formdata.append("icon", formState.values.icon);
    if (formState.values.active_category) formdata.append("category_id", formState.values.active_category.id);

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "Categories",
              },
            ],
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const remove_category = async () => {
    if (!formState.values.active_category || !formState.values.new_active_category) {
      alert("وارد کردن فیلدها اجباریست.");
      return;
    }

    const url = Config.aioUrl + "/website/blog/category/remove";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("old_category_id", formState.values.active_category.id);
    formdata.append("new_category_id", formState.values.new_active_category.id);

    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "Categories",
              },
            ],
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_categories();
    });

    return focused;
  }, [props.navigation]);

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Modal animationType="fade" transparent={true} visible={formState.values.modalVisible} swipeDirection="down">
        <TouchableOpacity
          style={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)" }}
          activeOpacity={1}
          onPressOut={() => {
            dispatchFormState({ type: SET_MODAL, value: false });
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: DESKTOP ? "30%" : "80%",
                marginTop: DESKTOP ? "15%" : "60%",
                marginLeft: DESKTOP ? "30%" : "10%",
                backgroundColor: Colors.bg,
                borderRadius: 25,
                paddingHorizontal: 25,
                paddingVertical: 35,
              }}
            >
              <Picker
                label="تغییر دسته بندی مطالب مرتبط به"
                id="new_active_category"
                onChange={inputChangeHandler}
                options={formState.values.categories}
              />
              <Btn
                title="ثبت وضعیت"
                onPress={async () => {
                  remove_category();
                }}
                loading={formState.values.loading}
                color={Colors.green}
                wrapperStyle={{ marginTop: 20 }}
              />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
      <Header title="دسته بندی های بلاگ" noBack />
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      {!formState.values.loading && formState.values.categories.length != 0 && (
        <View style={{ flex: 1, height: "100%", width: "100%" }}>
          <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "30%" : 0, paddingBottom: 30 }}>
            {!formState.values.onEdit && (
              <FlatList
                data={formState.values.categories}
                keyExtractor={(item) => item.id}
                renderItem={(itemData) => (
                  <TouchableOpacity
                    style={{
                      width: "100%",
                      backgroundColor: "#F2F2F2",
                      borderRadius: 15,
                      padding: 20,
                      marginBottom: 10,
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      dispatchFormState({ type: TOGGLE_EDIT });
                      dispatchFormState({ type: SET_ACTIVE_CAT, data: itemData.item });
                    }}
                  >
                    <Text style={{ fontFamily: "IranSans-regular", fontSize: 12, color: Colors.text }}>{itemData.item.title}</Text>
                    <Text style={{ fontFamily: "IranSans-regular", fontSize: 12, color: Colors.text }}>{itemData.item.slug}</Text>
                  </TouchableOpacity>
                )}
              />
            )}
            {formState.values.onEdit && (
              <View>
                <Input
                  label="عنوان"
                  id="title"
                  onChange={inputChangeHandler}
                  insert={formState.values.active_category ? formState.values.active_category.title : false}
                />
                <Input
                  label="نامک"
                  id="slug"
                  onChange={inputChangeHandler}
                  insert={formState.values.active_category ? formState.values.active_category.slug : false}
                />
                <Input
                  label="آیکن"
                  id="icon"
                  onChange={inputChangeHandler}
                  insert={formState.values.active_category ? formState.values.active_category.icon : false}
                />
                <View style={{ flexDirection: "row", justifyContent: "space-between", columnGap: 10 }}>
                  <Btn
                    title="حذف"
                    color={Colors.primary}
                    onPress={() => {
                      dispatchFormState({ type: SET_MODAL, value: true });
                    }}
                    wrapperStyle={{ flex: 1 }}
                    loading={formState.values.loading}
                  />
                  <Btn
                    title="انصراف"
                    color={Colors.secondary}
                    onPress={() => {
                      dispatchFormState({ type: TOGGLE_EDIT });
                      dispatchFormState({ type: SET_ACTIVE_CAT, data: null });
                    }}
                    wrapperStyle={{ flex: 1 }}
                  />
                  <Btn
                    title="ثبت"
                    color={Colors.green}
                    onPress={async () => {
                      set_category();
                    }}
                    wrapperStyle={{ flex: 1 }}
                    loading={formState.values.loading}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default CategoriesScreen;
