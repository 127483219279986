import { useCallback, useReducer, useEffect } from "react"
import {
  View,
  ScrollView,
  Text,
  StyleSheet,
  ActivityIndicator,
  Dimensions,
} from "react-native"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import Btn from "../../components/Btn"
import { useSelector } from "react-redux"
import AwesomeAlert from "react-native-awesome-alerts"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Config from "../../constants/Config"
import Picker from "../../components/Picker"
import Datepicker from "../../components/Datepicker"
import { separate } from "../../components/Functions"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const ALERT_SHOW = "ALERT_SHOW"
const SET_LOADING = "SET_LOADING"
const SET_PROJECT = "SET_PROJECT"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
        validities: {
          ...state.validities,
          [action.id]: action.validity,
        },
      }
    case ALERT_SHOW:
      return {
        ...state,
        values: {
          ...state.values,
          showAlert: action.show,
          alertMessage: action.msg,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_PROJECT:
      return {
        ...state,
        values: {
          ...state.values,
          project: action.data,
        },
      }
    default:
      return state
  }
}

const ActivateInactiveProjectScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const project_id = props.route.params.id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      project: null,
      contract_date: "",
      start_date: "",
      end_date: "",
      selected_staffs: [],
    },
    validities: {},
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value, validity) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value, validity })
    },
    [dispatchFormState]
  )

  const formValidity = (form) => {
    const areTrue = Object.values(form.validities).every(
      (value) => value != false
    )
    return areTrue
  }

  const get_project = async () => {
    const url = Config.aioUrl + "/project/get?id=" + project_id
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_PROJECT, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  const activate_project = async () => {
    const url = Config.aioUrl + "/project/activate"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let reqData = JSON.stringify({
      id: project_id,
      contract_date: formState.values.contract_date,
      start_date: formState.values.start_date,
      end_date: formState.values.end_date,
      selected_staffs: formState.values.selected_staffs,
    })
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: reqData,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        if (response.data.success) {
          alert(response.data.msg)
          props.navigation.goBack()
        } else {
          alert(response.data.msg)
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({
          type: ALERT_SHOW,
          show: true,
          msg: error.response.data.message,
        })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_project()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title={"فعال کردن پروژه" + " #" + project_id} noToggle />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingBottom: 300, paddingHorizontal: DESKTOP ? "30%" : 0 }}
      >
        {!formState.values.project && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
        {formState.values.project && (
          <View
            style={{
              width: "100%",
              paddingVertical: 20,
              paddingHorizontal: 20,
              backgroundColor: Colors.secondText,
              borderRadius: 15,
              flexDirection: "row-reverse",
              marginBottom: 30,
            }}
          >
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <Text
                style={{
                  color: "#fff",
                  fontFamily: "IranSans-Regular",
                  fontSize: 16,
                  marginBottom: 15,
                }}
              >
                {formState.values.project.customer_first_name +
                  " " +
                  formState.values.project.customer_last_name}
              </Text>
              <Text
                style={{
                  color: "#fff",
                  fontFamily: "IranSans-Regular",
                  fontSize: 16,
                }}
              >
                {formState.values.project.customer_mobile}
              </Text>
            </View>
            {(user_data.department == "sales" ||
              user_data.department == "lead") && (
              <View style={{ flex: 1, alignItems: "flex-start" }}>
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    marginBottom: 15,
                  }}
                >
                  {separate(formState.values.project.cost) +
                    " / " +
                    separate(formState.values.project.paid)}
                </Text>
                <Text
                  style={{
                    color: "#fff",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    direction: "rtl",
                  }}
                >
                  {"مانده: " +
                    separate(
                      formState.values.project.cost -
                        formState.values.project.paid
                    )}
                </Text>
              </View>
            )}
          </View>
        )}
        {formState.values.project &&
          (formState.values.project.contract_date == null ||
            formState.values.project.start_date == null ||
            formState.values.project.end_date == null) && (
            <View
              style={{
                width: "100%",
                height: 50,
                paddingVertical: 5,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.primary,
                marginBottom: 20,
                borderRadius: 3,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontFamily: "IranSans-Regular",
                  fontSize: 16,
                }}
              >
                تاریخ ها ناقص هستند
              </Text>
            </View>
          )}
        {user_data.department == "sales" &&
          formState.values.project &&
          formState.values.project.contract_date == null && (
            <View style={{ zIndex: 3 }}>
              <Datepicker
                label="تاریخ قرارداد"
                id="contract_date"
                onChange={inputChangeHandler}
              />
            </View>
          )}
        {user_data.department == "sales" &&
          formState.values.project &&
          formState.values.project.start_date == null && (
            <View style={{ zIndex: 2 }}>
              <Datepicker
                label="تاریخ شروع"
                id="start_date"
                onChange={inputChangeHandler}
              />
            </View>
          )}
        {user_data.department == "sales" &&
          formState.values.project &&
          formState.values.project.end_date == null && (
            <View style={{ zIndex: 1 }}>
              <Datepicker
                label="تاریخ پایان"
                id="end_date"
                onChange={inputChangeHandler}
              />
            </View>
          )}

        {formState.values.project &&
          formState.values.project.staffs.length == 0 && (
            <View
              style={{
                width: "100%",
                height: 50,
                paddingVertical: 5,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: Colors.primary,
                marginBottom: 20,
                borderRadius: 3,
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontFamily: "IranSans-Regular",
                  fontSize: 16,
                }}
              >
                باید پرسنلی در پروژه افزوده شود
              </Text>
            </View>
          )}

        {formState.values.project &&
          formState.values.project.staffs.length == 0 &&
          (user_data.department != "sales" ||
            formState.values.project.department == "sales") && (
            <View>
              <Picker
                id="selected_staffs"
                onChange={inputChangeHandler}
                label="پرسنل"
                options={formState.values.project.can_be_selected_staffs}
                isMulti
              />
            </View>
          )}

        {formState.values.project && (
          <Btn
            title="فعال کردن پروژه"
            color={Colors.green}
            onPress={() => {
              activate_project()
            }}
            loading={formState.values.loading}
          />
        )}
      </ScrollView>
      <AwesomeAlert
        show={formState.values.showAlert}
        showProgress={false}
        title="اعلان"
        message={
          formState.values.alertMessage
            ? formState.values.alertMessage
            : "ابتدا خطاها را بررسی کنید!"
        }
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showConfirmButton={true}
        confirmText="متوجه شدم"
        confirmButtonColor={Colors.green}
        onConfirmPressed={() => {
          dispatchFormState({ type: ALERT_SHOW, show: false })
        }}
        titleStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        messageStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
        confirmButtonTextStyle={{ fontFamily: "IranSans-Light", fontSize: 13 }}
      />
    </View>
  )
}

const Styles = StyleSheet.create({
  cardHeader: {
    width: "100%",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 0.5,
    alignItems: "center",
    paddingVertical: 10,
    marginVertical: 10,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default ActivateInactiveProjectScreen
