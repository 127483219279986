import { useEffect, useReducer } from "react"
import {
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Text,
} from "react-native"
import { useSelector } from "react-redux"
import Header from "../../components/Header"
import Colors from "../../constants/Colors"
import InfoTab from "./LeadTabs/InfoTab"
import TouchTab from "./LeadTabs/TouchTab"
import ReminderTab from "./LeadTabs/ReminderTab"
import Config from "../../constants/Config"
import axios from "axios"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
const Tab = createMaterialTopTabNavigator()
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const SET_LOADING = "SET_LOADING"
const SET_LEAD = "SET_LEAD"
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.value,
        },
      }
    case SET_LEAD:
      let touches = [{ title: "کل", success: 0, total: 0 }]
      for (let j = 0; j < action.data.touches.length; j++) {
        let already_exists = false
        let touch_index = null
        for (let k = 0; k < touches.length; k++) {
          if (touches[k].title == action.data.touches[j].title) {
            touch_index = k
            already_exists = true
          }
        }
        if (action.data.touches[j].success == 1) {
          touches[0].success++
          touches[0].total++
        } else {
          touches[0].total++
        }
        if (already_exists) {
          touches[touch_index].total++
          if (action.data.touches[j].success == 1)
            touches[touch_index].success++
        } else {
          touches.push({ ...action.data.touches[j], total: 1 })
        }
      }

      let can_select_touches = action.touches
      for (let i = 0; i < can_select_touches.length; i++) {
        can_select_touches[i].item = can_select_touches[i]["title"]
        delete can_select_touches[i].title
      }

      return {
        ...state,
        values: {
          ...state.values,
          lead: action.data,
          flags: action.flags,
          touches: touches,
          can_select_touches: can_select_touches,
        },
      }

    default:
      return state
  }
}

const LeadScreen = (props) => {
  const id = props.route.params.id
  const token = useSelector((store) => store.user.token)
  let tabs = [
    {
      name: "یاداوری",
      component: ReminderTab,
    },
    {
      name: "تاچ",
      component: TouchTab,
    },
    {
      name: "مشخصات",
      component: InfoTab,
    },
  ]

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      lead: false,
      flags: [],
      touches: [],
      can_select_touches: [],
    },
  })

  const get_lead = async () => {
    const url = Config.aioUrl + "/crm/lead/get?id=" + id

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, value: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, value: false })
        dispatchFormState({
          type: SET_LEAD,
          data: response.data.data,
          touches: response.data.touches,
          flags: response.data.flags,
        })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, value: false })
      })
  }

  useEffect(() => {
    get_lead()
  }, [])

  return (
    <View style={styles.wrapper}>
      <Header
        title={"اطلاعات لید " + "#" + id}
        noToggle
        noNotif
        noActiveSessions
      />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 0,
          flex: 1,
          height: "100%",
        }}
      >
        {!formState.values.lead && (
          <View
            style={{
              width: "100%",
              height: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color={Colors.primary} />
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 16,
                color: Colors.text,
                marginTop: 20,
              }}
            >
              در حال بارگذاری اطلاعات...
            </Text>
          </View>
        )}

        {formState.values.lead && (
          <Tab.Navigator
            initialRouteName="مشخصات"
            screenOptions={{
              tabBarActiveTintColor: Colors.primary,
              tabBarInactiveTintColor: Colors.secondText,
              tabBarIndicatorStyle: { backgroundColor: Colors.primary },
              tabBarLabelStyle: { fontFamily: "IranSans-Medium" },
            }}
            sceneContainerStyle={{ backgroundColor: "#fff", flex: 1 }}
          >
            {tabs.map((tab) => (
              <Tab.Screen
                key={tab.name}
                name={tab.name}
                component={tab.component}
                initialParams={{
                  lead: formState.values.lead,
                  touches: formState.values.touches,
                  flags: formState.values.flags,
                  can_select_touches: formState.values.can_select_touches,
                }}
              />
            ))}
          </Tab.Navigator>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
}

export default LeadScreen
