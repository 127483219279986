import React, { Component } from "react"
import { View, Text, Image, Linking, Platform } from "react-native"
import Config, { isDevelopeMode } from "../constants/Config"
import AsyncStorage from "@react-native-async-storage/async-storage"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.toString() }
  }

  componentDidCatch(error, errorInfo) {
    // if (!isDevelopeMode) {
      // console.clear()
    // }

    this.reportErr(this.state)
  }

  reportErr = async (state) => {
    const userDataJson = await AsyncStorage.getItem("user_data")

    const userDataObj = await JSON.parse(userDataJson)

    const userToken = await AsyncStorage.getItem("token")

    let user_page_location = null
    await Linking.getInitialURL().then((url) => (user_page_location = url))

    const url = Config.aioUrl + "/error-log/store"

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
    }

    const report_data = {
      error: state.error,
      hasError: state.hasError,
      time: Date.now(),
      appVersion: Config.appVersion,
      location: user_page_location,
      user: {
        name:
          userDataObj.name ||
          `${userDataObj.first_name} ${userDataObj.last_name}`,
        id: userDataObj.id,
        department: userDataObj.department,
        role: userDataObj.role,
        avatar: userDataObj.avatar,
      },
    }

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(report_data),
    }).finally(() => {
      if (!isDevelopeMode) {
        const isWeb = Platform.OS === "web"

        // if (isWeb) {
        //   window.location.reload()
        // }

        // console.clear()
      }

      return false
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <View
          style={{
            paddingHorizontal: 30,
            height: "100%",
            overflow: "hidden",
            backgroundColor: "#DD1C1D",
            width: "100%",
          }}
        >
          <View
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              flex: 1,
              marginBottom: 30,
            }}
          >
            <Image
              style={{ width: 150, height: 150 }}
              source={require("../assets/img/logoR-500.png")}
            />
          </View>
          <View
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              flex: 1,
            }}
          >
            <Text style={{ fontSize: 26, fontWeight: "bold", color: "#fff" }}>
              AminH Company
            </Text>
            <Text
              style={{
                fontSize: 18,
                fontWeight: "200",
                marginTop: 10,
                color: "#fff",
              }}
            >
              We Love What We Do
            </Text>
          </View>
        </View>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
