import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import SplashScreen, {
  screenOptions as SplashScreenOptions,
} from "../screens/Auth/SplashScreen"
import LoginOneScreen, {
  screenOptions as LoginOneScreenOptions,
} from "../screens/Auth/LoginOneScreen"
import LoginTwoScreen, {
  screenOptions as LoginTwoScreenOptions,
} from "../screens/Auth/LoginTwoScreen"
import EnableNotifScreen, {
  screenOptions as EnableNotifScreenOptions,
} from "../screens/Auth/EnableNotifScreen"
import SetKeyScreen, {
  screenOptions as SetKeyScreenOptions,
} from "../screens/Auth/SetKeyScreen"

import { useSelector } from "react-redux"

const AuthStackNavigator = createNativeStackNavigator()
const AuthNavigator = () => {
  const role = useSelector((store) => store.user.user_data.role)
  return (
    <AuthStackNavigator.Navigator>
      <AuthStackNavigator.Screen
        name="Splash"
        component={SplashScreen}
        options={{ ...SplashScreenOptions, title: "AminH" }}
      />
      <AuthStackNavigator.Screen
        name="LoginOne"
        component={LoginOneScreen}
        options={{ ...LoginOneScreenOptions, title: "ورود" }}
      />
      <AuthStackNavigator.Screen
        name="LoginTwo"
        component={LoginTwoScreen}
        options={{ ...LoginTwoScreenOptions, title: "تایید شماره" }}
      />
      <AuthStackNavigator.Screen
        name="EnableNotif"
        component={EnableNotifScreen}
        options={{ ...EnableNotifScreenOptions, title: "نوتیفیکیشن" }}
      />
      {role == "staff" && (
        <AuthStackNavigator.Screen
          name="SetKey"
          component={SetKeyScreen}
          options={{ ...SetKeyScreenOptions, title: "ثبت کلید" }}
        />
      )}
    </AuthStackNavigator.Navigator>
  )
}

export default AuthNavigator
