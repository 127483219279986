import { useReducer, useCallback, useEffect } from "react"
import { ScrollView } from "react-native"
import { useSelector } from "react-redux"
import Input from "../../../components/Input"
import InputNote from "../../../components/InputNote"
import ExpoPicker from "../../../components/ExpoPicker"
import File from "../../../components/File"
import Btn from "../../../components/Btn"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM = "SET_FORM"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_FORM:
      return {
        ...state,
        values: {
          ...state.values,
          form: action.data,
          is_staff_in_project: action.is_staff_in_project,
        },
      }
    default:
      return state
  }
}

const SaligheTab = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const project_id = props.route.params.project_id
  const role = useSelector((store) => store.user.user_data.role)
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      fav_domains: "",
      we_logo: "",
      logo: "",
      fav_sites: "",
      last_words: "",
      form: null,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_form = async () => {
    const url = Config.aioUrl + "/project/forms/siteform/update"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("project_id", project_id)
    formdata.append("fav_domains", formState.values.fav_domains)
    if (formState.values.we_logo == "1") {
      formdata.append("we_logo", formState.values.we_logo)
    }
    formdata.append("fav_sites", formState.values.fav_sites)
    formdata.append("last_words", formState.values.last_words)

    dispatchFormState({ type: SET_LOADING, loading: true })
    return axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        return response.data
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_form = async () => {
    const url =
      Config.aioUrl + "/project/forms/siteform/get?project_id=" + project_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_FORM,
          data: response.data.data,
          is_staff_in_project: response.data.is_staff_in_project,
        })
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    get_form()
  }, [])

  return (
    <ScrollView
      style={{
        width: "100%",
        height: "100%",
        paddingHorizontal: 10,
      }}
      showsVerticalScrollIndicator={false}
    >
      {is_customer && (
        <InputNote
          title="توضیح"
          content="این بخش شامل سوالات سلیقه یابی می باشد. در واقع شما با پاسخگویی به این سوالات سلقیه و نیازهای خود را به ما منتقل می کنید. در صورتی که نمی دانید چه نوع سایتی مناسب شماست، اصلا نگران نباشید. طراحان ما با استفاده از تخصص و تجربه طراحی با نظر خودشان بهترین سایت را برای شما طراحی خواهند کرد. لازم به ذکر است پر نکردن این بخش به معنای واگذاری کامل طراحی به نظر طراحان است و امکان تغییر نظر در آینده وجود ندارد."
          icon="information-circle-outline"
        />
      )}
      {!formState.values.loading &&
        (is_customer ||
          formState.values.is_staff_in_project ||
          capabilities.includes("role_site_full_access") ||
          capabilities.includes("role_site_quality_supervisor") ||
          capabilities.includes("role_site_coordination")) && (
          <ExpoPicker
            id="we_logo"
            onChange={inputChangeHandler}
            label="آیا لوگو شما توسط تیم ما طراحی شده است؟"
            options={[
              { item: "بله", id: "1" },
              { item: "خیر", id: "0" },
            ]}
            insert={
              formState.values.form
                ? formState.values.form.siteform_we_logo
                  ? formState.values.form.siteform_we_logo
                  : false
                : false
            }
          />
        )}
      {formState.values.we_logo == "0" &&
        (is_customer ||
          formState.values.is_staff_in_project ||
          capabilities.includes("role_site_full_access") ||
          capabilities.includes("role_site_quality_supervisor") ||
          capabilities.includes("role_site_coordination")) && (
          <File
            id="logo"
            title="تصویر لوگوی شما"
            extension="jpg"
            content_type="image/jpeg"
            max_size={2048}
            max_width={1080}
            max_height={1080}
            min_width={300}
            min_height={300}
            token={token}
            onChange={inputChangeHandler}
            insert={
              !formState.values.logo && formState.values.form
                ? formState.values.form.siteform_logo
                  ? formState.values.form.siteform_logo
                  : formState.values.logo
                : formState.values.logo
            }
            value={formState.values.logo}
            wrapperStyle={{ marginTop: 10 }}
            downloadUrl={Config.aioUrl + "/project/forms/getFormFile"}
            downloadParams={[
              {
                key: "bucket",
                value:
                  !formState.values.logo && formState.values.form
                    ? formState.values.form.siteform_logo
                      ? formState.values.form.siteform_logo.bucket
                      : formState.values.logo
                    : formState.values.logo,
              },
              {
                key: "bucket_key",
                value:
                  !formState.values.logo && formState.values.form
                    ? formState.values.form.siteform_logo
                      ? formState.values.form.siteform_logo.bucket_key
                      : formState.values.logo
                    : formState.values.logo,
              },
            ]}
            editOptions={{
              compress: 0.8,
              resizeHeight: 1080,
              resizeWidth: 1080,
            }}
            uploadUrl={Config.aioUrl + "/project/forms/siteform/fileupload"}
            uploadParams={[{ key: "project_id", value: project_id }]}
            standalone
          />
        )}
      <Input
        disabled={
          (formState.values.is_staff_in_project &&
            capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        multiline
        label="نام دامنه های پیشنهادی"
        id="fav_domains"
        onChange={inputChangeHandler}
        insert={
          formState.values.form
            ? formState.values.form.siteform_fav_domains
            : ""
        }
      />
      {is_customer && (
        <InputNote
          content="نام دامنه همان آدرس اینترنتی سایت است. مثلا آدرس سایت گوگل google.com می باشد. آدرس ها(نام ها)ی پیشنهادی خود را در بالا وارد کنید. ما به ترتیب ورود آزاد بودن آن ها(امکان خرید و عدم اشغال این آدرس ها توسط دیگران) را بررسی می کنیم. مثال: shirini-soheil.com یا maryamsedaghati.ir در صورت خالی گذاشتن نام سایت شما توسط تیم طراحی بر مبنای تجربه و تخصص انتخاب خواهد شد و امکان تغییر آن وجود ندارد."
          icon="information-circle-outline"
        />
      )}

      {(is_customer ||
        formState.values.is_staff_in_project ||
        capabilities.includes("role_site_full_access") ||
        capabilities.includes("role_site_quality_supervisor") ||
        capabilities.includes("role_site_coordination")) && (
        <Input
          disabled={
            (formState.values.is_staff_in_project &&
              capabilities.includes("project_siteform_update_own_project")) ||
            is_customer ||
            capabilities.includes("role_site_full_access")
              ? false
              : true
          }
          multiline
          label="سایت های مورد علاقه"
          id="fav_sites"
          onChange={inputChangeHandler}
          insert={
            formState.values.form
              ? formState.values.form.siteform_fav_sites
              : ""
          }
        />
      )}
      {is_customer && (
        <InputNote
          content="چند نمونه سایت که طراحی آن ها مطابق با سلیقه شما هست را وارد کنید. همچنین سایت رقبا می تواند گزینه خوبی برای طراحی سایت باشد. این اطلاعات به ما کمک می کند تا با سلیقه شما آشنا بشیم."
          icon="information-circle-outline"
        />
      )}

      <Input
        disabled={
          (formState.values.is_staff_in_project &&
            capabilities.includes("project_siteform_update_own_project")) ||
          is_customer ||
          capabilities.includes("role_site_full_access")
            ? false
            : true
        }
        multiline
        label="توضیحات درباره طراحی"
        id="last_words"
        onChange={inputChangeHandler}
        insert={
          formState.values.form ? formState.values.form.siteform_last_words : ""
        }
      />
      {is_customer && (
        <InputNote
          content="اطلاعات تکمیلی درباره آن چه در سایتتان نیاز دارید. چیزی هست که نپرسیدیم؟ برامون بنویسید."
          icon="information-circle-outline"
        />
      )}

      {(is_customer ||
        (formState.values.is_staff_in_project &&
          capabilities.includes("project_siteform_update_own_project")) ||
        capabilities.includes("role_site_full_access")) && (
        <Btn
          title="ثبت اطلاعات سلیقه یابی"
          onPress={async () => {
            let result = await send_form()
            alert(result.msg)
            if (is_customer) props.navigation.goBack()
          }}
          wrapperStyle={{ marginVertical: 50 }}
          loading={formState.values.loading}
        />
      )}
    </ScrollView>
  )
}

export default SaligheTab
