import { useReducer, useCallback } from "react";
import { View } from "react-native";
import Colors from "../../constants/Colors";
import InputNote from "../../components/InputNote";

const LogoVideoScreen = (props) => {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 15,
      }}
    >
      <InputNote
        backgroundColor={Colors.secondary}
        textColor="#fff"
        content="کیفیت ویدیو با سرعت اینترنت شما تنظیم می شود، برای مشاهده ویدیو با کیفیت بالا حتما VPN خود را خاموش کنید."
      />
      <iframe
        src="https://player.arvancloud.ir/index.html?config=https://aminh.arvanvod.ir/ENWwVl4DLy/MGljKGjD4Z/origin_config.json&skin=shaka"
        style={{ height: 450, width: "100%" }}
        name="logo.mp4"
        allowFullScreen={true}
      ></iframe>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default LogoVideoScreen;
