import { manipulateAsync, SaveFormat } from "expo-image-manipulator"
import { Image } from "react-native"
import { Buffer } from "@craftzdog/react-native-buffer"
import AsyncStorage from "@react-native-async-storage/async-storage"

export const separate = (num) => {
  let number = num.toString().replace(/\D/g, "")
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const version_compare = (v1, v2) => {
  if (typeof v1 !== "string") return false
  if (typeof v2 !== "string") return false
  v1 = v1.split(".")
  v2 = v2.split(".")
  const k = Math.min(v1.length, v2.length)
  for (let i = 0; i < k; ++i) {
    v1[i] = parseInt(v1[i], 10)
    v2[i] = parseInt(v2[i], 10)
    if (v1[i] > v2[i]) return 1
    if (v1[i] < v2[i]) return -1
  }
  return v1.length == v2.length ? 0 : v1.length < v2.length ? -1 : 1
}

export const encrypt = async (term, key_encrypt) => {
  let result = term.split("")
  for (let i = 0; i < result.length; i++) {
    result[i] = key_encrypt[result[i]] ? key_encrypt[result[i]] : result[i]
  }
  return result.join("")
}

export const decrypt = async (term, key_decrypt) => {
  let result = term.split("")
  for (let i = 0; i < result.length; i++) {
    result[i] = key_decrypt[result[i]] ? key_decrypt[result[i]] : result[i]
  }
  return result.join("")
}

const flip = (data) => Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]))

export const is_key_valid = (key) => {
  const decoded_key = JSON.parse(Buffer.from(key, "base64").toString())
  const key_decrypt = flip(decoded_key)
  const term = "usv GCnDn jOQ"
  let result = term.split("")
  for (let i = 0; i < result.length; i++) {
    result[i] = key_decrypt[result[i]] ? key_decrypt[result[i]] : result[i]
  }
  return result.join("")
}

export const edit_image = async (image, options = null) => {
  let optionsArray = []

  //Let's calculate resolution

  const getImageSize = async (uri) =>
    new Promise((resolve) => {
      Image.getSize(uri, (width, height) => {
        resolve([width, height])
      })
    })

  const [img_width, img_height] = await getImageSize(image.uri)

  let final_width = null
  let final_height = null
  if (options.resizeHeight && options.resizeWidth) {
    if (
      (img_width > options.resizeWidth && img_height <= options.resizeHeight) ||
      (img_width <= options.resizeWidth && img_height > options.resizeHeight)
    ) {
      const maxVal = Math.max(img_width, img_height)
      if (maxVal == img_width) {
        final_width = options.resizeWidth
        final_height = img_height * (final_width / img_width)
      }
      if (maxVal == img_height) {
        final_height = options.resizeHeight
      }
    }

    if (img_width > options.resizeWidth && img_height > options.resizeHeight) {
      const maxVal = Math.max(img_width, img_height)
      if (maxVal == img_width) {
        final_width = options.resizeWidth
        final_height = img_height * (options.resizeWidth / img_width)

        if (final_height > options.resizeHeight) {
          final_height = options.resizeHeight
          final_width = null
        }
      }

      if (maxVal == img_height) {
        final_height = options.resizeHeight
        final_width = img_width * (options.resizeHeight / img_height)

        if (final_width > options.resizeWidth) {
          final_width = options.resizeWidth
          final_height = null
        }
      }
    }
  } else if (options.resizeHeight) {
    if (img_height > options.resizeHeight) {
      final_height = options.resizeHeight
    }
  } else if (options.resizeWidth) {
    if (img_width > options.resizeWidth) {
      final_height = options.resizeWidth
    }
  }

  if (final_height != null && final_width != null) {
    optionsArray.push({ resize: { height: final_height, width: final_width } })
  }
  if (final_height == null && final_width) {
    optionsArray.push({ resize: { width: final_width } })
  }
  if (final_height && final_width == null) {
    optionsArray.push({ resize: { height: final_height } })
  }
  let edited_image = await manipulateAsync(image.uri, optionsArray, {
    compress: options.compress ? options.compress : 1,
    format: options.format ? options.format : SaveFormat.JPEG,
  })

  const res = await fetch(edited_image.uri)
  const buf = await res.arrayBuffer()

  let final_name_array = image.file.name.split(".")
  let final_name_extension = final_name_array[final_name_array.length - 1]
  let final_name_first = ""
  for (let i = 0; i < final_name_array.length - 1; i++) {
    final_name_first += final_name_array[i]
  }
  if (options.format == "image/png") {
    final_name_extension = "png"
  }
  if (!options.format) {
    final_name_extension = "jpg"
  }
  let final_name = final_name_first + "." + final_name_extension.toLowerCase()
  let edited_image_file = new File([buf], final_name, {
    type: options.format ? options.format : "image/jpeg",
  })

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  let uri = await toBase64(edited_image_file)
  edited_image = {
    file: edited_image_file,
    name: final_name,
    size: edited_image_file.size,
    uri: uri,
  }
  return edited_image
}

const getBaseConversionNumber = (label) => {
  const faDigits = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
  const enDigits = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
  const arDigits = ["٠", "٩", "٨", "٧", "٦", "٥", "٤", "٣", "٢", "١"]

  var whichDigit = {}

  switch (label) {
    case "fa":
      whichDigit[label] = faDigits
      break
    case "en":
      whichDigit[label] = enDigits
      break
    case "ar":
      whichDigit[label] = arDigits
      break
    case "all":
      whichDigit = { fa: faDigits, en: enDigits, ar: arDigits }
      break
    default:
      whichDigit = []
  }

  return whichDigit
}

const CvnFromTo = (fromDigits, toDigits, str) => {
  var str = str == undefined ? this : str
  for (var i = 0; i < toDigits.length; i++) {
    const currentFromDigit = fromDigits[i]
    const currentToDigit = toDigits[i]
    const regex = new RegExp(currentFromDigit, "g")
    str = str.replace(regex, currentToDigit)
  }
  return str
}

export const convertDigits = (to, value) => {
  let str = value
  const toCvn = getBaseConversionNumber(to)[to]
  const allDigits = getBaseConversionNumber("all")

  delete allDigits[to]

  const Objkeys = Object.keys(allDigits)
  for (var i = 0; i < Objkeys.length; i++) {
    const currentKey = Objkeys[i]
    const fromCvn = allDigits[currentKey]
    str = CvnFromTo(fromCvn, toCvn, str)
  }
  return str
}

export const fixPhoneFormat = (value) => {
  let enPhone = convertDigits("en", value)
    .replaceAll(" ", "")
    .replaceAll("+", "")
    .replaceAll("-", "")
    .replaceAll("(", "")
    .replaceAll(")", "")

  if (/^09\d{9}$/.test(enPhone)) return enPhone.replace(enPhone.slice(0, 2), "989")

  if (/^9\d{9}$/.test(enPhone)) return `98${enPhone}`

  return enPhone
}

export const logOut = async () => {
  await AsyncStorage.clear()
  window.location.reload(true)
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const generatePassword = () => {
  let length = 16,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@$%&()^*",
    retVal = ""
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export const removeDomainPrefix = (url) =>
  url.substring(0, url.lastIndexOf(".")).replace(/^[^.]+\./g, "")
