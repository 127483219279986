import { useCallback, useEffect, useReducer } from "react";
import { View, Text, StyleSheet, FlatList, ActivityIndicator } from "react-native";
import Header from "../../components/Header";
import Colors from "../../constants/Colors";
import Input from "../../components/Input";
import { useSelector } from "react-redux";
import MarketItem from "../../components/MarketItem";
import filter from "lodash.filter";
import axios from "axios";
import Config from "../../constants/Config";
import { logOut } from "../../components/Functions";
import Btn from "../../components/Btn";

const SEARCH = "SEARCH";
const SET_LOADING = "SET_LOADING";
const SET_MARKET = "SET_MARKET";
const formReducer = (state, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          query: action.query,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_MARKET:
      return {
        ...state,
        values: {
          ...state.values,
          market: action.data,
        },
      };
    default:
      return state;
  }
};

const MarketListScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      data: [],
      market: [],
      loading: false,
    },
  });

  const get_market_list = async () => {
    const url = Config.aioUrl + "/asc/market/list";
    let reqHedear = {
      "Content-Type": "application/json",
    };
    dispatchFormState({ type: SET_LOADING, loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_MARKET, data: response.data.data });
        dispatchFormState({ type: SET_LOADING, loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_market_list();
    });

    return focused;
  }, [props.navigation]);

  const handleSearch = useCallback(
    (id, text) => {
      const contains = (item, query) => {
        if (item.folder_name.includes(query) || item.type.includes(query) || item.version.includes(query)) {
          return true;
        }
        return false;
      };
      const data = filter(formState.values.market, (item) => contains(item, text));
      dispatchFormState({ type: SEARCH, data, query: text });
    },
    [dispatchFormState, formState.values.market]
  );
  return (
    <View style={styles.wrapper}>
      <Header title="لیست مارکت" noBack />
      <Btn
        title="افزودن آیتم جدید"
        color={Colors.secondary}
        onPress={() => {
          props.navigation.navigate("AddMarketItem", { item_id: false });
        }}
        wrapperStyle={{ width: 150, height: 35 }}
        titleStyle={{ fontSize: 12 }}
      />
      <Input label="به دنبال چه می گردی؟" id="search" onChange={handleSearch} maxLength={20} validity />
      <Text
        style={{
          color: Colors.text,
          fontFamily: "Iransans-Medium",
          fontSize: 14,
          marginBottom: 10,
          marginTop: 30,
        }}
      >
        {formState.values.data.length} مورد یافت شد
      </Text>
      {!formState.values.loading && (
        <View style={{ flex: 1, width: "100%", height: "100%" }}>
          <View style={{ flexDirection: "row-reverse" }}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                flex: 3,
              }}
            >
              <Text style={styles.headerText}>نام</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                flex: 1,
              }}
            >
              <Text style={styles.headerText}>نوع</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                flex: 1,
              }}
            >
              <Text style={styles.headerText}>ورژن</Text>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                height: 50,
                flex: 1,
              }}
            >
              <Text style={styles.headerText}>آخرین ویرایش</Text>
            </View>

            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.secondary,
                flex: 1,
                height: 50,
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
              }}
            >
              <Text style={styles.headerText}>عملیات</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <FlatList
              data={formState.values.data}
              renderItem={(item) => <MarketItem item={item.item} token={token} get_market_list={get_market_list} />}
              keyExtractor={(item) => item.id}
            />
          </View>
        </View>
      )}
      {formState.values.loading && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 25,
    height: "100%",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
  headerText: {
    fontFamily: "IranSans-Medium",
    fontSize: 14,
    color: "#fff",
  },
});

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default MarketListScreen;
