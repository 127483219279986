import { View, Text } from "react-native";
import Colors from "../constants/Colors";
import { separate } from "./Functions";

const ProgressBar = (props) => {
  let background = "";
  if (props.progress < 50) background = Colors.primary;
  if (props.progress >= 50 && props.progress < 80) background = Colors.error;
  if (props.progress >= 80 && props.progress < 100) background = Colors.blue;
  if (props.progress >= 100) background = Colors.green;

  let width = 0;
  if (props.progress < 100) width = props.progress + "%";
  if (props.progress >= 100) width = "100%" + 1;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: props.end > 999 ? 14 : 30, color: Colors.text }}>
          {props.start > 999 ? separate(props.start) : props.start}
        </Text>
        <Text style={{ fontFamily: "IranSans-Medium", fontSize: props.end > 999 ? 14 : 30, color: Colors.text }}>
          {props.end > 999 ? separate(props.end) : props.end}
        </Text>
      </View>
      <View style={{ width: "100%", height: 20, borderColor: Colors.secondText, borderRadius: 10, borderWidth: 1 }}>
        <View
          style={{
            width: width,
            height: 20,
            borderRadius: 10,
            backgroundColor: background,
            marginTop: -1,
            marginLeft: -1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: "#fff" }}>{"%" + props.progress}</Text>
        </View>
      </View>
    </View>
  );
};

export default ProgressBar;
