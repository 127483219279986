import Slider from "@react-native-community/slider"
import { Dimensions, Text, View } from "react-native"
import Colors from "../constants/Colors"
import { useState } from "react"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const Range = (props) => {
  const {
    type,
    onChange,
    id,
    disabled,
    label, //? Just in Normal type
    minimumValue, //? Just in Normal type
    maximumValue, //? Just in Normal type
    insert,
    leftText,
    rightText,
    fixedValue,
  } = props

  const {
    wrapperStyle,
    labelWrapperStyle, //? Just in Normal type
    rangeStyle,
    leftTextStyle,
    rightTextStyle,
    leftColor,
    rightColor,
    thumbTintColor,
    noBorder,
  } = props

  if (type?.toUpperCase() === "NORMAL" || !type) {
    return (
      <View
        style={{
          width: "100%",
          height: 50,
          borderWidth: noBorder ? 0 : 0.8,
          borderRadius: 7,
          borderColor: Colors.secondText,
          marginVertical: noBorder ? 0 : 15,
          justifyContent: "space-evenly",
          gap: 15,
          paddingHorizontal: noBorder ? 0 : 15,
          alignItems: "center",
          flexDirection: "row",
          ...wrapperStyle,
        }}
      >
        {label && (
          <View
            style={{
              position: "absolute",
              top: -10,
              right: 20,
              backgroundColor: Colors.bg,
              paddingHorizontal: 7,
              ...labelWrapperStyle,
            }}
          >
            <Text
              selectable={false}
              style={{
                fontFamily: "IranSans-Light",
                fontSize: 13,
                color: Colors.secondText,
              }}
            >
              {label}
            </Text>
          </View>
        )}

        {leftText && (
          <Text
            selectable={false}
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: Colors.secondText,
              ...leftTextStyle,
            }}
          >
            {leftText}
          </Text>
        )}

        <Slider
          style={{
            marginHorizontal: "auto",
            opacity: disabled ? "70%" : "100%",
            ...rangeStyle,
          }}
          minimumValue={minimumValue || 0}
          maximumValue={maximumValue || 100}
          value={insert || minimumValue || 0}
          minimumTrackTintColor={leftColor || Colors.primary}
          maximumTrackTintColor={rightColor || Colors.secondText}
          thumbTintColor={thumbTintColor || Colors.text}
          onValueChange={(value) => {
            onChange(id, fixedValue ? value.toFixed() : value)
          }}
          id={id}
          disabled={disabled || false}
        />

        {rightText && (
          <Text
            selectable={false}
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: Colors.secondText,
              ...rightTextStyle,
            }}
          >
            {rightText}
          </Text>
        )}
      </View>
    )
  }

  if (type?.toUpperCase() === "PRO") {
    const [rangeValue, setRangeValue] = useState(insert || minimumValue || 0)

    let minimumValue = 0
    let maximumValue = 100

    let leftTextOpacity = 100 - rangeValue > 20 ? 100 - rangeValue : 20
    let rightTextOpacity = rangeValue > 20 ? rangeValue : 20

    return (
      <View
        style={{
          width: "100%",
          height: 50,
          borderWidth: noBorder ? 0 : 0.8,
          borderRadius: 7,
          borderColor: Colors.secondText,
          marginVertical: 15,
          justifyContent: "space-evenly",
          gap: 15,
          alignItems: "center",
          flexDirection: "row",
          overflow: "hidden",
          position: "relative",
          ...wrapperStyle,
        }}
      >
        {leftText && (
          <View
            style={{
              backgroundColor: Colors.blue,
              paddingHorizontal: 8,
              minWidth: DESKTOP ? 100 : 60,
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              opacity: `${leftTextOpacity}%`,
            }}
          >
            <Text
              selectable={false}
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 14,
                color: "white",
                ...leftTextStyle,
              }}
            >
              {leftText}
            </Text>
          </View>
        )}

        <View
          style={{
            width: 0.8,
            backgroundColor: Colors.secondText,
            opacity: "30%",
            borderRadius: 100,
            height: "100%",
            position: "absolute",
            left: "50%",
          }}
        ></View>

        <Slider
          style={{
            marginHorizontal: "auto",
            opacity: disabled ? "70%" : "100%",
            ...rangeStyle,
          }}
          minimumValue={minimumValue || 0}
          maximumValue={maximumValue || 100}
          value={insert || minimumValue || 0}
          minimumTrackTintColor={leftColor || Colors.primary}
          maximumTrackTintColor={rightColor || Colors.secondText}
          thumbTintColor={thumbTintColor || Colors.text}
          onValueChange={(value) => {
            onChange(id, fixedValue ? value.toFixed() : value)
            setRangeValue(value.toFixed())
          }}
          id={id}
          disabled={disabled || false}
        />

        {rightText && (
          <View
            style={{
              backgroundColor: Colors.blue,
              paddingHorizontal: 8,
              minWidth: DESKTOP ? 100 : 60,
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              opacity: `${rightTextOpacity}%`,
            }}
          >
            <Text
              selectable={false}
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 14,
                color: "white",
                ...rightTextStyle,
              }}
            >
              {rightText}
            </Text>
          </View>
        )}
      </View>
    )
  }
}

export default Range
