import { useReducer } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from "react-native";
import Colors from "../constants/Colors";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import axios from "axios";
import Config from "../constants/Config";
import { logOut } from "./Functions";
import moment from "moment-jalaali";
import { CommonActions } from "@react-navigation/native";

const SET_LOADING = "SET_LOADING";
const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.loading,
          },
        },
      };

    default:
      return state;
  }
};

const MarketItem = (props) => {
  const navigation = useNavigation();
  const { item, token, get_market_list } = props;
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        remove_item: false,
      },
    },
  });

  const remove_item = async () => {
    const url = Config.aioUrl + "/asc/market/removeitem";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("item_id", item.id);

    dispatchFormState({ type: SET_LOADING, name: "remove_item", loading: true });
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        dispatchFormState({ type: SET_LOADING, name: "remove_item", loading: false });
        alert(response.data.msg);
        get_market_list();
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "remove_item", loading: false });
      });
  };

  return (
    <View style={{ height: 80, backgroundColor: "#F2F2F2", borderRadius: 15, flexDirection: "row-reverse", marginVertical: 5 }}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 3,
        }}
      >
        <Text style={styles.headerText}>{item.folder_name}</Text>
      </View>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text style={styles.headerText}>{item.type}</Text>
      </View>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text style={styles.headerText}>{item.version}</Text>
      </View>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Text style={styles.headerText}>{moment.unix(item.last_modified).fromNow()}</Text>
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: Colors.primary,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            if (confirm("فایل آیتم به صورت کامل پاک خواهد شد. آیا مطمئن هستید؟")) {
              remove_item();
            }
          }}
        >
          {!formState.values.loading.remove_item && <Ionicons name="ios-trash-outline" color="#fff" size={16} />}
          {formState.values.loading.remove_item && <ActivityIndicator size="small" color="#fff" />}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 24,
            height: 24,
            backgroundColor: Colors.secondary,
            borderRadius: 3,
            marginHorizontal: 3,
          }}
          onPress={() => {
            navigation.navigate("AddMarketItem", { item_id: item.id });
          }}
        >
          <Ionicons name="pencil" color="#fff" size={16} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    fontFamily: "IranSans-Regular",
    fontSize: 14,
    color: Colors.text,
  },
});

export default MarketItem;
