import { useReducer, useCallback, useEffect } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import { useSelector } from "react-redux";
import Colors from "../../../constants/Colors";
import Header from "../../../components/Header";
import Config from "../../../constants/Config";
import axios from "axios";
import { logOut } from "../../../components/Functions";
import Btn from "../../../components/Btn";
import Input from "../../../components/Input";
import ExpoPicker from "../../../components/ExpoPicker";
import File from "../../../components/File";
import { CommonActions } from "@react-navigation/native";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_FORM_SEND = "SET_FORM_SEND";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      };
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          form_send_progress: action.value,
        },
      };

    default:
      return state;
  }
};

const AddTicketScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      department: null,
      title: null,
      description: null,
      file: null,
      form_send_progress: 0,
    },
  });
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const send_ticket = async () => {
    if (!formState.values.department || !formState.values.title || !formState.values.description) {
      alert("وارد کردن عنوان، توضیحات و دپارتمان اجباریست.");
      return;
    }

    const url = Config.aioUrl + "/customer/ticket/send";

    let reqHedear = {
      Authorization: "Bearer " + token,
    };

    let formdata = new FormData();
    formdata.append("department", formState.values.department);
    formdata.append("title", formState.values.title);
    formdata.append("description", formState.values.description);
    if (formState.values.file) {
      formdata.append("file", formState.values.file.file, formState.values.file.name);
    }
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        });
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "SupportList",
                },
              ],
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="ارسال تیکت جدید" noNotif noActiveSessions noToggle />
      <View style={{ paddingHorizontal: DESKTOP ? "30%" : 0 }}>
        <ExpoPicker
          id="department"
          onChange={inputChangeHandler}
          label="دپارتمان"
          options={[
            { item: "پشتیبانی سایت", id: "site" },
            { item: "فروش", id: "sales" },
          ]}
        />
        <Input label="عنوان تیکت" id="title" onChange={inputChangeHandler} />
        <Input label="توضیحات درخواست" id="description" onChange={inputChangeHandler} multiline />
        <View style={{ width: "100%", marginBottom: 30 }}>
          <File
            id="file"
            title="پیوست"
            extension="jpg"
            max_size={300}
            max_width={1080}
            max_height={1080}
            content_type="image/jpeg"
            value={formState.values.file}
            editOptions={{
              compress: 0.7,
              resizeWidth: 800,
            }}
            onChange={inputChangeHandler}
          />
        </View>
        <Btn
          title={
            formState.values.form_send_progress > 0 && formState.values.form_send_progress < 1
              ? "در حال ارسال...(" + (formState.values.form_send_progress * 100).toFixed(0) + "%)"
              : "ثبت تیکت"
          }
          onPress={() => {
            send_ticket();
          }}
        />
      </View>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default AddTicketScreen;
