import { useReducer, useCallback, useEffect, useRef, useState } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  Modal,
  TouchableWithoutFeedback,
  Switch,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import Btn from "../../components/Btn"
import Picker from "../../components/Picker"
import Input from "../../components/Input"
import File from "../../components/File"
import moment from "moment-jalaali"
import { CommonActions, useNavigation } from "@react-navigation/native"
import { ActivityIndicator } from "react-native-web"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import * as Clipboard from "expo-clipboard"
import { Ionicons } from "@expo/vector-icons"
import FormData from "form-data"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_MESSAGES = "SET_MESSAGES"
const SET_MODAL = "SET_MODAL"
const SET_MODAL_STATUS = "SET_MODAL_STATUS"
const SET_SILENT = "SET_SILENT"
const SET_ACCESS_MODAL = "SET_ACCESS_MODAL"
const SET_IS_EDIT_ACCESS = "SET_IS_EDIT_ACCESS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          ...state.values,
          form_send_progress: action.value,
        },
      }
    case SET_MESSAGES:
      return {
        ...state,
        values: {
          ...state.values,
          messages: action.data,
          can_ref_users: action.can_ref_users,
          ticket_status: action.ticket_status,
          customer_data: action.customer_data,
        },
      }
    case SET_MODAL:
      return {
        ...state,
        values: {
          ...state.values,
          modalVisible: action.value,
        },
      }
    case SET_ACCESS_MODAL:
      return {
        ...state,
        values: {
          ...state.values,
          isAccessModalOpen: action.value,
          targetSite: action.site_id || null,
        },
      }
    case SET_IS_EDIT_ACCESS:
      return {
        ...state,
        values: {
          ...state.values,
          isEditAccess: action.value,
        },
      }
    case SET_MODAL_STATUS:
      return {
        ...state,
        values: {
          ...state.values,
          modalStatusVisible: action.value,
        },
      }
    case SET_SILENT:
      return {
        ...state,
        values: {
          ...state.values,
          silent: state.values.silent ? false : true,
        },
      }
    default:
      return state
  }
}

const Message = (props) => {
  const { item, user_data, token } = props
  const [download, setDownload] = useState(false)

  const get_file = async (message_id) => {
    const url = Config.aioUrl + "/ticket/getFile?message_id=" + message_id
    setDownload(true)
    const file = await axios(url, {
      headers: { Authorization: "Bearer " + token },
      method: "get",
      responseType: "blob",
    })
    const blobUrl = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement("a")
    link.href = blobUrl
    link.setAttribute("download", item.content_file.bucket_key)
    document.body.appendChild(link)
    link.click()
    setDownload(false)
  }

  if (item.sender_id == null && item.customer_id == null) {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            width: "90%",
            paddingVertical: 15,
            paddingHorizontal: 15,
            backgroundColor: "#2F2F2F",
            borderRadius: 15,
            marginBottom: 10,
            alignItems: "flex-end",
          }}
        >
          <View style={{ marginBottom: 5 }}>
            <Text
              selectable
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 14,
                color: "#fff",
                lineHeight: 26,
              }}
            >
              {item.content_txt}
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: "#fff",
                }}
              >
                سیستم
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: "#fff",
                }}
              >
                {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        width: "100%",
        alignItems: item.sender_id != null ? "flex-end" : "flex-start",
      }}
    >
      <View
        style={{
          width: "90%",
          paddingVertical: 15,
          paddingHorizontal: 15,
          backgroundColor: item.sender_id != null ? Colors.blue : "#f2f2f2",
          borderRadius: 15,
          marginBottom: 10,
          alignItems: "flex-end",
        }}
      >
        <View style={{ marginBottom: 5 }}>
          <Text
            selectable
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: item.sender_id != null ? "#fff" : Colors.text,
              lineHeight: 26,
            }}
          >
            {item.content_txt}
          </Text>
        </View>
        {item.content_file && (
          <TouchableOpacity
            style={{
              paddingHorizontal: 10,
              height: 25,
              backgroundColor: Colors.secondary,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 5,
            }}
            onPress={() => {
              if (!download) get_file(item.id)
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: "#fff",
              }}
            >
              {download ? "درحال دانلود..." : "پیوست"}
            </Text>
          </TouchableOpacity>
        )}
        <View
          style={{
            width: "100%",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: item.sender_id != null ? "#fff" : Colors.text,
              }}
            >
              {item.sender_id == null ? "" : "پشتیبان شماره " + item.sender_id}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: item.sender_id != null ? "#fff" : Colors.text,
              }}
            >
              {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const CustomerData = (props) => {
  const { data } = props

  const navigation = useNavigation()

  const copyToClipboard = async (value) => {
    await Clipboard.setStringAsync(value)
  }

  return (
    <View
      style={
        DESKTOP
          ? {
              backgroundColor: Colors.secondary,
              padding: 20,
              flex: 0.7,
              maxHeight: 150,
              borderRadius: 15,
              marginLeft: 20,
              alignItems: "center",
              justifyContent: "center",
            }
          : {
              backgroundColor: Colors.secondary,
              padding: 20,
              borderRadius: 15,
              marginBottom: 20,
              alignItems: "center",
              justifyContent: "center",
            }
      }
    >
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 14,
          color: "#fff",
          marginBottom: 25,
        }}
      >
        مشخصات مشتری
      </Text>

      <View
        style={{
          width: "100%",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 12,
            color: "#fff",
          }}
        >
          نام و نام خانوادگی
        </Text>
        <TouchableOpacity
          onPress={() =>
            copyToClipboard(data.first_name + " " + data.last_name)
          }
        >
          <Text
            style={{
              color: "white",
              fontSize: 12,
              fontFamily: "IranSans-Regular",
            }}
          >
            {data.first_name + " " + data.last_name}
          </Text>
        </TouchableOpacity>
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 12,
            fontFamily: "IranSans-Regular",
          }}
        >
          شماره موبایل
        </Text>
        <TouchableOpacity onPress={() => copyToClipboard(data.mobile)}>
          <Text
            style={{
              color: "white",
              fontSize: 12,
              fontFamily: "IranSans-Regular",
            }}
          >
            {data.mobile}
          </Text>
        </TouchableOpacity>
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 12,
            fontFamily: "IranSans-Regular",
          }}
        >
          سایت ها
        </Text>

        <View
          style={{
            flexDirection: "row-reverse",
            gap: 15,
            flexWrap: "wrap",
            maxWidth: "85%",
          }}
        >
          {data.websites.map((item, index) => (
            <View
              style={{
                flexDirection: "row-reverse",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => copyToClipboard(item.url)}
                key={index}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 12,
                    fontFamily: "IranSans-Regular",
                  }}
                >
                  {item.url}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: 24,
                  height: 24,
                  backgroundColor: item.hasAccess
                    ? Colors.green
                    : Colors.primary,
                  borderRadius: 3,
                  marginHorizontal: 3,
                }}
                onPress={() => {
                  if (item.hasAccess) {
                    if (confirm("دسترسی شما حذف شود؟")) {
                      props.terminateAccess(item.id)
                    }
                  } else {
                    props.dispatchFormState({
                      type: SET_ACCESS_MODAL,
                      value: true,
                      site_id: item.id,
                    })
                  }
                }}
              >
                <Ionicons
                  name={
                    item.hasAccess ? "lock-open-outline" : "lock-closed-outline"
                  }
                  color="#fff"
                  size={16}
                />
              </TouchableOpacity>

              {item.hasAccess && (
                <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: 24,
                    height: 24,
                    backgroundColor: Colors.blue,
                    borderRadius: 3,
                    marginHorizontal: 3,
                  }}
                  onPress={() => {
                    navigation.navigate("ASC", {
                      screen: "AddSite",
                      params: { site_id: item.id },
                    })
                  }}
                >
                  <Ionicons name="eye-outline" color="#fff" size={16} />
                </TouchableOpacity>
              )}
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

const TicketScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const ticket_id = props.route.params.ticket_id

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      description: null,
      file: null,
      form_send_progress: 0,
      messages: [],
      modalVisible: false,
      modalStatusVisible: false,
      silent: true,
      can_ref_users: null,
      ref_id: null,
      to_status: null,
      ticket_status: null,
      customer_data: null,

      // Access Request ->
      isAccessModalOpen: false,
      accessType: null,
      isEditAccess: false,
      targetSite: null,
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_reply = async () => {
    if (!formState.values.description) {
      alert("وارد کردن توضیحات اجباریست.")
      return
    }

    const url = Config.aioUrl + "/ticket/send"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("ticket_id", ticket_id)
    formdata.append("description", formState.values.description)
    if (formState.values.file) {
      formdata.append(
        "file",
        formState.values.file.file,
        formState.values.file.name
      )
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "TicketList",
              },
              {
                name: "Ticket",
                params: { ticket_id: ticket_id },
              },
            ],
          })
        )
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_messages = async () => {
    const url = Config.aioUrl + "/ticket/get?id=" + ticket_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_MESSAGES,
          data: response.data.data,
          ticket_status: response.data.ticket_status,
          can_ref_users: response.data.can_ref_users,
          customer_data: response.data.customer_data,
        })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const ref = async () => {
    if (!formState.values.ref_id) {
      alert("پرسنل را انتخاب کنید")
      return
    }

    const url = Config.aioUrl + "/ticket/ref"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("ticket_id", ticket_id)
    formdata.append("ref_id", formState.values.ref_id.id)
    formdata.append("silent", formState.values.silent ? 1 : 0)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)

        props.navigation.navigate("TicketList")

        // props.navigation.dispatch(
        //   CommonActions.reset({
        //     index: 1,
        //     routes: [
        //       {
        //         name: "TicketList",
        //       },
        //       {
        //         name: "Ticket",
        //         params: { ticket_id: ticket_id },
        //       },
        //     ],
        //   })
        // )
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const change_status = async () => {
    if (!formState.values.to_status) {
      alert("وضعیت را انتخاب کنید")
      return
    }

    const url = Config.aioUrl + "/ticket/changeStatus"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("ticket_id", ticket_id)
    formdata.append("status", formState.values.to_status.id)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "TicketList",
              },
              {
                name: "Ticket",
                params: { ticket_id: ticket_id },
              },
            ],
          })
        )
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const requestAccess = async (id) => {
    if (!formState.values.accessType) {
      alert("انتخاب سطح دسترسی الزامیست")
      return
    }

    const url = Config.aioUrl + "/asc/access/request"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    formdata.append("site_id", id)
    formdata.append("type", parseInt(formState.values.accessType.id))
    formdata.append("edit", formState.values.isEditAccess ? 1 : 0)

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOADING, loading: false })

        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })

        console.log(error)
      })
  }

  const terminateAccess = async (id) => {
    const url = Config.aioUrl + "/asc/access/terminate"

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    formdata.append("site_id", id)

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        dispatchFormState({ type: SET_LOADING, loading: false })

        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_messages()
    })

    return focused
  }, [props.navigation])

  const scrollViewRef = useRef()

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Modal
        animationType="fade"
        transparent={true}
        visible={formState.values.isAccessModalOpen}
      >
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          activeOpacity={1}
          onPressOut={() => {
            dispatchFormState({ type: SET_ACCESS_MODAL, value: false })
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: "30%",
                marginTop: "15%",
                marginLeft: "30%",
                backgroundColor: Colors.bg,
                borderRadius: 25,
                paddingHorizontal: 25,
                paddingVertical: 35,
              }}
            >
              <ScrollView style={{ maxHeight: 200 }}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    دسترسی ویرایش
                  </Text>

                  <Switch
                    trackColor={{ false: "#767577", true: "#767577" }}
                    thumbColor={
                      formState.edit ? Colors.primary : Colors.primary
                    }
                    onValueChange={() => {
                      dispatchFormState({
                        type: SET_IS_EDIT_ACCESS,
                        value: !formState.values.isEditAccess,
                      })
                    }}
                    value={formState.values.isEditAccess}
                    style={{ marginHorizontal: 10 }}
                  />
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    دسترسی مشاهده
                  </Text>
                </View>

                <Picker
                  label="سطح دسترسی"
                  id="accessType"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "وردپرس", id: "1" },
                    { item: "پنل هاست", id: "2" },
                    { item: "وردپرس و پنل هاست", id: "3" },
                    { item: "نمادها", id: "4" },
                    { item: "وردپرس و نمادها", id: "5" },
                    { item: "پنل هاست و نمادها", id: "6" },
                    { item: "پنل هاست، وردپرس و نمادها", id: "7" },
                    { item: "درگاه", id: "8" },
                    { item: "وردپرس و درگاه", id: "9" },
                    { item: "پنل هاست و درگاه", id: "10" },
                    { item: "پنل هاست، وردپرس و درگاه", id: "11" },
                    { item: "هاستینگ", id: "12" },
                    { item: "هاستینگ و وردپرس", id: "13" },
                    { item: "ایرانیک", id: "14" },
                    { item: "وردپرس و ایرانیک", id: "15" },
                    { item: "پنل هاست و ایرانیک", id: "16" },
                    { item: "وردپرس، پنل هاست و ایرانیک", id: "17" },
                    { item: "کامل سطح یک", id: "18" },
                  ]}
                />
              </ScrollView>

              <Btn
                title="دریافت دسترسی"
                onPress={async () => {
                  await requestAccess(formState.values.targetSite)
                  dispatchFormState({ type: SET_ACCESS_MODAL, value: false })
                }}
                loading={formState.values.loading}
                color={Colors.green}
                wrapperStyle={{ marginTop: 20 }}
              />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={formState.values.modalVisible}
        swipeDirection="down"
      >
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          activeOpacity={1}
          onPressOut={() => {
            dispatchFormState({ type: SET_MODAL, value: false })
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: DESKTOP ? "30%" : "80%",
                marginTop: DESKTOP ? "15%" : "60%",
                marginLeft: DESKTOP ? "30%" : "10%",
                backgroundColor: Colors.bg,
                borderRadius: 25,
                paddingHorizontal: 25,
                paddingVertical: 35,
              }}
            >
              <ScrollView style={{ maxHeight: 200 }}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    سایلنت
                  </Text>
                  <Switch
                    trackColor={{ false: "#767577", true: "#767577" }}
                    thumbColor={
                      formState.values.silent ? Colors.primary : Colors.primary
                    }
                    onValueChange={() => {
                      dispatchFormState({ type: SET_SILENT })
                    }}
                    value={formState.values.silent}
                    style={{ marginHorizontal: 10 }}
                  />
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: Colors.text,
                    }}
                  >
                    به مشتری اطلاع بده
                  </Text>
                </View>
                {formState.values.can_ref_users && (
                  <Picker
                    label="ارجاع به"
                    id="ref_id"
                    onChange={inputChangeHandler}
                    options={formState.values.can_ref_users}
                  />
                )}
              </ScrollView>
              <Btn
                title="ثبت ارجاع"
                onPress={async () => {
                  await ref()
                  dispatchFormState({ type: SET_MODAL, value: false })
                }}
                loading={formState.values.loading}
                color={Colors.green}
                wrapperStyle={{ marginTop: 20 }}
              />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={formState.values.modalStatusVisible}
        swipeDirection="down"
      >
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
          activeOpacity={1}
          onPressOut={() => {
            dispatchFormState({ type: SET_MODAL_STATUS, value: false })
          }}
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                width: DESKTOP ? "30%" : "80%",
                marginTop: DESKTOP ? "15%" : "60%",
                marginLeft: DESKTOP ? "30%" : "10%",
                backgroundColor: Colors.bg,
                borderRadius: 25,
                paddingHorizontal: 25,
                paddingVertical: 35,
              }}
            >
              <Picker
                label="تغییر وضعیت به"
                id="to_status"
                onChange={inputChangeHandler}
                options={[
                  { item: "در حال بررسی", id: "pending" },
                  { item: "بسته", id: "closed" },
                ]}
              />
              <Btn
                title="ثبت وضعیت"
                onPress={async () => {
                  await change_status()
                  dispatchFormState({ type: SET_MODAL, value: false })
                }}
                loading={formState.values.loading}
                color={Colors.green}
                wrapperStyle={{ marginTop: 20 }}
              />
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>

      <View
        style={{
          paddingHorizontal: 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Header
          title={"تیکت" + " #" + ticket_id}
          noNotif
          noActiveSessions
          noToggle
          reload={() => {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: "TicketList",
                  },
                  {
                    name: "Ticket",
                    params: { ticket_id: ticket_id },
                  },
                ],
              })
            )
          }}
        />
        <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "10%" : 0 }}>
          <View
            style={{
              height: "100%",
              flex: 1,
              flexDirection: DESKTOP ? "row-reverse" : "column",
            }}
          >
            {!formState.values.customer_data && (
              <View
                style={{
                  width: "100%",
                  height: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color={Colors.primary} />
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 16,
                    color: Colors.text,
                    marginTop: 20,
                  }}
                >
                  در حال بارگذاری اطلاعات...
                </Text>
              </View>
            )}

            {DESKTOP && formState.values.customer_data && (
              <CustomerData
                dispatchFormState={dispatchFormState}
                requestAccess={requestAccess}
                terminateAccess={terminateAccess}
                data={formState.values.customer_data}
              />
            )}

            {formState.values.customer_data && (
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingBottom: 50, flex: 1 }}
                ref={scrollViewRef}
                onContentSizeChange={() =>
                  scrollViewRef.current.scrollToEnd({ animated: true })
                }
              >
                {!DESKTOP && formState.values.customer_data && (
                  <CustomerData data={formState.values.customer_data} />
                )}
                {formState.values.messages.map((item) => (
                  <Message
                    key={item.id}
                    item={item}
                    user_data={user_data}
                    token={token}
                  />
                ))}
                {formState.values.ticket_status != "closed" && (
                  <Input
                    label="پاسخ"
                    id="description"
                    onChange={inputChangeHandler}
                    multiline
                    // insert={formState.values.description}
                  />
                )}
                {formState.values.ticket_status != "closed" && (
                  <View style={{ width: "100%", marginBottom: 30 }}>
                    <File
                      id="file"
                      title="پیوست"
                      extension="jpg"
                      max_size={300}
                      max_width={1080}
                      max_height={1080}
                      content_type="image/jpeg"
                      value={formState.values.file}
                      editOptions={{
                        compress: 0.7,
                        resizeWidth: 800,
                      }}
                      onChange={inputChangeHandler}
                    />
                  </View>
                )}
                <View style={{ flexDirection: "row-reverse" }}>
                  {formState.values.ticket_status != "closed" && (
                    <View style={{ flex: 1, marginLeft: 3.5 }}>
                      <Btn
                        title={
                          formState.values.form_send_progress > 0 &&
                          formState.values.form_send_progress < 1
                            ? "در حال ارسال...(" +
                              (
                                formState.values.form_send_progress * 100
                              ).toFixed(0) +
                              "%)"
                            : "ثبت پاسخ"
                        }
                        onPress={() => {
                          send_reply()
                        }}
                        loading={formState.values.loading}
                        color={Colors.green}
                      />
                    </View>
                  )}
                  {formState.values.ticket_status != "closed" && (
                    <View
                      style={{ flex: 1, marginRight: 3.5, marginLeft: 3.5 }}
                    >
                      <Btn
                        title="ارجاع"
                        color={Colors.error}
                        onPress={() => {
                          dispatchFormState({ type: SET_MODAL, value: true })
                        }}
                      />
                    </View>
                  )}
                  <View style={{ flex: 1, marginRight: 3.5 }}>
                    <Btn
                      title="تغییر وضعیت"
                      onPress={() => {
                        dispatchFormState({
                          type: SET_MODAL_STATUS,
                          value: true,
                        })
                      }}
                    />
                  </View>
                </View>
              </ScrollView>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default TicketScreen
