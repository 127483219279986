import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  ActivityIndicator,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import InputNote from "../../components/InputNote"
import Config from "../../constants/Config"
import axios from "axios"
import { logOut } from "../../components/Functions"
import { Ionicons } from "@expo/vector-icons"
import AsyncStorage from "@react-native-async-storage/async-storage"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_PROJECTS = "SET_PROJECTS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_PROJECTS:
      return {
        ...state,
        values: {
          ...state.values,
          projects: action.projects,
          user_data: action.user_data,
        },
      }
    default:
      return state
  }
}

const Project = (props) => {
  const { project } = props

  const department = project.department

  const do_form_percent = (
    (+project.form_filled_fields / +project.form_total_fields) *
    100
  ).toFixed()

  const done =
    project.form_filled_fields == project.form_total_fields ? true : false

  console.clear()
  console.log(project.department)

  return (
    <TouchableOpacity
      style={{
        width: "100%",
        height: 200,
        backgroundColor: done ? Colors.green : Colors.error,
        borderRadius: 15,
        justifyContent: "center",
        marginBottom: 10,
      }}
      onPress={() => {
        if (department === "site") {
          props.navigation.navigate("FormSite", {
            project_id: project.id,
            is_customer: true,
          })

          return false
        }

        if (department === "graphic") {
          props.navigation.navigate("FormLogo", {
            project_id: project.id,
            is_customer: true,
          })

          return false
        }
      }}
    >
      <View
        style={{
          width: "100%",
          height: 50,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <Text
          style={{ fontFamily: "IranSans-Medium", fontSize: 40, color: "#fff" }}
        >
          {isNaN(do_form_percent) ? 100 : do_form_percent}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 28,
            color: "#fff",
            marginRight: 5,
          }}
        >
          درصد
        </Text>
      </View>

      {/* <View
        style={{
          width: "100%",
          height: 50,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 25,
            marginBottom: 18,
            color: "#fff",
          }}
        >
          {project.primary_service_title}
        </Text>
      </View> */}

      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: 20,
        }}
      >
        {!done && (
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: "#fff",
              marginRight: 5,
              lineHeight: 28,
            }}
          >
            {"از فرم اطلاعات پروژه طراحی " +
              project.primary_service_title +
              " شما پر شده است. جهت ادامه فرایند پروژه، شما می بایست فرم را تکمیل نمایید. برای تکمیل فرم روی همین کارت کلیک کنید."}
          </Text>
        )}
        {done && (
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: "#fff",
              marginRight: 5,
              lineHeight: 28,
            }}
          >
            {"از فرم اطلاعات پروژه طراحی " +
              project.primary_service_title +
              " شما پر شده است و نیاز به هیج اقدامی توسط شما نیست."}
          </Text>
        )}
      </View>

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row-reverse",
          position: "absolute",
          top: 10,
          left: 10,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 18,
            color: "#fff",
            marginRight: 5,
          }}
        >
          #{project.id}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const DashBtn = (props) => {
  return (
    <View style={{ flex: 1, paddingHorizontal: 3 }}>
      <TouchableOpacity
        style={{
          width: "100%",
          height: 150,
          backgroundColor: props.color,
          borderRadius: 15,
          shadowColor: props.color,
          shadowOpacity: 1,
          shadowRadius: 3,
          elevation: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
        onPress={props.onPress}
      >
        {props.icon && <Ionicons name={props.icon} color="#fff" size={46} />}
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontSize: 18,
            color: "#fff",
            marginTop: 3,
          }}
        >
          {props.title}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const DashboardScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: true,
      projects: [],
      user_data: {},
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_dashboard = async () => {
    const url = Config.aioUrl + "/customer/dashboard"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({
      type: SET_LOADING,
      loading: true,
    })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({
          type: SET_PROJECTS,
          projects: response.data.projects,
          user_data: response.data.user_data,
        })

        dispatchFormState({
          type: SET_LOADING,
          loading: false,
        })
      })
      .catch(function (error) {
        dispatchFormState({
          type: SET_LOADING,
          loading: false,
        })

        alert(
          "متاسفانه ارتباط برقرار نشد! لطفا اینترنت خود را بررسی کنید و مجدد چک کنید."
        )
      })
  }

  useEffect(() => {
    get_dashboard()
  }, [])

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="داشبورد" noBack noNotif noToggle />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ paddingHorizontal: DESKTOP ? "30%" : 0, paddingBottom: 25 }}
      >
        {formState.values.loading ? (
          <View
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: DESKTOP && "20%",
            }}
          >
            <ActivityIndicator size="large" color={Colors.primary} />
            <Text
              style={{
                fontFamily: "IranSans-Medium",
                fontSize: 16,
                color: Colors.text,
                marginTop: 20,
              }}
            >
              در حال بارگذاری اطلاعات...
            </Text>
          </View>
        ) : (
          <>
            <InputNote
              title={
                user_data.first_name + " " + user_data.last_name + " عزیز،"
              }
              content="به داشبورد خودت خوش اومدی!"
            />

            {formState.values.projects.map((item) => (
              <Project
                key={item.id}
                project={item}
                navigation={props.navigation}
              />
            ))}
            <View style={{ flexDirection: "row-reverse" }}>
              <DashBtn
                title="پشتیبانی"
                icon="help-buoy-outline"
                color={Colors.primary}
                onPress={() => {
                  if (formState.values.user_data.has_support) {
                    props.navigation.navigate("Support", {
                      screen: "SupportList",
                    })
                  } else {
                    alert("شما اجازه ارسال تیکت ندارید!")
                  }
                }}
              />
              <DashBtn
                title="دوره ها"
                icon="school-outline"
                color={Colors.primary}
                onPress={() => {
                  props.navigation.navigate("CoursesList")
                }}
              />
            </View>
            <View style={{ flexDirection: "row-reverse", marginTop: 7 }}>
              <DashBtn
                title="خروج از حساب"
                icon="log-out-outline"
                color={Colors.secondary}
                onPress={async () => {
                  await AsyncStorage.clear()
                  window.location.reload(true)
                }}
              />
              <DashBtn title="" color={Colors.bg} onPress={() => {}} />
            </View>
          </>
        )}
      </ScrollView>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default DashboardScreen
