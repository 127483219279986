import { useReducer, useEffect, useCallback } from "react";
import { View, Text, FlatList, ActivityIndicator, TouchableOpacity, Image, Dimensions } from "react-native";
import Colors from "../../../constants/Colors";
import Config from "../../../constants/Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { logOut } from "../../../components/Functions";
import Header from "../../../components/Header";
import moment from "moment-jalaali";
import Input from "../../../components/Input";
import File from "../../../components/File";
import Btn from "../../../components/Btn";
import { CommonActions } from "@react-navigation/native";
const DESKTOP = Dimensions.get("window").width > 800 ? true : false;

const INPUT_CHANGE = "INPUT_CHANGE";
const SET_LOADING = "SET_LOADING";
const SET_MEDIAS = "SET_MEDIAS";
const SELECT_FILE = "SELECT_FILE";
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: {
            ...state.values.loading,
            [action.name]: action.loading,
          },
        },
      };
    case SET_MEDIAS:
      return {
        ...state,
        values: {
          ...state.values,
          medias: action.data,
        },
      };
    case SELECT_FILE:
      return {
        ...state,
        values: {
          ...state.values,
          selected_file: action.data,
        },
      };
    default:
      return state;
  }
};

const MediaScreen = (props) => {
  const token = useSelector((store) => store.user.token);
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: {
        get_media: false,
        remove_media: false,
        upload_media: false,
      },
      medias: [],
      file: null,
      alt: null,
      selected_file: null,
    },
  });

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value });
    },
    [dispatchFormState]
  );

  const upload_media = async () => {
    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    const url = Config.aioUrl + "/website/media/upload";
    let formdata = new FormData();
    if (formState.values.file && formState.values.alt) {
      formdata.append("file", formState.values.file.file, formState.values.file.name);
      formdata.append("alt", formState.values.alt);
    } else {
      alert("فایل و آلت را پر کنید");
      return;
    }

    dispatchFormState({ type: SET_LOADING, name: "upload_media", loading: true });
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        
        alert(response.data.msg);
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "Media",
                },
              ],
            })
          );
        }
        dispatchFormState({ type: SET_LOADING, name: "upload_media", loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "upload_media", loading: false });
      });
  };

  const get_medias = async () => {
    let url = Config.aioUrl + "/website/media/list";

    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    dispatchFormState({ type: SET_LOADING, name: "get_media", loading: true });
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        
        dispatchFormState({ type: SET_MEDIAS, data: response.data.data });
        dispatchFormState({ type: SET_LOADING, name: "get_media", loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "get_media", loading: false });
      });
  };

  const remove_media = async () => {
    let reqHedear = {
      Authorization: "Bearer " + token,
    };
    const url = Config.aioUrl + "/website/media/remove";
    let formdata = new FormData();
    formdata.append("media_id", formState.values.selected_file.id);

    dispatchFormState({ type: SET_LOADING, name: "remove_media", loading: true });
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.");
          logOut();
        }
        alert(response.data.msg);
        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "Media",
                },
              ],
            })
          );
        }
        dispatchFormState({ type: SET_LOADING, name: "remove_media", loading: false });
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, name: "remove_media", loading: false });
      });
  };

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_medias();
    });

    return focused;
  }, [props.navigation]);

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="مدیریت فایل ها" noBack />
      {formState.values.loading.get_media && (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )}
      <View style={{ flexDirection: DESKTOP ? "row-reverse" : "column", width: "100%", height: "100%" }}>
        <View style={{ flex: 1 }}>
          <File
            id="file"
            title="فایل"
            extension="jpg"
            content_type="image/jpeg"
            required={true}
            value={formState.values.file}
            onChange={inputChangeHandler}
          />
          <Input label="Alt" id="alt" onChange={inputChangeHandler} />
          <Btn
            title="آپلود"
            onPress={() => {
              upload_media();
            }}
            loading={formState.values.loading.upload_media}
            color={Colors.secondary}
          />
          {formState.values.selected_file && (
            <View style={{ width: "100%", paddingHorizontal: 15, paddingVertical: 20, borderRadius: 10, backgroundColor: "#F2F2F2", marginTop: 20 }}>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text, marginBottom: 10 }}>آیدی:</Text>
              <Text selectable style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: Colors.text, marginBottom: 20, textAlign: "right" }}>
                {formState.values.selected_file.id}
              </Text>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text, marginBottom: 10 }}>آدرس فایل:</Text>
              <Text selectable style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: Colors.text, marginBottom: 20, textAlign: "right" }}>
                {formState.values.selected_file.url}
              </Text>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text, marginBottom: 10 }}>متن جایگزین:</Text>
              <Text selectable style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: Colors.text, marginBottom: 20 }}>
                {formState.values.selected_file.alt}
              </Text>
              <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: Colors.text, marginBottom: 10 }}>ارسال کننده :</Text>
              <Text style={{ fontFamily: "IranSans-Regular", fontSize: 12, color: Colors.text }}>
                {formState.values.selected_file.sender_name + " - " + moment.unix(formState.values.selected_file.upload_time).format("jYYYY/jM/jD")}
              </Text>
              <Btn
                title="حذف فایل"
                onPress={() => {
                  if (confirm("آیا از حذف فایل مطمئن هستید؟ امکان برگشت به عقب وجود ندارد.")) {
                    remove_media();
                  }
                }}
                loading={formState.values.loading.remove_media}
                wrapperStyle={{ width: 100, height: 35 }}
                titleStyle={{ fontSize: 12 }}
              />
            </View>
          )}
        </View>
        <View style={{ flex: 3, height: "100%", paddingBottom: 150 }}>
          {!formState.values.loading.get_media && formState.values.medias.length != 0 && (
            <FlatList
              style={{ flex: 1, height: "100%" }}
              contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", height: "100%" }}
              data={formState.values.medias}
              keyExtractor={(item) => item.id}
              renderItem={(itemData) => (
                <TouchableOpacity
                  onPress={() => {
                    dispatchFormState({ type: SELECT_FILE, data: itemData.item });
                  }}
                  style={{ opacity: formState.values.selected_file ? (formState.values.selected_file.id == itemData.item.id ? 0.4 : 1) : 1 }}
                >
                  <Image
                    source={{ uri: itemData.item.url }}
                    style={{ width: 100, height: 100, marginHorizontal: 3, marginVertical: 3, borderRadius: 7 }}
                  />
                </TouchableOpacity>
              )}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
};

export default MediaScreen;
