import { useReducer, useCallback, useEffect } from "react"
import { View, Dimensions } from "react-native"
import Colors from "../../constants/Colors"
import Config from "../../constants/Config"
import Btn from "../../components/Btn"
import axios from "axios"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import InputNote from "../../components/InputNote"
import Input from "../../components/Input"
import FancySelector from "../../components/FancySelector"
import { fixPhoneFormat } from "../../components/Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    default:
      return state
  }
}

const GetPriceScreen = (props) => {
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      name: "",
      phone: "",
      selected_services: [],
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )
  const add_lead = async () => {
    if (!formState.values.name || !formState.values.phone) {
      alert("وارد کردن نام و شماره الزامیست..")
      return
    }

    if (formState.values.selected_services.length == 0) {
      alert("حداقل یکی از خدمات را انتخاب کنید")
      return
    }

    let reqHedear = {
      "Content-Type": "application/json",
    }
    const url = Config.aioUrl + "/crm/lead/add"

    let formdata = new FormData()
    formdata.append("name", formState.values.name)
    formdata.append("phone", fixPhoneFormat(formState.values.phone))
    formdata.append("services", formState.values.selected_services.join())
    formdata.append("source", "instagram")
    formdata.append("medium", "dm")
    formdata.append("campaign", "auto")
    formdata.append("autoresponse", "1")

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        alert(response.data.msg)
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    console.clear()
    console.log(fixPhoneFormat(formState.values.phone))
  }, [formState])

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: DESKTOP ? "30%" : 15,
      }}
    >
      <InputNote
        backgroundColor={Colors.secondary}
        textColor="#fff"
        content="توضیحات کامل و تعرفه خدمات به واتسپ شما ارسال می شود."
      />
      <Input
        label="نام و نام خانوادگی شما"
        id="name"
        onChange={inputChangeHandler}
        textAlign="center"
      />
      <Input
        label="شماره واتسپ"
        id="phone"
        onChange={inputChangeHandler}
        textAlign="center"
      />
      <FancySelector
        column={2}
        id="selected_services"
        title="کدام خدمت مد نظر شماست؟"
        options={[
          { item: "لوگو", id: "logo", icon: "bulb-outline" },
          { item: "سایت", id: "site", icon: "globe-outline" },
          { item: "موارد دیگر", id: "other", icon: "checkmark-circle-outline" },
          { item: "اپلیکیشن", id: "app", icon: "md-phone-portrait-outline" },
        ]}
        onChange={inputChangeHandler}
      />
      <Btn
        title="ثبت شماره و دریافت تعرفه"
        onPress={() => {
          !formState.values.loading ? add_lead() : null
        }}
        wrapperStyle={{ marginTop: 20 }}
        loading={formState.values.loading}
      />
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default GetPriceScreen
