import { useState } from "react"
import { View, Dimensions } from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Journey from "../../components/Journey"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
const Tab = createMaterialTopTabNavigator()
import IdentityTab from "./FormSiteTabs/IdentityTab"
import AddressTab from "./FormSiteTabs/AddressTab"
import BankTab from "./FormSiteTabs/BankTab"
import BusinessTab from "./FormSiteTabs/BusinessTab"
import SaligheTab from "./FormSiteTabs/SaligheTab"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const FormSiteScreen = (props) => {
  const project_id = props.route.params.project_id
  const is_customer = props.route.params.is_customer
  const token = useSelector((store) => store.user.token)
  const steps = [
    {
      id: 5,
      name: "سلیقه یابی",
      status: "inactive",
      component: SaligheTab,
    },
    {
      id: 4,
      name: "اطلاعات کسب و کار",
      status: "inactive",
      component: BusinessTab,
    },
    {
      id: 3,
      name: "اطلاعات بانکی",
      status: "inactive",
      component: BankTab,
    },
    {
      id: 2,
      name: "اطلاعات مکانی",
      status: "inactive",
      component: AddressTab,
    },
    {
      id: 1,
      name: "اطلاعات هویتی",
      status: "active",
      component: IdentityTab,
    },
  ]
  const [stepsState, setStepsState] = useState(steps)
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        flex: 1,
      }}
    >
      <Header title="فرم خدمات سایت" noToggle noNotif noActiveSessions />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 0,
          flex: 1,
          height: "100%",
        }}
      >
        <Tab.Navigator
          initialRouteName="اطلاعات هویتی"
          swipeEnabled={false}
          sceneContainerStyle={{ backgroundColor: "#fff" }}
          tabBar={(props) => (
            <Journey
              steps={stepsState}
              setSteps={setStepsState}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              {...props}
            />
          )}
        >
          {steps.map((step) => (
            <Tab.Screen
              key={step.name}
              name={step.name}
              component={step.component}
              initialParams={{
                setActiveIndex: setActiveIndex,
                setSteps: setStepsState,
                project_id: project_id,
                is_customer: is_customer,
              }}
            />
          ))}
        </Tab.Navigator>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default FormSiteScreen
