import { useReducer, useCallback, useEffect, useRef, useState } from "react"
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../../constants/Colors"
import Header from "../../../components/Header"
import Config from "../../../constants/Config"
import axios from "axios"
import { logOut } from "../../../components/Functions"
import Btn from "../../../components/Btn"
import Input from "../../../components/Input"
import File from "../../../components/File"
import moment from "moment-jalaali"
import { CommonActions } from "@react-navigation/native"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_FORM_SEND = "SET_FORM_SEND"
const SET_MESSAGES = "SET_MESSAGES"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_FORM_SEND:
      return {
        ...state,
        values: {
          ...state.values,
          form_send_progress: action.value,
        },
      }
    case SET_MESSAGES:
      return {
        ...state,
        values: {
          ...state.values,
          messages: action.data,
          ticket_status: action.ticket_status,
        },
      }
    default:
      return state
  }
}

const Message = (props) => {
  const { item, user_data, token } = props
  const [download, setDownload] = useState(false)
  const get_file = async (message_id) => {
    const url =
      Config.aioUrl + "/customer/ticket/getFile?message_id=" + message_id
    setDownload(true)
    const file = await axios(url, {
      headers: { Authorization: "Bearer " + token },
      method: "get",
      responseType: "blob",
    })
    const blobUrl = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement("a")
    link.href = blobUrl
    link.setAttribute("download", item.content_file.bucket_key)
    document.body.appendChild(link)
    link.click()
    setDownload(false)
  }

  if (item.sender_id == null && item.customer_id == null) {
    return (
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <View
          style={{
            width: "90%",
            paddingVertical: 15,
            paddingHorizontal: 15,
            backgroundColor: "#2F2F2F",
            borderRadius: 15,
            marginBottom: 10,
            alignItems: "flex-end",
          }}
        >
          <View style={{ marginBottom: 5 }}>
            <Text
              selectable
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 14,
                color: "#fff",
                lineHeight: 26,
              }}
            >
              {item.content_txt}
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View>
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: "#fff",
                }}
              >
                سیستم
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: "#fff",
                }}
              >
                {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        width: "100%",
        alignItems: item.sender_id == null ? "flex-end" : "flex-start",
      }}
    >
      <View
        style={{
          width: "90%",
          paddingVertical: 15,
          paddingHorizontal: 15,
          backgroundColor: item.sender_id == null ? Colors.blue : "#f2f2f2",
          borderRadius: 15,
          marginBottom: 10,
          alignItems: "flex-end",
        }}
      >
        <View style={{ marginBottom: 5 }}>
          <Text
            style={{
              fontFamily: "IranSans-Regular",
              fontSize: 14,
              color: item.sender_id == null ? "#fff" : Colors.text,
              lineHeight: 26,
            }}
          >
            {item.content_txt}
          </Text>
        </View>
        {item.content_file && (
          <TouchableOpacity
            style={{
              paddingHorizontal: 10,
              height: 25,
              backgroundColor: Colors.secondary,
              borderRadius: 7,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 5,
            }}
            onPress={() => {
              if (!download) get_file(item.id)
            }}
          >
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: "#fff",
              }}
            >
              {download ? "درحال دانلود..." : "پیوست"}
            </Text>
          </TouchableOpacity>
        )}
        <View
          style={{
            width: "100%",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: item.sender_id == null ? "#fff" : Colors.text,
              }}
            >
              {item.sender_id == null ? "" : "پشتیبان"}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: item.sender_id == null ? "#fff" : Colors.text,
              }}
            >
              {moment.unix(item.time).format("jYYYY/jM/jD HH:mm")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const TicketScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const user_data = useSelector((store) => store.user.user_data)
  const ticket_id = props.route.params.ticket_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      description: null,
      file: null,
      form_send_progress: 0,
      messages: [],
      ticket_status: null,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const send_reply = async () => {
    if (!formState.values.description) {
      alert("وارد کردن توضیحات اجباریست.")
      return
    }

    const url = Config.aioUrl + "/customer/ticket/send"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("ticket_id", ticket_id)
    formdata.append("description", formState.values.description)
    if (formState.values.file) {
      formdata.append(
        "file",
        formState.values.file.file,
        formState.values.file.name
      )
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
      onUploadProgress: (progress) => {
        dispatchFormState({
          type: SET_FORM_SEND,
          value: progress.loaded / progress.total,
        })
      },
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        alert(response.data.msg)
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: "Ticket",
                params: { ticket_id: ticket_id },
              },
            ],
          })
        )
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_messages = async () => {
    const url = Config.aioUrl + "/customer/ticket/get?id=" + ticket_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({
          type: SET_MESSAGES,
          data: response.data.data,
          ticket_status: response.data.ticket_status,
        })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        console.log(error)
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_messages()
    })

    return focused
  }, [props.navigation])
  
  const scrollViewRef = useRef()
  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="تیکت" noNotif noActiveSessions noToggle />
      <View style={{ flex: 1, paddingHorizontal: DESKTOP ? "30%" : 25 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ paddingBottom: 50 }}
          ref={scrollViewRef}
          onContentSizeChange={() =>
            scrollViewRef.current.scrollToEnd({ animated: true })
          }
        >
          {formState.values.messages.map((item) => (
            <Message
              key={item.id}
              item={item}
              user_data={user_data}
              token={token}
            />
          ))}
          {formState.values.ticket_status != "closed" && (
            <Input
              label="پاسخ"
              id="description"
              onChange={inputChangeHandler}
              multiline
              insert={formState.values.description}
            />
          )}
          {formState.values.ticket_status != "closed" && (
            <View style={{ width: "100%", marginBottom: 30 }}>
              <File
                id="file"
                title="پیوست"
                extension="jpg"
                max_size={300}
                max_width={1080}
                max_height={1080}
                content_type="image/jpeg"
                value={formState.values.file}
                editOptions={{
                  compress: 0.7,
                  resizeWidth: 800,
                }}
                onChange={inputChangeHandler}
              />
            </View>
          )}
          {formState.values.ticket_status != "closed" && (
            <Btn
              title={
                formState.values.form_send_progress > 0 &&
                formState.values.form_send_progress < 1
                  ? "در حال ارسال...(" +
                    (formState.values.form_send_progress * 100).toFixed(0) +
                    "%)"
                  : "ثبت پاسخ"
              }
              onPress={() => {
                send_reply()
              }}
              loading={formState.values.loading}
            />
          )}
        </ScrollView>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default TicketScreen
