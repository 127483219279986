import { useReducer, useCallback, useEffect } from "react"
import {
  View,
  StyleSheet,
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
} from "react-native"

import { useSelector } from "react-redux"
import Input from "../components/Input"
import Colors from "../constants/Colors"
import Btn from "../components/Btn"
import InputNote from "../components/InputNote"
import Config from "../constants/Config"
import Header from "../components/Header"
import axios from "axios"
import { logOut } from "../components/Functions"
import { Ionicons } from "@expo/vector-icons"
import { CommonActions } from "@react-navigation/native"
import moment from "moment-jalaali"
const windowWidth = Dimensions.get("window").width

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_BUGS = "SET_BUGS"
const SET_REPORTS = "SET_REPORTS"
const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"

const BugCard = ({ item, resolve_bug }) => {
  return (
    <View
      style={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        paddingHorizontal: 15,
        paddingVertical: 20,
        borderRadius: 15,
        marginBottom: 15,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          style={{ width: 10, height: 10 }}
          onPress={() => {
            if (
              confirm("آیا از تغییر وضعیت این باگ به انجام شده اطمینان دارید؟")
            ) {
              resolve_bug(item.id)
            }
          }}
        >
          <Ionicons
            name="checkmark-done-outline"
            color={Colors.green}
            size={15}
          />
        </TouchableOpacity>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 10,
            color: Colors.text,
          }}
        >
          {moment.unix(item.created_at).format("jYYYY/jM/jD HH:mm")}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 10,
            color: Colors.text,
          }}
        >
          {item.name}
        </Text>
      </View>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
        }}
      >
        {item.content}
      </Text>
    </View>
  )
}

const ReportCard = ({ item, resolve_bug }) => {
  return (
    <View
      style={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        paddingHorizontal: 15,
        paddingVertical: 20,
        borderRadius: 15,
        marginBottom: 15,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 10,
            color: Colors.text,
          }}
        >
          {moment.unix(item.created_at).format("jYYYY/jM/jD HH:mm")}
        </Text>
        <Text
          style={{
            fontFamily: "IranSans-Regular",
            fontSize: 10,
            color: Colors.text,
            marginBottom: 8,
          }}
        >
          {item?.user?.id} - {item?.user?.name || "بدون نام"} -{" "}
          {(item?.user?.department || item?.user?.role || "بدون دپارتمان")
            .replace("sales", "فروش")
            .replace("seo", "سئو")
            .replace("site", "سایت")
            .replace("lead", "مدیرکل")
            .replace("graphic", "گرافیک")
            .replace("customer", "مشتری")
            .replace("hr", "منابع انسانی")}
        </Text>
      </View>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
          marginBottom: 8,
        }}
        selectable
      >
        ورژن اپلیکیشن: {item?.appVersion}
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
        }}
        selectable
      >
        صفحه رویداد:
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
          marginBottom: 8,
        }}
        selectable
      >
        {item?.location}
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
        }}
        selectable
      >
        متن ارور:
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 12,
          color: Colors.text,
          marginTop: 10,
        }}
        selectable
      >
        {item?.error}
      </Text>
    </View>
  )
}

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.id]: action.value,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case SET_BUGS:
      return {
        ...state,
        bugs: action.data,
      }
    case SET_REPORTS:
      return {
        ...state,
        reports: action.data,
      }
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        active_section: action.value,
      }
    default:
      return state
  }
}

const BugsScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)
  const DESKTOP = windowWidth > 800 ? true : false

  const [formState, dispatchFormState] = useReducer(formReducer, {
    loading: false,
    content: false,
    active_section: null, // REPORT & BUG
    bugs: [],
    reports: [],
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const report_bug = async () => {
    if (!formState.content) {
      alert("باگ خالیست.")
      return
    }

    const url = Config.aioUrl + "/bug/report"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("content", formState.content)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })

        alert(response.data.msg)

        if (response.data.success) {
          props.navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: "Bugs",
                },
              ],
            })
          )
        }
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const resolve_bug = async (id) => {
    const url = Config.aioUrl + "/bug/resolve"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()
    formdata.append("id", id)

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "post",
      url: url,
      data: formdata,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        get_bugs()
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_bugs = async () => {
    const url = Config.aioUrl + "/bug/get"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_BUGS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_reports = async () => {
    const url = Config.aioUrl + "/error-log"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_REPORTS, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    if (
      capabilities.includes("bugs_get_all") &&
      formState.active_section === "BUG"
    ) {
      get_bugs()
    }

    if (
      capabilities.includes("bugs_get_autoreport") &&
      formState.active_section === "REPORT"
    ) {
      get_reports()
    }
  }, [formState.active_section])

  return (
    <View style={styles.wrapper}>
      <Header title="گزارش باگ" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ width: DESKTOP ? "40%" : "100%", paddingBottom: 25 }}
      >
        {(capabilities.includes("bugs_get_all") ||
          capabilities.includes("bugs_get_autoreport")) && (
          <View
            style={{ height: 40, marginBottom: 20, alignItems: "flex-end" }}
          >
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row-reverse", width: "100%" }}
            >
              <View
                style={{
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  height: 40,
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Medium",
                    fontSize: 12,
                    color: Colors.text,
                  }}
                >
                  دسته نمایش:{" "}
                </Text>

                {capabilities.includes("bugs_get_all") && (
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.active_section == "BUG"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_SECTION,
                        value: "BUG",
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.active_section == "BUG"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      باگ ها
                    </Text>
                  </TouchableOpacity>
                )}

                {capabilities.includes("bugs_get_autoreport") && (
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      borderRadius: 10,
                      backgroundColor:
                        formState.active_section == "REPORT"
                          ? Colors.blue
                          : "#F2F2F2",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 5,
                    }}
                    onPress={() => {
                      dispatchFormState({
                        type: SET_ACTIVE_SECTION,
                        value: "REPORT",
                      })
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color:
                          formState.active_section == "REPORT"
                            ? "#fff"
                            : Colors.text,
                      }}
                    >
                      گزارش های خودکار
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </ScrollView>
          </View>
        )}

        {(capabilities.includes("bugs_get_all") ||
          capabilities.includes("bugs_get_autoreport")) &&
          !formState.active_section && (
            <InputNote
              title="موردی انتخاب نشده است!"
              content="لطفا از بخش بالا موردی را برای نمایش انتخاب کنید."
              icon="information-circle-outline"
              backgroundColor={Colors.error}
              textColor="#fff"
            />
          )}

        <View>
          {formState.active_section === "BUG" &&
            formState.bugs.map((item, index) => (
              <BugCard key={index} item={item} resolve_bug={resolve_bug} />
            ))}

          {formState.active_section === "REPORT" &&
            formState.reports.map((item, index) => (
              <ReportCard key={index} item={item} />
            ))}
        </View>

        <InputNote
          title="نکته قبل از ارسال"
          content="حتما باگ را ضمن اشاره به صفحه رخداد، دقیق شرح دهید. علاوه بر گزارش باگ می توانید پیشنهادات خود جهت ارتقا و بهبود سیستم را نیز ثبت کنید."
          icon="information-circle-outline"
        />
        <Input
          autoFocus
          multiline
          label="باگ"
          id="content"
          onChange={inputChangeHandler}
        />
        <Btn
          title="ثبت باگ"
          onPress={() => {
            report_bug()
          }}
          loading={formState.loading}
        />
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 15,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Colors.bg,
  },
})

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default BugsScreen
